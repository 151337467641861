import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Gauge, HStack, RCard, StyleProps, Text, VStack } from '@ramp/components';

import { Nullable } from 'types';

import { useDeviceDetail } from '../DeviceDetailProvider';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ScorePanelProps {}

interface ScoreCardProps extends StyleProps {
  title: string;
  score: Nullable<number>;
}

const ScoreCard: React.FC<ScoreCardProps> = ({ title, score, ...props  }) => {
  const { t } = useTranslation();

  return (
    <RCard w="full" px="24px" py="16px" {...props}>
      <HStack w="full" spacing={8} alignItems="center">
        <Gauge size="sm" value={score || 0} maxValue={10}/>
        <VStack w="full" spacing={0} alignItems="start">
          <Text fontSize="20px" fontWeight={500} color="unknown.500">{title}</Text>
          <HStack spacing={0} alignItems="end">
            {score !== null
              ? <Text fontSize="32px" fontWeight={700}>{score}</Text>
              : <Text fontSize="24px" fontWeight={700}>{t('components.scoreCard.noData')}</Text>
            }
            {score !== null
              ? <Text fontSize="24px" fontWeight={700} color="unknown.500">/10</Text>
              : null
            }
          </HStack>
        </VStack>
      </HStack>
    </RCard>
  );
};

const ScorePanel: React.FC<ScorePanelProps> = () => {
  const { t } = useTranslation();
  const { platform, scan } = useDeviceDetail();

  if (!platform || !scan) return null;
  const { global_score: globalScore, applications } = scan;

  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent={{ sm: 'space-between' }}
      flexDirection={{ sm: 'column', xl: 'row' }}
    >
      <Flex w="full" justifyContent="space-between" flexDirection={{ sm: 'column', md: 'row' }}>
        <ScoreCard title={t('admin.devices.detail.scorePanel.device')} score={globalScore?.device_score || null} />
        <ScoreCard
          title={t('admin.devices.detail.scorePanel.network')}
          score={globalScore?.network_score || null}
          ml={{ md: '1rem' }}
        />
      </Flex>
      <Flex w="full" justifyContent="space-between" flexDirection={{ sm: 'column', md: 'row' }} ml={{ xl: '1rem' }}>
        <ScoreCard title={t('admin.devices.detail.scorePanel.privacy')} score={globalScore?.privacy_score || null} />
        {platform !== 'windows'
          ? (
            <ScoreCard
              title={t('admin.devices.detail.scorePanel.apps')}
              score={globalScore?.apps_score || null}
              ml={{ md: '1rem' }}
            />
          )
          : (
            <ScoreCard
              title={t('admin.devices.detail.scorePanel.apps')}
              score={applications?.security_score || null}
              ml={{ md: '1rem' }}
            />
          )
        }

      </Flex>
    </Flex>
  );
};

export default ScorePanel;
