import React from 'react';

import { HStack, Icon, Text } from '@ramp/components';
import { IconType } from 'react-icons/lib';
import { RxDotFilled } from 'react-icons/rx';


interface ScanStatusIndicatorProps {
  status: 'inProgress' ;
}

interface Status {
  text: string;
  icon: IconType;
  color: string;
}

const statuses: Record<string, Status> = {
  inProgress: { text: 'In progress', icon: RxDotFilled, color: 'yellow.500' },
};

export const ScanStatusIndicator: React.FC<ScanStatusIndicatorProps> = ({ status }) => {
  const statusData = statuses[status];

  return (
    <HStack color={statusData.color} spacing={1}>
      <Icon as={statusData.icon} w={4} h={4} />
      <Text fontWeight='semibold'>{statusData.text}</Text>
    </HStack>
  );
};
