import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useAuthStore } from 'store/auth';
import { Navigate, useParams } from 'react-router-dom';
import { PRODUCT_NAME } from 'utils/constants';
import { useQuery } from '@tanstack/react-query';
import { VStack, Spinner } from '@ramp/components';

import api from 'api';
import Logo from 'components/Logo';

import QuizPage from './quiz';
import StartPage from './StartPage';


const TestAssignment: React.FC = () => {
  const logoutUser = useAuthStore((store) => store.logoutUser);
  const { assignmentToken } = useParams<{ assignmentToken: string }>();

  const [startQuiz, setStartQuiz] = useState(false);

  if (!assignmentToken) {
    return <Navigate to="/" />;
  }

  const quizAssignment = useQuery({
    queryKey: ['quiz', assignmentToken],
    queryFn: () => api.users.getQuizAssignment(assignmentToken || ''),
  });

  useEffect(() => {
    window.document.title = `${PRODUCT_NAME} | Quiz`;

    const searchParams = new URLSearchParams(window.location.search);
    const noLogout = searchParams.get('noLogout') === 'true';

    if (!noLogout) {
      logoutUser();
    }
  }, []);

  useEffect(() => {
    if (quizAssignment.data) {
      console.log(quizAssignment.data);
    }
  }, [quizAssignment.data]);

  return (
    <VStack
      w="full"
      maxW="480px"
      h="100vh"
      mx="auto"
      px={['16px']}
      pb={['16px']}
      pt={['16px']}
      alignItems="center"
      justifyContent="start"
    >
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          zIndex: 10,
        }}
      >
        <VStack
          w="full"
          h="full"
          spacing="16px"
          alignItems="center"
          justifyContent="center"
        >
          <Logo />
          {quizAssignment.isLoading ? (
            <VStack
              w="full"
              h="calc(100vh - 320px)"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size="md" color="brand.500" />
            </VStack>
          ) : !startQuiz
            ? <StartPage assignment={quizAssignment.data!} setStartQuiz={setStartQuiz} />
            : <QuizPage assignment={quizAssignment.data!} assignmentToken={assignmentToken} />
          }
        </VStack>
      </motion.div>
    </VStack>
  );
};

export default TestAssignment;
