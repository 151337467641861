import React, { useMemo } from 'react';
import { colorHex } from '@ramp/theme';
import { Tracker } from 'types/api/devices';
import { useTranslation } from 'react-i18next';
import { ColumnsType, RTable, Tag } from '@ramp/components';

import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';


interface TrackersTableProps {
  id: string;
  loading?: boolean;
  trackers?: Tracker[];
}

interface TrackerRow extends Tracker {
  key: number;
}

const TrackersTable: React.FC<TrackersTableProps> = ({ id, loading, trackers }) => {
  if (!trackers) return null;

  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, 10);

  const columns: ColumnsType<TrackerRow> = useMemo(() => ([
    {
      title: t('components.table.trackers.name'),
      key: 'name',
      align: 'left',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
      },
    },
    {
      title: 'URL',
      key: 'url',
      align: 'left',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
      },
      render: url => (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: colorHex('brand.500') }}
        >
          {url}
        </a>
      )
    },
    {
      title: t('components.table.trackers.trackerCategories'),
      key: 'categories',
      align: 'left',
      render: (_, tracker) => {
        return (
          <>
            {tracker.categories.map((category, index) => (
              <Tag key={index} size="sm" colorScheme="brand">{category}</Tag>
            ))}
          </>
        );
      }
    }
  ]), []);

  const formattedTrackers = useMemo(() => {
    return trackers.map((tracker, key) => ({ key, ...tracker }));
  }, [trackers]);

  return (
    <RTable
      columns={columns}
      loading={loading}
      data={formattedTrackers}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      interleaveRows
    />
  );
};

export default TrackersTable;