import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ArrowRight, Info } from 'lucide-react';
import {
  Button,
  ColumnsType,
  HStack,
  RTable,
  Tag,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
} from '@ramp/components';

import { UserQuizAssignment } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import TextTruncateTooltip from 'components/TextTruncateTooltip';

import QuestionDetail from '../QuizSelectTable/QuestionDetail';


interface QuizAssignmentsTableProps {
  id: string;
  loading?: boolean;
  defaultPageSize?: number;
  quizAssignments: UserQuizAssignment[];
}

interface QuizAssignmentRow extends UserQuizAssignment {
  key: number | string;
}

const QuizAssignmentsTable: React.FC<QuizAssignmentsTableProps> = ({
  id,
  defaultPageSize = 50,
  quizAssignments,
  loading,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const formattedQuizAssignments: QuizAssignmentRow[] = useMemo(() => {
    return quizAssignments.map((qa, key) => ({ key, ...qa }));
  }, [quizAssignments]);

  const columns: ColumnsType<QuizAssignmentRow> = useMemo(
    () => [
      {
        key: 'title',
        width: '30%',
        title: t('components.table.quizAssignments.title'),
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          sortingFn: (a, b) => a.original.quiz.title.localeCompare(b.original.quiz.title),
        },
        render: (_, { quiz }) => (
          <Text fontSize="md" lineHeight={1} fontWeight={600}>
            <TextTruncateTooltip text={quiz.title} maxNumOfChars={100} />
          </Text>
        ),
      },
      {
        key: 'keywords',
        title: t('components.table.quizAssignments.keywords'),
        render: (_, { quiz }) => (
          <HStack spacing="4px">
            {quiz.keywords.map((keyword) => (
              <Tag key={keyword} size="sm" colorScheme="gray">
                <span style={{ position: 'relative', top: '1px' }}>
                  {keyword.toUpperCase()}
                </span>
              </Tag>
            ))}
          </HStack>
        ),
      },
      {
        key: 'successRate',
        title: t('components.table.quizAssignments.successRate.title2'),
        align: 'center',
        sorting: true,
        sortingOptions: {
          dataType: 'number',
          sortingFn: (a, b) => (a.original.success_rate || 0) - (b.original.success_rate || 0),
        },
        render: (_, { success_rate }) => {
          if (!success_rate) return (
            <Tag size="md" colorScheme="gray">
              {t('components.table.quizAssignments.noData')}
            </Tag>
          );

          const getColorScheme = () => {
            if (success_rate >= 66) return 'green';
            if (success_rate >= 33) return 'yellow';
            if (success_rate > 0) return 'error';
            return 'gray';
          };

          return (
            <Tooltip label={t('components.table.quizAssignments.scoreTooltip')} placement="top">
              <HStack spacing="6px" align="end" justify="center">
                <Tag size="md" colorScheme={getColorScheme()}>
                  <span style={{ position: 'relative', top: '1.5px' }}>
                    {`${success_rate.toFixed(2)}%`}
                  </span>
                </Tag>
              </HStack>
            </Tooltip>
          );
        },
      },
      {
        key: 'completionDate',
        width: '10%',
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          sortingFn: (a, b) => dayjs(a.original.completion_date).diff(dayjs(b.original.completion_date)),
        },
        title: t('components.table.quizAssignments.completationDate'),
        render: (_, { completion_date }) => {
          if (!completion_date) return t('components.permissionRow.never');
          return dayjs(completion_date).format('DD. MM. YYYY (HH:mm)');
        },
      },
      {
        key: 'created',
        width: '10%',
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          defaultSortOrder: 'desc',
          sortingFn: (a, b) => dayjs(a.original.created).diff(dayjs(b.original.created)),
        },
        title: t('components.table.quizAssignments.created'),
        render: (_, { created }) => {
          if (!created) return t('components.permissionRow.never');
          return dayjs(created).format('DD. MM. YYYY (HH:mm)');
        },
      },
      {
        key: 'actions',
        title: t('admin.dashboard.issues.table.actions'),
        render: (_, { assignment_token, completion_date }) => (
          <Button
            variant="brand"
            size="sm"
            rightIcon={!completion_date ? <ArrowRight size={14} /> : undefined}
            isDisabled={!!completion_date}
            onClick={() => {
              if (!completion_date) {
                window.open(`/test/${assignment_token}?noLogout=true`, '_blank');
              }
            }}
          >
            {completion_date
              ? `${t('base.test.completedText2')}`
              : t('base.test.testStartText')
            }
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <RTable<QuizAssignmentRow>
      data={formattedQuizAssignments}
      columns={columns}
      loading={loading}
      expandable={{
        rowExpandable: () => true,
        render: (assignment) => {
          const timeSpent = useMemo(() => {
            return (
              assignment.answers.reduce(
                (acc, curr) => acc + curr.spent_time_ms,
                0
              ) / 1000
            );
          }, [assignment.answers]);

          return (
            <HStack w="full" spacing={4} align="start" pt={4} pb={4} pr={4}>
              <VStack
                w="66%"
                align="start"
                spacing={4}
                p={4}
                bg={useColorModeValue('gray.25', 'gray.750')}
                borderRadius="md"
              >
                <VStack w="full" spacing={2} align="start">
                  <Text fontSize="sm" color="gray.400">
                    {t('admin.quizzes.table.answers')}
                  </Text>
                  <VStack w="full" spacing={2}>
                    {assignment.quiz.questions.map((question, idx) => (
                      <QuestionDetail
                        key={idx}
                        index={idx}
                        question={question}
                        answers={assignment.answers}
                        simple
                        onCard
                      />
                    ))}
                  </VStack>
                </VStack>
              </VStack>
              <HStack
                w="33%"
                spacing={2}
                align="start"
                p={4}
                bg={useColorModeValue('gray.25', 'gray.750')}
                borderRadius="md"
              >
                <VStack
                  w="full"
                  spacing={6}
                  align="start"
                  p={4}
                  bg={useColorModeValue('gray.25', 'gray.750')}
                  borderRadius="md"
                >
                  <VStack w="fit-content" spacing={2} align="start">
                    <Text fontSize="4xl" fontWeight={500} lineHeight={1}>
                      {((assignment.score / assignment.quiz.max_score) * 100).toFixed(2)}%
                    </Text>
                    <Text
                      fontSize="sm"
                      color="gray.400"
                      lineHeight={1}
                      display="flex"
                      alignItems="start"
                    >
                      {t(
                        'components.table.quizAssignments.successRate.title2'
                      )}
                    </Text>
                  </VStack>
                </VStack>

                <VStack
                  w="full"
                  spacing={6}
                  align="start"
                  p={4}
                  bg={useColorModeValue('gray.25', 'gray.750')}
                  borderRadius="md"
                >
                  <Tooltip
                    label={t(
                      'components.table.quizAssignments.spentTime.tooltip'
                    )}
                    placement="top"
                  >
                    <VStack w="fit-content" spacing={2} align="start">
                      <Text fontSize="4xl" fontWeight={500} lineHeight={1}>
                        {timeSpent.toFixed(2)}
                        {!timeSpent ? '' : 's'}
                      </Text>
                      <Text
                        fontSize="sm"
                        color="gray.400"
                        lineHeight={1}
                        display="flex"
                        alignItems="start"
                      >
                        <Info size={12} />
                        &nbsp;{' '}
                        {t(
                          'components.table.quizAssignments.spentTime.title'
                        )}
                      </Text>
                    </VStack>
                  </Tooltip>
                </VStack>
              </HStack>
            </HStack>
          );
        },
      }}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      interleaveRows
    />
  );
};

export default QuizAssignmentsTable;
