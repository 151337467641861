import React, { useMemo } from 'react';
import { Client } from 'types';
import { BiServer } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ColumnsType, HStack, RTable, SeverityNumTag, Tooltip, Text, Tag } from '@ramp/components';

import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import { parseRoute, usePlatformIcon } from 'utils/utils';
import useTablePageSize from 'utils/hooks/useTablePageSize';


interface CustomersTableProps {
  id: string,
  defaultPageSize?: number,
  defaultCustomers: Client[],
  filteredCustomers: Client[],
  loading?: boolean,
}

interface CustomerRow extends Client {
  key: number
}

const CustomersTable: React.FC<CustomersTableProps> = ({
  id,
  defaultPageSize,
  defaultCustomers,
  filteredCustomers = [],
  loading
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, loginUserAsAdmin } = useAuthStore(store => store);
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize || 10);

  const columns: ColumnsType<CustomerRow> = [
    {
      title: t('components.table.customers.customer'),
      key: 'name',
      align: 'center',
      sorting: true,
      sortingOptions: {
        defaultSortOrder: 'asc',
        dataType: 'string',
      },
    },
    {
      title: t('components.table.customers.mobileDevices'),
      key: 'mobile_devices',
      align: 'center',
      children: [
        {
          title: (
            <Tooltip label="And/iOS/iPadOS">
              <HStack align="center" justify="center">
                {usePlatformIcon('android')}
                <Text>/</Text>
                {usePlatformIcon('macos')}
                <Text>/</Text>
                {usePlatformIcon('macos')}
              </HStack>
            </Tooltip>
          ),
          key: 'mobile_devices',
          align: 'center',
          sorting: true,
          sortingOptions: {
            dataType: 'number',
            sortingFn: (a, b) => {
              const aDevices = a.original.devices;
              const bDevices = b.original.devices;

              // eslint-disable-next-line max-len
              const aTotal = aDevices.count_android_devices + aDevices.count_ios_devices + aDevices.count_ipados_devices;
              // eslint-disable-next-line max-len
              const bTotal = bDevices.count_android_devices + bDevices.count_ios_devices + bDevices.count_ipados_devices;

              return aTotal - bTotal;
            },
          },
          render: (_, firm) => (
            <Tooltip label="And/iOS/iPadOS">
              <HStack align="center" justify="center">
                <Text>{firm.devices.count_android_devices}</Text>
                <Text>/</Text>
                <Text>{firm.devices.count_ios_devices}</Text>
                <Text>/</Text>
                <Text>{firm.devices.count_ipados_devices}</Text>
              </HStack>
            </Tooltip>
          ),
        },
      ],
    },
    {
      title: t('components.table.customers.desktopDevices'),
      key: 'desktop_devices',
      align: 'center',
      children: [
        {
          title: (
            <Tooltip label="Win/macOS">
              <HStack align="center" justify="center">
                {usePlatformIcon('windows')}
                <Text>/</Text>
                {usePlatformIcon('macos')}
              </HStack>
            </Tooltip>
          ),
          key: 'desktop_devices',
          align: 'center',
          sorting: true,
          sortingOptions: {
            dataType: 'number',
            sortingFn: (a, b) => {
              const aDevices = a.original.devices;
              const bDevices = b.original.devices;

              const aTotal = aDevices.count_windows_devices + aDevices.count_macos_devices;
              const bTotal = bDevices.count_windows_devices + bDevices.count_macos_devices;

              return aTotal - bTotal;
            },
          },
          render: (_, firm) => (
            <Tooltip label="Win/macOS">
              <HStack align="center" justify="center">
                <Text>{firm.devices.count_windows_devices}</Text>
                <Text>/</Text>
                <Text>{firm.devices.count_macos_devices}</Text>
              </HStack>
            </Tooltip>
          ),
        },
      ],
    },
    {
      title: t('components.table.customers.issues'),
      key: 'issues',
      align: 'center',
      children: [
        {
          title: t('components.table.customers.high'),
          key: 'high_issues',
          align: 'center',
          sorting: true,
          render: (_, firm) => firm.total_severity_count.high,
        },
        {
          title: t('components.table.customers.medium'),
          key: 'medium_issues',
          align: 'center',
          sorting: true,
          render: (_, firm) => firm.total_severity_count.medium,
        },
        {
          title: t('components.table.customers.low'),
          key: 'low_issues',
          align: 'center',
          sorting: true,
          render: (_, firm) => firm.total_severity_count.low,
        },
      ],
    },
    {
      title: t('components.table.customers.riskScore'),
      key: 'riskScore',
      align: 'center',
      children: [
        {
          title: t('components.table.customers.devices'),
          key: 'device_score',
          align: 'center',
          sorting: true,
          sortingOptions: {
            dataType: 'number',
            defaultSortOrder: 'desc',
            sortingFn: ({ original: a }, { original: b }) =>
              (a.device_score || 0) - (b.device_score || 0),
          },
          render: (_, { device_score }) => {
            if (device_score !== 0) {
              return (
                <HStack justify="center" spacing={0}>
                  <SeverityNumTag severityScore={device_score} />
                  {device_score && <Text pos="relative" top="0.5rem" left="0.2rem">/10</Text>}
                </HStack>
              );
            }

            return <SeverityNumTag severityScore={null} />;
          },
        },
        {
          title: t('components.table.customers.platform'),
          key: 'os_score',
          align: 'center',
          sorting: true,
          sortingOptions: {
            dataType: 'number',
            sortingFn: ({ original: a }, { original: b }) =>
              (a.os_score || 0) - (b.os_score || 0),
          },
          render: (_, { os_score }) => {
            if (os_score !== 0) {
              return (
                <HStack justify="center" spacing={0}>
                  <SeverityNumTag severityScore={os_score} />
                  {os_score && <Text pos="relative" top="0.5rem" left="0.2rem">/10</Text>}
                </HStack>
              );
            }

            return <SeverityNumTag severityScore={null} />;
          },
        },
        {
          title: t('components.table.customers.settings'),
          key: 'settings_score',
          align: 'center',
          sorting: true,
          sortingOptions: {
            dataType: 'number',
            sortingFn: ({ original: a }, { original: b }) =>
              (a.settings_score || 0) - (b.settings_score || 0),
          },
          render: (_, { settings_score }) => {
            if (settings_score !== 0) {
              return (
                <HStack justify="center" spacing={0}>
                  <SeverityNumTag severityScore={settings_score} />
                  {settings_score && <Text pos="relative" top="0.5rem" left="0.2rem">/10</Text>}
                </HStack>
              );
            }

            return <SeverityNumTag severityScore={null} />;
          },
        },
        {
          title: t('components.table.customers.apps'),
          key: 'application_score',
          align: 'center',
          sorting: true,
          sortingOptions: {
            dataType: 'number',
            sortingFn: ({ original: a }, { original: b }) =>
              (a.application_score || 0) - (b.application_score || 0),
          },
          render: (_, { application_score }) => {
            if (application_score !== 0) {
              return (
                <HStack justify="center" spacing={0}>
                  <SeverityNumTag severityScore={application_score} />
                  {application_score && <Text pos="relative" top="0.5rem" left="0.2rem">/10</Text>}
                </HStack>
              );
            }

            return <SeverityNumTag severityScore={null} />;
          },
        },
      ],
    },
    {
      title: t('components.table.customers.approvedTitle'),
      key: 'is_approved',
      align: 'center',
      render: (_, { is_approved }) => {
        if (is_approved) {
          return <Tag colorScheme="green">{t('components.table.customers.approved')}</Tag>;
        }

        return <Tag colorScheme="red">{t('components.table.customers.notApproved')}</Tag>;
      },
    },
    {
      title: t('components.table.customers.console'),
      key: 'console',
      align: 'center',
      render: (_, { id: entityId, name: clientName }) => (
        <Tooltip label="Open Console">
          <Button
            size="sm"
            variant="brand"
            onClick={() => loginUserAsAdmin({ entityId, clientName, customers: defaultCustomers, previousUser: user! })}
          >
            <BiServer style={{ fontSize: '1.15rem' }} />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const formattedCustomers = useMemo(() => {
    return filteredCustomers.map((customer, key) => ({ key, ...customer }));
  }, [filteredCustomers]);

  return (
    <RTable
      columns={columns}
      data={formattedCustomers}
      loading={loading}
      onRowClick={customer =>
        navigate(parseRoute(user!, ROUTES.FIRMADMIN.CUSTOMERS.DETAIL, { id: customer.id }))
      }
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
    />
  );
};

export default CustomersTable;
