import React from 'react';
import { useFormik } from 'formik';
import { Briefcase, User, UserCog, Users } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { object, SchemaOf, string } from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button, FormControl, HStack, Modal, ModalBody, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay, RInput, RSelectOptionV2, RSelectV2, VStack,
} from '@ramp/components';

import api from 'api';
import { notify } from 'utils/notifications';
import CountryFlag from 'components/CountryFlag';
import { getCountryCode, getRole } from 'utils/utils';
import { RSelectCountry } from 'components/RSelectCountry';
import { APIError, Nullable, UserDataWithIssues } from 'types';


interface IUserEdit {
  name: string,
  position?: Nullable<string>,
  country: string,
  language: string,
  role: string,
}

interface UserEditModalProps {
  isOpen: boolean,
  onClose: () => void,
  user?: UserDataWithIssues,
  firmAdmin?: boolean,
}

const UserEditValidationSchema: SchemaOf<IUserEdit> = object().shape({
  name: string().required(),
  position: string().notRequired().nullable(),
  country: string().required(),
  language: string().required(),
  role: string().required(),
});

const UserEditModal: React.FC<UserEditModalProps> = ({ isOpen, onClose, user, firmAdmin = false }) => {
  if (!user) return null;

  const query = useQueryClient();
  const { t } = useTranslation();
  const { id: userId } = useParams<{ id: string }>();

  const { handleChange, handleSubmit, values, isSubmitting } = useFormik<IUserEdit>({
    initialValues: {
      name: user.name,
      position: user.position,
      country: user.country,
      language: user.language,
      role: getRole(user.role),
    },
    validationSchema: UserEditValidationSchema,
    onSubmit: async ({ name, position, role }) => {
      api.users.update(userId!, { name, position: position!, country: user.country, language: user.language })
        .then(async () => {
          if (role !== getRole(user.role)) {
            if (role === 'admin') {
              await api.users.promote(userId!);
            } else {
              await api.users.degrade(userId!);
            }
          }

          onClose();
          await query.invalidateQueries(['users', userId]);

          notify.success({
            title: t('admin.users.detail.editModal.successTitle'),
            description: t('admin.users.detail.editModal.successDescription'),
          });
        })
        .catch((err: APIError) => {
          onClose();
          notify.error({
            title: t('admin.users.detail.editModal.errorTitle'),
            description: err.description,
          });
        });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <ModalHeader>{t('admin.users.detail.editModal.title')}</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <RInput
                  id="name"
                  type="text"
                  size="md"
                  icon={<User />}
                  label={t('admin.users.detail.editModal.name')}
                  value={values.name}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl>
                <RInput
                  id="position"
                  type="text"
                  size="md"
                  icon={<Briefcase />}
                  label={t('admin.users.detail.editModal.position')}
                  onChange={handleChange}
                  value={values.position || ''}
                />
              </FormControl>
              <FormControl>
                <RSelectCountry
                  size="md"
                  label={t('admin.settings.countryLanguage.country.title')}
                  value={values.country}
                  searchable={true}
                  onCountrySelect={country => handleChange({
                    target: {
                      id: 'country',
                      value: country ? country.code.iso3 : ''
                    }
                  })}
                  required
                />
              </FormControl>
              <FormControl>
                <RSelectV2
                  size="md"
                  w="full"
                  label={t('components.languageSelect.label')}
                  icon={(
                    <CountryFlag
                      countryCode={getCountryCode(user.language)}
                      style={{ marginRight: '0.5rem' }}
                    />
                  )}
                  value={values.language}
                  onChange={language => handleChange({ target: { id: 'language', value: language } })}
                  required
                >
                  <RSelectOptionV2 icon={<CountryFlag countryCode="gb" />} value="en">English</RSelectOptionV2>
                  <RSelectOptionV2 icon={<CountryFlag countryCode="cz" />} value="cs">Čeština</RSelectOptionV2>
                  <RSelectOptionV2 icon={<CountryFlag countryCode="de" />} value="de">Deutsch</RSelectOptionV2>
                </RSelectV2>
              </FormControl>
              {!firmAdmin && (
                <FormControl>
                  <RSelectV2
                    id="role"
                    size="md"
                    icon={<Users />}
                    label={t('admin.users.detail.editModal.role')}
                    value={values.role}
                    onChange={val => handleChange({ target: { id: 'role', value: val } })}
                    required
                  >
                    <RSelectOptionV2 icon={<UserCog size={18} />} value="admin">Admin</RSelectOptionV2>
                    <RSelectOptionV2 icon={<User size={18} />} value="user">
                      {t('components.menu.user')}
                    </RSelectOptionV2>
                  </RSelectV2>
                </FormControl>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={2}>
              <Button size="sm" variant="borderless" onClick={onClose}>
                {t('admin.users.detail.cancel')}
              </Button>
              <Button size="sm" type="submit" variant="brand" isLoading={isSubmitting}>
                {t('admin.users.detail.editModal.update')}
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UserEditModal;
