import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiCheckShield, BiShieldX } from 'react-icons/bi';
import { Button, Grid, GridItem, Tag, TagLabel, Text, useDisclosure, VStack } from '@ramp/components';

import { UserDataWithIssues } from 'types';

import Enable2FAModal from './Enable2FAModal';
import Disable2FAModal from './Disable2FAModal';


interface TwoFASettingProps {
  user?: UserDataWithIssues;
}

const TwoFASetting: React.FC<TwoFASettingProps> = ({ user }) => {
  if (!user) return null;

  const { t } = useTranslation();
  const { isOpen: isDisableOpen, onOpen: onDisableOpen, onClose: onDisableClose } = useDisclosure();
  const { isOpen: isEnableOpen, onOpen: onEnableOpen, onClose: onEnableClose } = useDisclosure();

  return (
    <Grid w="full" templateColumns="1fr 1.4fr" gap={14}>
      <GridItem w="100%">
        <VStack w="full" align="start">
          <Text fontWeight={600} fontSize="1.25rem">{t('admin.accountSettings.2fa.title')}</Text>
          <Text fontWeight={300} fontSize="1rem">
            {t('admin.accountSettings.2fa.description')}
          </Text>
        </VStack>
      </GridItem>
      <GridItem w="100%">
        <VStack w="full" align="start" spacing={6}>
          <Text fontSize="1rem">
            Status:
            &nbsp;
            {user.otp_enabled
              ? (
                <Tag size="md" variant="subtle" bg="success.500" color="white">
                  <BiCheckShield style={{ fontSize: '1.1rem' }} />
                  &nbsp;
                  <TagLabel>{t('admin.users.filters.enabled')}</TagLabel>
                </Tag>
              )
              : (
                <Tag size="md" variant="subtle" bg="error.500" color="white">
                  <BiShieldX style={{ fontSize: '1.1rem' }} />
                  &nbsp;
                  <TagLabel>{t('admin.users.filters.disabled')}</TagLabel>
                </Tag>
              )
            }
          </Text>
          {user.otp_enabled
            ? (
              <Button
                size="sm"
                variant="brand"
                onClick={onDisableOpen}
                leftIcon={<BiShieldX style={{ fontSize: '1.2rem' }} />}
              >
                {t('admin.accountSettings.2fa.btnDisable')}
              </Button>
            )
            : (
              <Button
                size="md"
                variant="solid"
                borderRadius="8px"
                onClick={onEnableOpen}
                leftIcon={<BiCheckShield style={{ fontSize: '1.2rem' }} />}
              >
                {t('admin.accountSettings.2fa.btnEnable')}
              </Button>
            )
          }
        </VStack>
        <Enable2FAModal isOpen={isEnableOpen} onClose={onEnableClose} />
        <Disable2FAModal isOpen={isDisableOpen} onClose={onDisableClose} />
      </GridItem>
    </Grid>
  );
};

export default TwoFASetting;
