import React, { useEffect, useState } from 'react';
import { FiUser } from 'react-icons/fi';
import { useFloating, offset } from '@floating-ui/react';
import { Android, Apple, Linux, User, Windows } from '@ramp/icons';
import { Box, HStack, Spinner, Tag, Text, useColorModeValue, VStack } from '@ramp/components';

import api from 'api';
import { UserDataWithIssues } from 'types';


interface UserTooltipProps {
  userId: string;
  userName: string;
}

const UserTooltip: React.FC<UserTooltipProps> = ({ userId, userName }) => {
  const { refs, floatingStyles } = useFloating({ placement: 'top', middleware: [offset(16)] });

  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState<UserDataWithIssues>();

  useEffect(() => {
    if (isOpen && !user) api.users.getUser(userId).then(setUser);
  }, [isOpen]);

  return (
    <>
      <Tag
        ref={refs.setReference}
        colorScheme="unknown"
        id="user-link"
        _hover={{
          textDecor: 'underline',
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Text fontSize="sm">
          <FiUser />
        </Text>
        <Text
          fontSize="sm"
          id="user-link"
          pos="relative"
          top="1px"
          ml="0.25rem"
        >
          {userName}
        </Text>
      </Tag>
      {isOpen && (
        <VStack
          ref={refs.setFloating}
          style={floatingStyles}
          px="12px"
          py="12px"
          spacing="12px"
          borderRadius="12px"
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow="2xl"
          zIndex={1000}
        >
          {!user && <Spinner size="md" color="brand.500" />}
          {user && (
            <React.Fragment>
              <HStack
                w="full"
                pb="12px"
                borderBottom="1px solid"
                borderColor={useColorModeValue('gray.10', 'gray.700')}
              >
                <Box p="8px" borderRadius="8px" bg={useColorModeValue('gray.25', 'gray.750')}>
                  <User size={32} />
                </Box>
                <VStack spacing="0px" align="start">
                  <Text fontSize="16px" fontWeight="500">{user.name}</Text>
                  <Text fontSize="14px" fontWeight="400">{user.email}</Text>
                </VStack>
              </HStack>
              <HStack px="4px" spacing="24px">
                <HStack spacing="8px">
                  <Apple size={16} />
                  <Text fontSize="16px" fontWeight="500">{user.ios_devices}</Text>
                </HStack>
                <HStack spacing="8px">
                  <Android size={16} />
                  <Text fontSize="16px" fontWeight="500">{user.android_devices}</Text>
                </HStack>
                <HStack spacing="8px">
                  <Apple size={16} />
                  <Text fontSize="16px" fontWeight="500">{user.ipados_devices}</Text>
                </HStack>
                <Box w="1px" h="18px" bg={useColorModeValue('gray.25', 'gray.700')} />
                <HStack spacing="8px">
                  <Apple size={16} />
                  <Text fontSize="16px" fontWeight="500">{user.macos_devices}</Text>
                </HStack>
                <HStack spacing="8px">
                  <Windows size={16} />
                  <Text fontSize="16px" fontWeight="500">{user.windows_devices}</Text>
                </HStack>
                <HStack spacing="8px">
                  <Linux size={16} />
                  <Text fontSize="16px" fontWeight="500">{user.linux_devices}</Text>
                </HStack>
              </HStack>
            </React.Fragment>
          )}
        </VStack>
      )}
    </>
  );
};

export default UserTooltip;
