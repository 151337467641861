import React from 'react';
import { RMenuGroup } from '@ramp/components';
import { useTranslation } from 'react-i18next';
import { AiOutlineNotification } from 'react-icons/ai';
import {
  Settings, Smartphone, Home, Shield, AlertTriangle,
  Users, Network, GraduationCap, Lightbulb,
  Radiation,
} from 'lucide-react';

import { Role } from 'types';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import Layout from 'components/Layout';
import { hasRole, parseRoute } from 'utils/utils';


const withUserLayout = (Page: React.ComponentType) => {
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);

  const adminMenuItems: RMenuGroup[] = [
    {
      title: t('admin.menu.main.title'),
      items: [
        {
          key: 'admin.dashboard',
          to: parseRoute(user, ROUTES.ADMIN.DASHBOARD),
          content: t('admin.menu.main.dashboard'),
          icon: <Home size={16} />,
        },
        {
          key: 'admin.devices',
          to: `${parseRoute(user, ROUTES.ADMIN.DEVICES.LIST)}?dev.status=active`,
          content: t('admin.menu.main.devices'),
          icon: <Smartphone size={16} />,
        },
        {
          key: 'admin.networks',
          to: parseRoute(user, ROUTES.ADMIN.NETWORKS.LIST),
          content: t('admin.menu.main.networks'),
          icon: <Network size={16} />,
        },
        {
          key: 'admin.privacy-protection',
          to: parseRoute(user, ROUTES.ADMIN.PRIVACY_PROTECTION),
          content: t('admin.menu.main.privacyProtection'),
          icon: <Shield size={16} />,
        },
        {
          key: 'admin.issues',
          to: parseRoute(user, ROUTES.ADMIN.ISSUES.LIST),
          content: t('admin.menu.main.issues'),
          icon: <AlertTriangle size={16} />,
        },
        {
          key: 'admin.education',
          to: parseRoute(user, ROUTES.ADMIN.EDUCATION.TESTS),
          content: t('admin.menu.main.education.title'),
          icon: <GraduationCap size={16} />,
          items: [
            {
              key: 'admin.education.tests',
              to: parseRoute(user, ROUTES.ADMIN.EDUCATION.TESTS),
              content: t('admin.menu.main.education.tests'),
              icon: <GraduationCap size={16} />,
            },
            {
              key: 'admin.education.local-threats',
              to: parseRoute(user, ROUTES.ADMIN.EDUCATION.LOCAL_THREATS),
              content: t('admin.menu.main.education.localThreats'),
              icon: <Radiation size={16} />,
            },
            {
              key: 'admin.education.recommendations',
              to: parseRoute(user, ROUTES.ADMIN.EDUCATION.RECOMMENDATIONS),
              content: t('admin.menu.main.education.recommendations'),
              icon: <Lightbulb size={16} />,
            }
          ]
        },
        {
          key: 'admin.notifications',
          to: parseRoute(user, ROUTES.ADMIN.NOTIFICATIONS.LIST),
          content: t('admin.menu.main.notifications'),
          icon: <AiOutlineNotification size={16} />,
        },
      ],
    },
    {
      title: t('admin.menu.companyManagement.title'),
      items: [
        {
          key: 'admin.users',
          to: parseRoute(user, ROUTES.ADMIN.USERS.LIST),
          content: t('admin.menu.companyManagement.users'),
          icon: <Users size={16} />,
        },
        {
          key: 'admin.global',
          to: parseRoute(user, ROUTES.ADMIN.GLOBAL_SETTINGS),
          content: t('admin.menu.companyManagement.settings'),
          icon: <Settings size={16} />,
        },
      ],
    },
  ];

  const userMenuItems: RMenuGroup[] = [
    {
      title: t('user.menu.main.title'),
      items: [
        {
          key: 'user.dashboard',
          to: parseRoute(user, ROUTES.USER.DASHBOARD),
          content: t('user.menu.main.dashboard'),
          icon: <Home size={16} />,
        },
        {
          key: 'user.devices',
          to: `${parseRoute(user, ROUTES.USER.DEVICES.LIST)}?dev.status=active`,
          content: t('user.menu.main.devices'),
          icon: <Smartphone size={16} />,
        },
        {
          key: 'user.networks',
          to: parseRoute(user, ROUTES.USER.NETWORKS.LIST),
          content: t('user.menu.main.networks'),
          icon: <Network size={16} />,
        },
        {
          key: 'user.privacy-protection',
          to: parseRoute(user, ROUTES.USER.PRIVACY_PROTECTION),
          content: t('user.menu.main.privacyProtection'),
          icon: <Shield size={16} />,
        },
        {
          key: 'user.issues',
          to: parseRoute(user, ROUTES.USER.ISSUES.LIST),
          content: t('user.menu.main.issues'),
          icon: <AlertTriangle size={16} />,
        },
        {
          key: 'user.education',
          to: parseRoute(user, ROUTES.USER.EDUCATION.TESTS),
          content: t('admin.menu.main.education.title'),
          icon: <GraduationCap size={16} />,
          items: [
            {
              key: 'admin.education.tests',
              to: parseRoute(user, ROUTES.USER.EDUCATION.TESTS),
              content: t('admin.menu.main.education.tests'),
              icon: <GraduationCap size={16} />,
            },
            {
              key: 'admin.education.local-threats',
              to: parseRoute(user, ROUTES.USER.EDUCATION.LOCAL_THREATS),
              content: t('admin.menu.main.education.localThreats'),
              icon: <Radiation size={16} />,
            },
            {
              key: 'admin.education.recommendations',
              to: parseRoute(user, ROUTES.USER.EDUCATION.RECOMMENDATIONS),
              content: t('admin.menu.main.education.recommendations'),
              icon: <Lightbulb size={16} />,
            }
          ]
        },
        {
          key: 'user.notifications',
          to: parseRoute(user, ROUTES.USER.NOTIFICATIONS.LIST),
          content: t('user.menu.main.notifications'),
          icon: <AiOutlineNotification size={16} />,
        },
      ],
    },
    {
      title: t('user.menu.management.title'),
      items: [
        {
          key: 'user.account',
          to: parseRoute(user, ROUTES.USER.ACCOUNT_SETTINGS),
          content: t('user.menu.management.accountSettings'),
          icon: <Settings size={16} />,
        },
      ],
    },
  ];

  // If logged user is admin, show also admin menu items
  const menuItems: { title: 'user' | 'admin', items: RMenuGroup[] }[] = hasRole(user.role, Role.admin)
    ? [
      { title: t('components.menu.admin'), items: adminMenuItems },
      { title: t('components.menu.user'), items: userMenuItems }
    ]
    : [ { title: t('components.menu.user'), items: userMenuItems } ];

  return (
    <Layout
      type="user"
      menuItems={menuItems}
      page={Page}
    />
  );
};

export default withUserLayout;
