import React, { useMemo } from 'react';
import { BoxSelect } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import useNativeLazyLoading from '@charlietango/use-native-lazy-loading';
import { ColumnsType, HStack, SimpleTable, Text } from '@ramp/components';

import { AppInfo } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import { useDeviceDetail } from 'pages/admin/devices/detail/DeviceDetailProvider';


interface UnknownSourceAppsTableProps {
  id: string,
  apps: AppInfo[],
  searchFor?: string,
}

interface UnknownSourceAppRow extends AppInfo {
  key: number
}

const UnknownSourceAppsTable: React.FC<UnknownSourceAppsTableProps> = ({ apps, searchFor = '' }) => {
  const { t } = useTranslation();
  const { appsIcons } = useDeviceDetail();

  const columns: ColumnsType<UnknownSourceAppRow> = [
    {
      title: t('components.table.riskyApps.name'),
      key: 'package_name',
      render: (_, app) => {
        const supportsLazyLoading = useNativeLazyLoading();
        const { ref, inView } = useInView({
          delay: 50,
          threshold: 0,
          triggerOnce: true,
          skip: !supportsLazyLoading,
        });

        return (
          <HStack ref={ref} spacing="16px">
            {(appsIcons && appsIcons[app.package_name])
              ? inView
                ? (
                  <img
                    src={appsIcons[app.package_name] || ''}
                    alt={app.package_name}
                    loading="lazy"
                    style={{ width: '32px', height: '32px', borderRadius: '8px' }}
                  />
                )
                : null
              : <BoxSelect size="32px" />
            }
            <Text>{app.application_name || app.package_name}</Text>
          </HStack>
        );
      },
    },
  ];

  const filteredApps: UnknownSourceAppRow[] = useMemo(() => {
    const formattedApps: UnknownSourceAppRow[] = apps.map((app, key) => ({ key, ...app }));

    return formattedApps.filter(app => {
      return app.package_name.toLowerCase().includes(searchFor) ||
        (app.application_name && app.application_name.toLowerCase().includes(searchFor));
    });
  }, [apps, searchFor]);

  return (
    <SimpleTable
      columns={columns}
      data={filteredApps}
      pagination={{
        showTotal: true,
        defaultPageSize: 10,
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      interleaveRows
    />
  );
};

export default UnknownSourceAppsTable;
