import React, { useMemo } from 'react';
import countries from 'country-list-js';
import { ArrowRight } from 'lucide-react';
import { LocalRecommendation } from 'types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ColumnsType,
  HStack,
  RTable,
  Tag,
  Text,
  VStack,
  useColorModeValue,
} from '@ramp/components';

import CountryFlag from 'components/CountryFlag';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import TextTruncateTooltip from 'components/TextTruncateTooltip';


interface LocalThreatsTableProps {
  id: string;
  loading?: boolean;
  defaultPageSize?: number;
  localThreats: LocalRecommendation[];
}

interface LocalThreatRow extends LocalRecommendation {
  key: number | string;
}

const LocalThreatsTable: React.FC<LocalThreatsTableProps> = ({
  id,
  defaultPageSize = 50,
  localThreats,
  loading,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const formattedLocalThreats: LocalThreatRow[] = useMemo(() => {
    return localThreats.map((rec, key) => ({ key, ...rec }));
  }, [localThreats]);

  const columns: ColumnsType<LocalThreatRow> = useMemo(
    () => [
      {
        key: 'regions',
        width: '20%',
        title: t('components.table.basicIssues.regions'),
        sorting: true,
        sortingOptions: {
          dataType: 'number',
          sortingFn: ({ original: a }, { original: b }) =>
            (a.regions || []).length - (b.regions || []).length,
          defaultSortOrder: 'desc',
        },
        render: (_, { regions }) => (
          <HStack justify="center" spacing={0}>
            {regions.map((region) => {
              const country = countries.findByIso3(region.toUpperCase());

              if (!country) {
                return null;
              }

              return (
                <Tag key={region}>
                  <CountryFlag
                    countryCode={country.code.iso2}
                    style={{
                      position: 'relative',
                      top: '2px',
                      marginRight: '8px',
                    }}
                  />
                  <div style={{ position: 'relative', top: '1px' }}>
                    {country.name}
                  </div>
                </Tag>
              );
            })}
          </HStack>
        ),
      },
      {
        key: 'title',
        width: '20%',
        title: t('components.table.basicIssues.title'),
        render: (_, { title }) => <Text fontWeight={500}>{title}</Text>,
      },
      {
        key: 'description',
        title: t('components.table.basicIssues.description'),
        width: '50%',
        render: (_, { description }) => (
          <TextTruncateTooltip
            text={description || ''}
            maxNumOfChars={240}
            noOfLines={2}
          />
        ),
      },
      {
        key: 'moreInfo',
        width: '10%',
        title: t('components.table.basicIssues.moreInfo'),
        render: (_, { url }) => (
          <Button
            size="sm"
            rightIcon={<ArrowRight size={16} />}
            as="a"
            href={url}
            target="_blank"
          >
            {t('components.table.basicIssues.moreInfo2')}
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <RTable<LocalThreatRow>
      data={formattedLocalThreats}
      columns={columns}
      loading={loading}
      expandable={{
        rowExpandable: () => true,
        render: (localThreat) => (
          <HStack w="full" py={4} pr={8} align="start" spacing={4}>
            <VStack
              w="33%"
              align="start"
              spacing={4}
              p={4}
              bg={useColorModeValue('gray.25', 'gray.750')}
              borderRadius="md"
            >
              <VStack w="full" spacing={0} align="start">
                <Text fontSize="sm" color="gray.400">
                  {t('components.table.basicIssues.title')}
                </Text>
                <Text fontSize="md" fontWeight={500}>
                  {localThreat.title}
                </Text>
              </VStack>
              <VStack w="full" spacing={0} align="start">
                <Text fontSize="sm" color="gray.400">
                  {t('components.table.basicIssues.detailedDescription')}
                </Text>
                <Text fontSize="md" fontWeight={500}>
                  {localThreat.title}
                </Text>
              </VStack>
            </VStack>
            <VStack
              w="33%"
              align="start"
              spacing={4}
              p={4}
              bg={useColorModeValue('gray.25', 'gray.750')}
              borderRadius="md"
            >
              <VStack w="full" spacing={0} align="start">
                <Text fontSize="sm" color="gray.400">
                  {t('components.table.basicIssues.threatForRegions')}
                </Text>
                <HStack justify="center" spacing={0}>
                  {localThreat.regions.map((region) => {
                    const country = countries.findByIso3(region.toUpperCase());

                    if (!country) {
                      return null;
                    }

                    return (
                      <Tag key={region}>
                        <CountryFlag
                          countryCode={country.code.iso2}
                          style={{
                            position: 'relative',
                            top: '2px',
                            marginRight: '8px',
                          }}
                        />
                        <div style={{ position: 'relative', top: '1px' }}>
                          {country.name}
                        </div>
                      </Tag>
                    );
                  })}
                </HStack>
              </VStack>
              <VStack w="full" spacing={0} align="start">
                <Text fontSize="sm" color="gray.400">
                  {t('components.table.basicIssues.moreInfo3')}
                </Text>
                <Text
                  fontSize="md"
                  color="brand.500"
                  textDecoration="underline"
                  as="a"
                  href={localThreat.url}
                  target="_blank"
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  {t('components.table.basicIssues.moreInfo2')}
                  <ArrowRight size={14} />
                </Text>
              </VStack>
            </VStack>
          </HStack>
        ),
      }}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      interleaveRows
    />
  );
};

export default LocalThreatsTable;
