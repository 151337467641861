import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertSecondsToTime, toCapitalize } from 'utils/utils';
import { Description, DescriptionItem, HStack, Tag, Text } from '@ramp/components';

import { IosExtendedDeviceInfo } from 'types/api/devices';


interface IosExtendedInfoProps {
  info: IosExtendedDeviceInfo;
}

const IosExtendedInfo: React.FC<IosExtendedInfoProps> = ({ info }) => {
  const { t } = useTranslation();
  
  return (
    <Description
      size="md"
      columns={{ sm: 1, md: 1, lg: 2, xl: 4, '2xl': 4 }}
    >
      <DescriptionItem label={t('admin.devices.detail.battery.status')}>
        <HStack justify="start" spacing={2}>
          <Text>{toCapitalize(info.battery.status)}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.wirelessCharge')}>
        <HStack justify="start" spacing={2}>
          {info.wireless_charge
            ? <Tag colorScheme="success">{t('admin.devices.detail.sim.multiSimSupported.supported')}</Tag>
            : <Tag colorScheme="error">{t('admin.devices.detail.sim.multiSimSupported.notSupported')}</Tag>
          }
        </HStack>
      </DescriptionItem>
      <DescriptionItem label="MagSafe">
        <HStack justify="start" spacing={2}>
          {info.mag_Safe
            ? <Tag colorScheme="success">{t('admin.devices.detail.sim.multiSimSupported.supported')}</Tag>
            : <Tag colorScheme="error">{t('admin.devices.detail.sim.multiSimSupported.notSupported')}</Tag>
          }
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.ram')}>
        <HStack justify="start" spacing={2}>
          <Text>{(info.ram.actual_usage / 10e8).toFixed(2)}GB / {(info.ram.total_memory / 10e8).toFixed(2)}GB</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.storage')}>
        <HStack justify="start" spacing={2}>
          <Text>
            {(info.storage.actual_storage / 10e8).toFixed(2)}GB / {(info.storage.total_storage / 10e8).toFixed(2)}GB
          </Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label="LiDAR">
        <HStack justify="start" spacing={2}>
          {info.sensors.lidar
            ? <Tag colorScheme="success">{t('admin.devices.detail.sim.multiSimSupported.supported')}</Tag>
            : <Tag colorScheme="error">{t('admin.devices.detail.sim.multiSimSupported.notSupported')}</Tag>
          }
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.uptime')}>
        <HStack justify="start" spacing={2}>
          <Text>
            {info.uptime
              ? convertSecondsToTime(info.uptime)
              : t('admin.devices.detail.device.unknown')
            }
          </Text>
        </HStack>
      </DescriptionItem>
    </Description>
  );
};

export default IosExtendedInfo;