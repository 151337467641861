import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { RCard, Heading, HStack, VStack, Text, Box } from '@ramp/components';

import { RiskScore } from 'types';
import RiskScoreChart from 'components/RiskScoreChart';
import NoDataCardContent from 'components/NoDataCardContent';


interface RiskScoreCardProps {
  title?: string,
  riskScores?: RiskScore[],
}

const calculateChangeInPercentage = (riskScores: RiskScore[]) => {
  const differenceInScores = (riskScores[0].global_score || 0) - (riskScores[riskScores.length - 1].global_score || 0);
  const currentScore = (riskScores[0].global_score || 0);

  const result = (differenceInScores / currentScore) * 100;

  return result === Infinity ? 100 : result;
};

const RiskScoreCard: React.FC<RiskScoreCardProps> = ({ title, riskScores }) => {
  if (!riskScores) return null;

  if (!riskScores || riskScores.length === 0) {
    return (
      <RCard w="full" h="360px" title={title || 'Risk Score'} titleOutside={false}>
        <NoDataCardContent />
      </RCard>
    );
  }

  const { t } = useTranslation();

  const currentRiskScore = riskScores[riskScores.length - 1].global_score || 'Unknown';
  const changeInPercentages = currentRiskScore !== 'Unknown' ? calculateChangeInPercentage(riskScores) : 0;
  const changeDown = (riskScores[0].global_score || 0) > (riskScores[riskScores.length - 1].global_score || 0);

  if (currentRiskScore !== 'Unknown') {
    return (
      <RCard
        w="full"
        h="360px"
        mt="0 !important"
        title={title || t('admin.dashboard.riskScore.title')}
        titleOutside={false}
      >
        <VStack align="start" spacing={1}>
          <HStack align="flex-end" spacing={1}>
            <Heading as="h1" fontSize="2.5rem">{currentRiskScore.toFixed(1)}</Heading>
            <Text fontSize="1rem">/10</Text>
          </HStack>
          <HStack>
            <HStack spacing={1} color={changeDown ? 'green' : 'brand.500'}>
              <Text>{changeInPercentages !== 0 && (changeDown ? <FiArrowDown /> : <FiArrowUp />)}</Text>
              <Text fontWeight={600} fontSize="1.25rem">{`${Math.abs(changeInPercentages).toFixed(1)} %`}</Text>
            </HStack>
            <Text color="gray.400">
              {t('admin.dashboard.riskScore.changeFrom', { date: dayjs(riskScores[0].date).format('DD. MM. YYYY') })}
            </Text>
          </HStack>
        </VStack>
        <Box w="full" pos="relative" top="-1.5rem">
          <RiskScoreChart riskScores={riskScores} />
        </Box>
      </RCard>
    );
  }

  return (
    <RCard
      w="full"
      mt="0 !important"
      titleOutside={false}
      title={t('admin.dashboard.riskScore.title')}
    >
      <Text fontSize="1rem" fontWeight={500} opacity={0.5}>{t('admin.dashboard.riskScore.noData')}</Text>
    </RCard>
  );
};

export default RiskScoreCard;
