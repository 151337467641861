import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  Box, HStack, ModalBody, PinInput, PinInputField,
  PinInputFieldProps, Spinner, Text, VStack,
} from '@ramp/components';

import api from 'api';
import { APIError } from 'types';
import { useAuthStore } from 'store';
import { notify } from 'utils/notifications';


interface Enter2FACodeProps {
  onClose: () => void;
}

const Enter2FACode: React.FC<Enter2FACodeProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user_id: userId } = useAuthStore(store => store.user!);
  const setOtpEnabled = useAuthStore(store => store.setOtpEnabled);

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const onComplete = (otpCode: string) => {
    setShowLoading(true);

    api.users.enableOtp(userId, otpCode)
      .then(async () => {
        await queryClient.invalidateQueries(['user', userId]);
        setOtpEnabled(true);
        onClose();
        setShowLoading(false);
        notify.success({
          title: t('admin.accountSettings.2faModal.successTitle'),
          description: t('admin.accountSettings.2faModal.successDescription'),
        });
      })
      .catch((e: APIError) => {
        onClose();
        setShowLoading(false);
        notify.error({
          title: t('admin.accountSettings.2faModal.errorTitle'),
          description: e.description,
        });
      });
  };

  // TODO: Move this to `ramp` library
  const pinInputFieldStyle: PinInputFieldProps = {
    _focusVisible: {
      borderColor: 'rgba(214, 9, 40, 0.5)',
      boxShadow: 'rgb(214 9 40 / 50%) 0px 0px 0px 1px',
    },
  };

  return (
    <ModalBody>
      <VStack spacing={4} p={4} align="center" justify="center">
        <Text fontWeight={500} fontSize="1.25rem" textAlign="center">
          {t('admin.accountSettings.2faModal.secondTitle')}
        </Text>
        <Text fontWeight={300} fontSize="1rem" textAlign="center">
          {t('admin.accountSettings.2faModal.secondDescription')}
        </Text>
        <Box py={4}>
          {!showLoading && (
              <HStack justify="center">
                <PinInput onComplete={onComplete} otp>
                  <PinInputField {...pinInputFieldStyle} autoFocus />
                  <PinInputField {...pinInputFieldStyle} />
                  <PinInputField {...pinInputFieldStyle} />
                  <Box mx={1} border="none" w={1} />
                  <PinInputField {...pinInputFieldStyle} />
                  <PinInputField {...pinInputFieldStyle} />
                  <PinInputField {...pinInputFieldStyle} />
                </PinInput>
              </HStack>
          )}
          {showLoading && <Spinner size="md" color="brand.500" />}
        </Box>
      </VStack>
    </ModalBody>
  );
};

export default Enter2FACode;
