export const SeverityColumnFilters = [
  {
    text: 'Critical',
    value: 'critical',
  },
  {
    text: 'High',
    value: 'high',
  },
  {
    text: 'Medium',
    value: 'medium',
  },
  {
    text: 'Low',
    value: 'low',
  },
  {
    text: 'None',
    value: 'none',
  },
];

export const PRODUCT_NAME = 'Redamp.io';

export const DEFAULT_PAGE_SIZES = [5, 10, 25, 50, 100, 200];
