import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { colorHex } from '@ramp/theme';
import { useTranslation } from 'react-i18next';
import { RiBuilding2Line } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, HStack, RMenu, RMenuGroup, Text, useRMenu } from '@ramp/components';

import ROUTES from 'router/routes';
import PageOverlay from 'components/PageOverlay';
import { useAuthStore, useLayoutStore } from 'store';
import { getRoleByNumber, parseRoute } from 'utils/utils';
import { useWindowWidth } from 'utils/hooks/useWindowWidth';


interface LayoutProps {
  type: 'user' | 'admin' | 'firmadmin' | 'superadmin',
  menuItems: { title: 'admin' | 'user', items: RMenuGroup[] }[],
  page: React.ComponentType,
}

const Layout: React.FC<LayoutProps> = ({ type, menuItems, page: Page }) => {
  const menuBreakpoint = 1600;

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const { collapsed, toggleMenu, setMenuCollapsed } = useRMenu();
  const user = useAuthStore(store => store.user!);
  const { clientName, customers, previousUser } = useAuthStore(store => store.loginAsAdmin);
  const {
    logoutUser, loginUserAsAdmin, loginAsPartnerAdmin, logoutUserAsAdmin, logoutUserAsPartnerAdmin
  } = useAuthStore(store => store);

  const setLayoutLoading = useLayoutStore(store => store.setLoading);
  const theme = useLayoutStore(store => store.theme);
  const setTheme = useLayoutStore(store => store.setTheme);
  const setDefaultTheme = useLayoutStore(store => store.setDefaultTheme);

  useEffect(() => {
    if (windowWidth < menuBreakpoint && !collapsed) {
      setMenuCollapsed(true);
    }

    if (windowWidth > menuBreakpoint && collapsed) {
      setMenuCollapsed(false);
    }
  }, [windowWidth]);

  useEffect(()=>{
    if (windowWidth < menuBreakpoint && !collapsed) {
      setMenuCollapsed(true);
    }
  }, [location]);

  const getUserRoleInURL = () => location.pathname.split('/')[1];

  const getActiveMenuItemKey: () => string = () => {
    if (type === 'superadmin') {
      return location.pathname.split('/')[1];
    } else if (type === 'firmadmin') {
      return location.pathname.split('/')[3];
    } else {
      let finalPath = `${getUserRoleInURL()}.`;
      const splittedPath = location.pathname.split('/');

      for (let i = 4; i < splittedPath.length; i++) {
        finalPath += `${splittedPath[i]}${i !== splittedPath.length - 1 ? '.' : ''}`;
      }

      return finalPath;
    }
  };

  const userRole = getRoleByNumber(user.role!);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {loginAsPartnerAdmin.entityId && (
        <Box
          top="1rem"
          right="calc(50% - 125px)"
          transform="translateX(50%)"
          px={3}
          py={2}
          position="fixed"
          zIndex={9999}
          bg="brand.500"
          boxShadow="2xl"
          borderRadius="md"
        >
          <Text
            color="white"
            fontSize="1rem"
            fontWeight={500}
            textAlign="center"
            whiteSpace="nowrap"
            display="flex"
            alignItems="center"
          >
            You are logged under the partner account as&nbsp;
            <span style={{ padding: '0.25rem', background: 'black', borderRadius: '4px' }}>
              {loginAsPartnerAdmin.partnerName}
            </span>
            &nbsp;!&nbsp;
            You can go back to&nbsp;&nbsp;
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0.25rem 0.5rem',
                borderRadius: '4px',
                background: 'black',
                color: colorHex('brand.500'),
                textDecoration: 'underline',
                cursor: 'pointer',
                fontWeight: 600,
              }}
              onClick={() => {
                setDefaultTheme();
                logoutUserAsAdmin();
                logoutUserAsPartnerAdmin();
                navigate(ROUTES.SUPERADMIN.DASHBOARD);
              }}
            >
              SUPERADMIN DASHBOARD
              →
            </span>
          </Text>
        </Box>
      )}
      <HStack alignItems="start" height="calc(100vh - 2rem)" spacing={0}>
        <RMenu
          top="1rem"
          position="fixed"
          height="calc(100vh - 2rem)"
          defaultActiveMenuItemIndex={getUserRoleInURL() === 'admin' ? 0 : 1}
          menuItems={menuItems}
          router={pathname => navigate(pathname)}
          user={{ name: user.name!, role: user.email! }}
          activeEnvironmentItem={clientName
            ? {
              name: clientName,
              icon: <RiBuilding2Line />,
              description: 'CUSTOMER',
              onClick: () => null,
            }
            : undefined
          }
          environmentItems={(userRole !== 'superadmin' && userRole !== 'firmadmin')
            ? undefined
            : customers
              .sort((c1: { name: string; }, c2: { name: any; }) => c1.name.localeCompare(c2.name))
              .map((c: { id: string, name: string }) => ({
                name: c.name,
                icon: <RiBuilding2Line />,
                description: 'CUSTOMER',
                onClick: () => {
                  loginUserAsAdmin({
                    previousUser,
                    customers,
                    entityId: c.id,
                    clientName: c.name,
                  });
                  navigate(parseRoute({ ...user, entity_id: c.id }, ROUTES.ADMIN.DASHBOARD, { entityId: c.id }));
                },
              }))}
          onBackToDashboard={() => {
            logoutUserAsAdmin();
            navigate(parseRoute({ ...user, entity_id: null }, ROUTES.SUPERADMIN.DASHBOARD));
          }}
          userMenuItems={[
            {
              name: t('superadmin.menu.management.settings'),
              action: () => {
                switch (getRoleByNumber(user.role)) {
                  case 'superadmin':
                    return navigate(parseRoute(user, ROUTES.SUPERADMIN.SETTINGS));
                  case 'firmadmin':
                    return navigate(parseRoute(user, ROUTES.FIRMADMIN.SETTINGS));
                  case 'admin':
                    return navigate(parseRoute(user, ROUTES.ADMIN.ACCOUNT_SETTINGS));
                  case 'user':
                    return navigate(parseRoute(user, ROUTES.USER.ACCOUNT_SETTINGS));
                  default:
                    return navigate(parseRoute(user, ROUTES.USER.ACCOUNT_SETTINGS));
                }
              },
            },
            {
              name: t('superadmin.menu.management.download_apps'),
              action: () => {
                // Open link on new tab
                window.open('https://redamp.io/en/download', '_blank');
              }
            }
          ]}
          onLogout={() => {
            setLayoutLoading(true);
            setTheme({ ...theme, theme: 'all' });
            logoutUserAsAdmin();
            logoutUserAsPartnerAdmin();
            logoutUser();
          }}
          activeMenuItemKey={getActiveMenuItemKey()}
          isCollapsed={collapsed}
          onCollapsedChange={toggleMenu}
          showThemeToggle={theme.theme === 'all'}
          zIndex={2}
          primaryLogoDark={theme.logo_primary_dark}
          primaryLogoLight={theme.logo_primary_light}
          secondaryLogoDark={theme.logo_secondary_dark}
          secondaryLogoLight={theme.logo_secondary_light}
        />
        <Box
          position="relative"
          pl={(windowWidth > menuBreakpoint && !collapsed) ? '250px' : '80px'}
          width="100%"
        >
          <Page />
        </Box>
      </HStack>
      <PageOverlay breakpoint={menuBreakpoint} />
    </motion.div>
  );
};

export default Layout;
