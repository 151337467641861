import React from 'react';
import { RMenuGroup } from '@ramp/components';
import { useTranslation } from 'react-i18next';
import { Settings, Home, Users } from 'lucide-react';

import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import Layout from 'components/Layout';
import { parseRoute } from 'utils/utils';


const withSuperAdminLayout = (Page: React.ComponentType) => {
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);

  const menuItems: RMenuGroup[] = [
    {
      title: t('superadmin.menu.main.title'),
      items: [
        {
          key: 'dashboard',
          to: parseRoute(user, ROUTES.SUPERADMIN.DASHBOARD),
          content: t('superadmin.menu.main.dashboard'),
          icon: <Home size={16} />,
        },
        {
          key: 'partners',
          to: parseRoute(user, ROUTES.SUPERADMIN.PARTNERS.LIST),
          content: t('superadmin.menu.main.partners'),
          icon: <Users size={16} />,
        },
      ],
    },
    {
      title: t('superadmin.menu.management.title'),
      items: [
        {
          key: 'settings',
          to: parseRoute(user, ROUTES.SUPERADMIN.SETTINGS),
          content: t('superadmin.menu.management.settings'),
          icon: <Settings size={16} />,
        },
      ],
    },
  ];

  return (
    <Layout
      type="superadmin"
      menuItems={[
        { title: t('components.menu.admin'), items: menuItems }
      ]}
      page={Page}
    />
  );
};

export default withSuperAdminLayout;
