import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Divider, RPage, VStack } from '@ramp/components';

import api from 'api';
import { useAuthStore } from 'store';
import { PRODUCT_NAME } from 'utils/constants';
import UserInfoCard from 'components/UserInfoCard';

import TwoFASetting from './2FASetting';
import LanguageSetting from './LanguageSetting';
import ChangePasswordSetting from './ChangePasswordSetting';
import CountrySetting from './CountrySetting';
import CommunicationLanguageSetting from './CommunicationLanguageSetting';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AccountSettingsProps {}

const AccountSettings: React.FC<AccountSettingsProps> = () => {
  const { t } = useTranslation();
  const { user_id: userId } = useAuthStore(store => store.user!);

  const user = useQuery(['user', userId], () => api.users.getUser(userId));

  return (
    <RPage
      px={6}
      pt={8}
      loading={user.isLoading}
      heading={t('admin.accountSettings.heading')}
      title={t('admin.accountSettings.heading', { productName: PRODUCT_NAME })}
    >
      <VStack w="full">
        <VStack w="full" maxW="86rem" spacing={10} pb={12} m="0 auto">

          <UserInfoCard user={user.data} />

          <VStack w="full" maxW="72rem" spacing={10} pt={4} m="0 auto">
            <LanguageSetting />
            <Divider />
            <CommunicationLanguageSetting user={user.data} />
            <Divider />
            <CountrySetting user={user.data} />
            <Divider />
            <TwoFASetting user={user.data} />
            <Divider />
            <ChangePasswordSetting />
          </VStack>

        </VStack>
      </VStack>
    </RPage>
  );
};

export default AccountSettings;
