import React from 'react';
import { Tag } from '@ramp/components';
import { useTranslation } from 'react-i18next';
import { Network, Monitor, Building2, AtSign, Globe, User, LayoutGrid } from 'lucide-react';

import { IssueObjectType } from 'types';


interface IssueObjectTypeTagProps {
  type: IssueObjectType;
}

const IssueObjectTypeTag: React.FC<IssueObjectTypeTagProps> = ({ type }) => {
  const { t } = useTranslation();

  if (!type) return <Tag colorScheme="error">{t('components.networkType.unknown')}</Tag>;

  if (type === 'network') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap">
        <Globe size="16px" />
        &nbsp;
        {t('components.issueObjectType.network')}
      </Tag>
    );
  }

  if (type === 'device') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap">
        <Monitor size="16px" />
        &nbsp;
        {t('components.issueObjectType.device')}
      </Tag>
    );
  }

  if (type === 'client') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap">
        <Building2 size="16px" />
        &nbsp;
        {t('components.issueObjectType.client')}
      </Tag>
    );
  }

  if (type === 'email') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap">
        <AtSign size="16px" />
        &nbsp;
        {t('components.issueObjectType.email')}
      </Tag>
    );
  }

  if (type === 'network_device') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap">
        <Network size="16px" />
        &nbsp;
        {t('components.issueObjectType.networkDevice')}
      </Tag>
    );
  }

  if (type === 'user') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap">
        <User size="16px" />
        &nbsp;
        {t('components.issueObjectType.user')}
      </Tag>
    );
  }

  if (type === 'app') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap">
        <LayoutGrid size="16px" />
        &nbsp;
        {t('components.issueObjectType.app')}
      </Tag>
    );
  }

  return <Tag colorScheme="gray">{t('components.networkType.unknown')}</Tag>;
};

export default IssueObjectTypeTag;
