import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { RCard, RPage, VStack } from '@ramp/components';

import api from 'api';
import { useAuthStore } from 'store';
import { UserQuizAssignment } from 'types';
import { PRODUCT_NAME } from 'utils/constants';
import UserQuizAssignmentsTable from 'components/tables/UserQuizAssignmentsTable';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface QuizzesListProps {}

const QuizzesList: React.FC<QuizzesListProps> = () => {
  const { t } = useTranslation();
  const userId = useAuthStore(store => store.user!.user_id);

  const [defaultQuizzes, setDefaultQuizzes] = useState<UserQuizAssignment[]>([]);

  const quizAssignments = useQuery(
    ['quizAssignments', userId],
    () => api.users.getAllQuizAssignments(userId),
    {
      onSuccess: data => {
        setDefaultQuizzes(data);
      },
    }
  );

  useEffect(() => {
    console.log(defaultQuizzes);
  }, [defaultQuizzes]);

  return (
    <RPage
      heading={t('admin.quizzes.heading')}
      title={t('admin.quizzes.title', { productName: PRODUCT_NAME })}
      px={6}
      pt={8}
      loading={quizAssignments.isLoading}
    >
      <VStack w="full" align="start" spacing={0}>
        <RCard w="full" titleOutside={false}>
          <UserQuizAssignmentsTable
            id="user_quiz_assignments_table"
            quizAssignments={defaultQuizzes}
            loading={quizAssignments.isLoading}
          />
        </RCard>
      </VStack>
    </RPage>
  );
};

export default QuizzesList;
