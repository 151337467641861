import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import BaseRoutes from './BaseRoutes';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BaseRouterProps {}

const BaseRouter: React.FC<BaseRouterProps> = () => (
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <BaseRoutes />
    </QueryParamProvider>
  </BrowserRouter>
);

export default BaseRouter;
