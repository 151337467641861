import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, GridItem, HStack, Link, Pie, PropertyCard, Text, VStack } from '@ramp/components';

import { WindowsOs } from 'types/api/devices';
import NoDataCardContent from 'components/NoDataCardContent';
import { getSeverityFromScore, getSeverityTextColor } from 'utils/utils';
import { useDeviceDetail } from 'pages/admin/devices/detail/DeviceDetailProvider';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MobilePlatformProps {}

const MobilePlatform: React.FC<MobilePlatformProps> = () => {
  const { t } = useTranslation();
  const { platform, scan, recommendations } = useDeviceDetail();

  if (!platform || !scan || !recommendations) return null;

  const { os, device, global_score: globalScore } = scan;

  if (!os || !device || !globalScore) return null;

  const mobilePlatformScore = globalScore.platform_score || 0;
  const mobilePlatformSeverity = getSeverityFromScore(mobilePlatformScore);

  const osRecommendations = {
    patch: recommendations.find((rec) => rec.group_codename === 'patch') || null,
    os_end_of_life: recommendations.find((rec) => rec.group_codename === 'os_end_of_life') || null,
    device_end_of_life: recommendations.find((rec) => rec.group_codename === 'device_end_of_life') || null,
    vendor_updates: recommendations.find((rec) => rec.group_codename === 'vendor_updates') || null,
    system_software_update: recommendations.find((rec) => rec.group_codename === 'system_software_update') || null,
  };

  const availableSystemUpdates = platform === 'windows'
    ? (scan.os as WindowsOs).pending_updates.filter((update) => update.type === 'utSoftware')
    : [];

  const renderDescription = (description: string, link: string) => {
    return (
      <>
        {description}&nbsp;
        <Link
          color="brand.500"
          textDecoration="underline"
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {t('admin.dashboard.issues.table.moreInfo')}
        </Link>
      </>
    );
  };

  return (
    <VStack w={{ sm: 'full', lg: '50%' }}>
      <HStack w="full" alignItems="center" justifyContent="space-between">
        <Text fontSize={{ sm: '24px', md: '28px', lg: '24px', xl: '28px' }} fontWeight={700}>
          {platform === 'windows'
            ? t('admin.devices.detail.device.desktopPlatform').toUpperCase()
            : t('admin.devices.detail.device.mobilePlatform').toUpperCase()
          }
        </Text>
        <HStack spacing={0} alignItems="end">
          <Text
            fontWeight={700}
            fontSize={{ sm: '24px', md: '28px', lg: '24px', xl: '28px' }}
            color={getSeverityTextColor(mobilePlatformSeverity)}
          >
            {mobilePlatformScore.toFixed(1)}
          </Text>
          <Text fontSize={{ sm: '18px', md: '20px', lg: '18px', xl: '20px' }} fontWeight={700}>/10</Text>
        </HStack>
      </HStack>
      <VStack w="full" spacing={4}>
        <PropertyCard
          w="full"
          status={osRecommendations.os_end_of_life
            ? osRecommendations.os_end_of_life.severity
            : t('admin.devices.detail.device.unknown')
          }
          title={osRecommendations.os_end_of_life
            ? osRecommendations.os_end_of_life.group
            : t('admin.devices.detail.device.supportedOs')
          }
          description={osRecommendations.os_end_of_life
            ? renderDescription(
              osRecommendations.os_end_of_life.recommendation_description,
              osRecommendations.os_end_of_life.knowledge_base_url,
            )
            : <i>{t('admin.devices.detail.device.noRecommendation')}</i>
          }
        />
        <PropertyCard
          w="full"
          status={osRecommendations.patch
            ? osRecommendations.patch.severity
            : t('admin.devices.detail.device.unknown')
          }
          title={osRecommendations.patch
            ? osRecommendations.patch.group
            : t('admin.devices.detail.device.securityPatch')
          }
          description={osRecommendations.patch
            ? renderDescription(
              osRecommendations.patch.recommendation_description,
              osRecommendations.patch.knowledge_base_url,
            )
            : <i>{t('admin.devices.detail.device.noRecommendation')}</i>
          }
          openable
        >
          <Flex
            w="full"
            flexDirection={{ sm: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="gray.650"
            pb="1rem"
          >
            <VStack spacing={0} alignItems="center">
              <Text fontSize="md" fontWeight={500} color="gray.200">
                {t('admin.devices.detail.device.installedOs')}
              </Text>
              <Text fontSize="md" fontWeight={500}>{os.version}</Text>
            </VStack>
            <VStack mt={{ sm: '1rem', md: 0 }} spacing={0} alignItems="center">
              <Text fontSize="md" fontWeight={500} color="gray.200">
                {t('admin.devices.detail.device.installedPatch')}
              </Text>
              <Text
                fontSize="md"
                fontWeight={500}
                color={os.patch !== os.latest_available_patch ? 'error.500' : 'gray.200'}
              >
                {os.patch}
              </Text>
            </VStack>
            <VStack mt={{ sm: '1rem', md: 0 }} spacing={0} alignItems="center">
              <Text fontSize="md" fontWeight={500} color="gray.200">
                {t('admin.devices.detail.device.latestPatch')}
              </Text>
              <Text fontSize="md" fontWeight={500}>{os.latest_available_patch}</Text>
            </VStack>
          </Flex>
          <Flex flexDirection={{ sm: 'column', md: 'row' }} mt={{ sm: '1rem' }}>
            {(
              (os.applied_patches + os.unapplied_patches) !== 0
              && os.applied_patches !== null
              && os.unapplied_patches !== null
            )
              ? (
                <VStack w="full" spacing={0} alignItems="start">
                  <Text fontSize="lg" fontWeight={500} color="gray.200">
                    {t('admin.devices.detail.device.patches')}
                  </Text>
                  <Pie
                    width="240px"
                    legendLayout="vertical"
                    alignSelf="center"
                    data={[
                      {
                        gradient: 'red',
                        title: t('admin.devices.detail.device.notApplied'),
                        value: os.unapplied_patches,
                      },
                      {
                        gradient: 'black',
                        title: t('admin.devices.detail.device.applied'),
                        value: os.applied_patches,
                      },
                    ]}
                  />
                </VStack>
              )
              : <NoDataCardContent w="240px" h="380px" />
            }
            {(
              (os.patched_vulnerabilities + os.unpatched_vulnerabilities) !== 0
              && os.patched_vulnerabilities !== null
              && os.unpatched_vulnerabilities !== null
            )
              ? (
                <VStack w="full" spacing={0} alignItems="start" mt={{ sm: '1rem', md: 0 }} ml={{ md: '1rem' }}>
                  <Text fontSize="lg" fontWeight={500} color="gray.200">
                    {t('admin.devices.detail.device.vulnerabilities')}
                  </Text>
                  <Pie
                    width="240px"
                    legendLayout="vertical"
                    alignSelf="center"
                    data={[
                      {
                        gradient: 'red',
                        title: t('admin.devices.detail.device.unpatched'),
                        value: os.unpatched_vulnerabilities,
                      },
                      {
                        gradient: 'black',
                        title: t('admin.devices.detail.device.patched'),
                        value: os.patched_vulnerabilities,
                      },
                    ]}
                  />
                </VStack>
              )
              : <NoDataCardContent w="240px" h="380px" />
            }
          </Flex>
        </PropertyCard>
        {platform === 'android' && (
          <PropertyCard
            w="full"
            status={osRecommendations.vendor_updates
              ? osRecommendations.vendor_updates.severity
              : t('admin.devices.detail.device.unknown')
            }
            title={osRecommendations.vendor_updates
              ? osRecommendations.vendor_updates.group
              : t('admin.devices.detail.device.vendorUpdates')
            }
            description={osRecommendations.vendor_updates
              ? renderDescription(
                osRecommendations.vendor_updates.recommendation_description,
                osRecommendations.vendor_updates.knowledge_base_url,
              )
              : <i>{t('admin.devices.detail.device.noRecommendation')}</i>
            }
          />
        )}
        {(platform === 'ios' || platform === 'ipados' || platform === 'android') && (
          <PropertyCard
            w="full"
            status={osRecommendations.device_end_of_life
              ? osRecommendations.device_end_of_life.severity
              : t('admin.devices.detail.device.unknown')
            }
            title={osRecommendations.device_end_of_life
              ? osRecommendations.device_end_of_life.group
              : t('admin.devices.detail.device.deviceSupport')
            }
            description={osRecommendations.device_end_of_life
              ? renderDescription(
                osRecommendations.device_end_of_life.recommendation_description,
                osRecommendations.device_end_of_life.knowledge_base_url,
              )
              : <i>{t('admin.devices.detail.device.noRecommendation')}</i>
            }
          />
        )}
        {platform === 'windows' && (
          <PropertyCard
            w="full"
            status={osRecommendations.system_software_update
              ? osRecommendations.system_software_update.severity
              : t('admin.devices.detail.device.unknown')
            }
            title={osRecommendations.system_software_update
              ? osRecommendations.system_software_update.group
              : t('admin.devices.detail.device.deviceSupport')
            }
            description={osRecommendations.system_software_update
              ? renderDescription(
                osRecommendations.system_software_update.recommendation_description,
                osRecommendations.system_software_update.knowledge_base_url,
              )
              : <i>{t('admin.devices.detail.device.noRecommendation')}</i>
            }
            openable
          >
            <Grid templateColumns="6fr 1fr" gap={4}>
              <GridItem w="full">
                <Text fontSize="16px" fontWeight={500}>{t('admin.devices.detail.device.titleAndDescription')}</Text>
              </GridItem>
              <GridItem w="full">
                <Text fontSize="16px" fontWeight={500}>{t('admin.devices.detail.device.priority')}</Text>
              </GridItem>
              {availableSystemUpdates.map((update) => (
                <>
                  <GridItem w="full">
                    <VStack alignItems="start" spacing={0}>
                      <Text fontWeight={500}>{update.title}</Text>
                      <Text color="gray.200">{update.description}</Text>
                    </VStack>
                  </GridItem>
                  <GridItem w="full">
                    <Flex w="full" h="full" justifyContent="start" alignItems="center">
                      {update.download_priority.replace('dp', '')}
                    </Flex>
                  </GridItem>
                </>
              ))}
            </Grid>
          </PropertyCard>
        )}
      </VStack>
    </VStack>
  );
};

export default MobilePlatform;
