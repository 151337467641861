import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useColorMode, StyleProps } from '@ramp/components';

import ROUTES from 'router/routes';
import { useLayoutStore } from 'store';

import { ReactComponent as RedampDarkThemeLogo } from 'assets/logo-redamp-dark.svg';
import { ReactComponent as RedampLightThemeLogo } from 'assets/logo-redamp-light.svg';


interface LogoProps extends StyleProps {
  type?: 'redamp' | 'partner';
  style?: React.CSSProperties;
  clickable?: boolean;
}

const Logo: React.FC<LogoProps> = ({ type, style, clickable }) => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const {
    logo_primary_dark: logoPrimaryDark,
    logo_primary_light: logoPrimaryLight
  } = useLayoutStore(store => store.theme);

  const onClick = useMemo(() => {
    return clickable ? () => navigate(ROUTES.BASE.ROOT) : undefined;
  }, [clickable]);

  const innerStyle: React.CSSProperties = useMemo(() => ({
    width: '180px',
    height: 'auto',
    cursor: clickable ? 'pointer' : 'default',
    ...style
  }), [style, clickable]);

  if (type === 'redamp') {
    return colorMode === 'light'
      ? <RedampLightThemeLogo style={innerStyle} onClick={onClick} />
      : <RedampDarkThemeLogo style={innerStyle} onClick={onClick} />;
  }

  return colorMode === 'light'
    ? <img src={logoPrimaryLight} style={innerStyle} onClick={onClick} alt="Logo" />
    : <img src={logoPrimaryDark} style={innerStyle} onClick={onClick} alt="Logo" />;
};

export default Logo;
