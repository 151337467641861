import { useMemo } from 'react';
import { TablePageSize } from 'types';
import { useLayoutStore } from 'store';


type SetTablePageSizeType = (pageSize: number) => void;
type UseTablePageSizeType = (tableId: string, defaultPageSize: number) => [number, SetTablePageSizeType];

const useTablePageSize: UseTablePageSizeType = (tableId, defaultPageSize) => {
  const { tablesPageSizes, setTablePageSize: setTablePageSizeInStore } = useLayoutStore(store => store);

  const tableInStore = useMemo(() => {
    // TODO: temporary solution to fix the bug with the old version of the table page sizes in the local storage
    localStorage.removeItem('ramp_table_v1');

    return tablesPageSizes.find(tableSize => tableSize.id === tableId);
  }, [tablesPageSizes, tableId]);

  const setTablePageSize: SetTablePageSizeType = (pageSize) => {
    let newTablesPageSizes: TablePageSize[];

    if (tableInStore) {
      newTablesPageSizes = tablesPageSizes.map(table => ((table.id === tableId)
        ? { ...table, pageSize }
        : table
      ));
    } else {
      newTablesPageSizes = [...tablesPageSizes, { id: tableId, pageSize }];
    }

    setTablePageSizeInStore(newTablesPageSizes);

    localStorage.setItem('sc_tables_page_sizes', JSON.stringify(newTablesPageSizes));
  };

  if (!tableInStore) {
    setTablePageSize(defaultPageSize);
    return [defaultPageSize, setTablePageSize];
  }

  return [tableInStore.pageSize, setTablePageSize];
};

export default useTablePageSize;
