import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType, SimpleTable, Text } from '@ramp/components';

import { WindowsApp } from 'types/api/devices';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WindowsAppsTableProps {
  id: string;
  apps?: WindowsApp[];
  searchFor?: string;
  unupdated?: boolean;
  selectable?: boolean;
  onSelectionChange?: (selectedRows: WindowsAppsRow[]) => void;
}

interface WindowsAppsRow extends WindowsApp {
  key: number;
}

const WindowsAppsTable: React.FC<WindowsAppsTableProps> = ({
  id,
  apps = [],
  searchFor = '',
  unupdated,
  selectable = false,
  onSelectionChange: onSelectedChanged,
}) => {
  const { t } = useTranslation();

  const unupdatedColumns: ColumnsType<WindowsAppsRow> = [
    {
      title: t('components.table.windowsApps.name'),
      key: 'display_name',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        defaultSortOrder: 'asc',
      }
    },
    {
      title: t('components.table.windowsApps.installedVersion'),
      key: 'installed_version',
      render: installedVersion => (
        <Text color="warning.500">
          {installedVersion || <i>{t('admin.devices.detail.deviceSupport.unknown')}</i>}
        </Text>
      ),
    },
    {
      title: t('components.table.windowsApps.availableVersion'),
      key: 'available_version',
      render: availableVersion => (
        <Text color="success.500">
          {availableVersion || <i>{t('admin.devices.detail.deviceSupport.unknown')}</i>}
        </Text>
      ),
    },
  ];

  const columns: ColumnsType<WindowsAppsRow> = [
    {
      title: t('components.table.windowsApps.name'),
      key: 'display_name',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        defaultSortOrder: 'asc',
      }
    },
    {
      title: t('components.table.windowsApps.installedVersion'),
      key: 'installed_version',
    },
  ];

  const filteredApps: WindowsAppsRow[] = useMemo(() => {
    const formattedApps: WindowsAppsRow[] = apps.map((app, key) => ({
      key,
      ...app,
    }));

    return formattedApps.filter(app => {
      return app.display_name.toLowerCase().includes(searchFor.toLowerCase()) ||
        (app.display_name && app.display_name.toLowerCase().includes(searchFor.toLowerCase()));
    });
  }, [apps, searchFor]);

  return (
    <SimpleTable<WindowsAppsRow>
      id={id}
      columns={unupdated ? unupdatedColumns : columns}
      data={filteredApps}
      pagination={{
        showTotal: true,
        defaultPageSize: 10,
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      interleaveRows
      selectable={selectable && filteredApps.length > 0
        ? {
          rowSelectable: () => true,
          // @ts-ignore
          onChange: selectedRows => onSelectedChanged && onSelectedChanged(selectedRows as WindowsAppsRow[]),
        }
        : undefined
      }
    />
  );
};

export default WindowsAppsTable;