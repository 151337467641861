import { useRef } from 'react';
import { isLoggedIn } from 'axios-jwt';
import { useTranslation } from 'react-i18next';

import { useLayoutStore } from 'store';
import { AiOutlineWarning } from 'react-icons/ai';
import {
  Button, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Flex, Heading, Text, Icon, useColorModeValue,
} from '@ramp/components';

import { useWindowWidth } from 'utils/hooks/useWindowWidth';


const MobileUnsupportedModal = () => {
  if (!isLoggedIn()) return null;

  const { t } = useTranslation();
  const initialRef = useRef(null);
  const windowWidth = useWindowWidth();
  const confirmedMobileUnsupported = useLayoutStore(
    (store) => store.confirmedMobileUnsupported,
  );
  const setConfirmedMobileUnsupported = useLayoutStore(
    (store) => store.setConfirmedMobileUnsupported,
  );

  if (windowWidth > 992 || confirmedMobileUnsupported) {
    return null;
  }

  return (
    <Modal
      isOpen={!confirmedMobileUnsupported}
      onClose={() => setConfirmedMobileUnsupported(true)}
      size="full"
      initialFocusRef={initialRef}
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue('whiteAlpha.900', 'blackAlpha.800')}>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            h="90vh"
            textAlign="center"
            direction="column"
            align="center"
            justify="center"
          >
            <Icon w={32} h={32} as={AiOutlineWarning} mb={6} />
            <Heading size={'md'} mb={2} textAlign="center">
              {t('components.mobileUnsupportedModal.title')}
            </Heading>
            <Text mb={8}>
              {t('components.mobileUnsupportedModal.text')}
            </Text>
            <Button
              variant="brand"
              ref={initialRef}
              onClick={() => setConfirmedMobileUnsupported(true)}
            >
              {t('components.mobileUnsupportedModal.btn')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MobileUnsupportedModal;
