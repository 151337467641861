import { AxiosError } from 'axios';
import {
  DeviceUserEvaluation, EmailBreachScan,
  ErrorFields,
  Issue,
  NetworkScanWithoutUsers, NotificationLog,
  RiskScore,
  Status, User,
  UserDataWithIssues,
  VerificationWithStatus,
  DevicesStats, NetworkScan, Nullable, IssueDetail,
  UserQuizAssignment,
} from 'types';

import axios, { handleError } from './_defaults';


const getUser = (user_id: string): Promise<UserDataWithIssues> => new Promise<UserDataWithIssues>((resolve, reject) => {
  axios.get<UserDataWithIssues>(`/users/${user_id}`)
    .then((res) => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getUsers = (): Promise<User[]> => new Promise<User[]>((resolve, reject) => {
  axios.get('/users')
    .then((res) => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

interface UpdateUserBody {
  name: string;
  position: Nullable<string>;
  country: string;
  language: string;
}
// eslint-disable-next-line max-len
const update = (user_id: string, data: UpdateUserBody): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.put(`/users/${user_id}`, data)
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const updateMe = (data: { country: string, language: string }): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.put('/users/me', data)
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const deleteUser = (user_id: string): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.delete(`/users/${user_id}`)
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const updateWithToken = (access_token: string, user_id: string, name?: string, position?: string, password?: string): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.patch(`/users/${user_id}`, { name, position, password }, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getActiveInvitation = (user_id: string): Promise<VerificationWithStatus> => new Promise<VerificationWithStatus>((resolve, reject) => {
  axios.get<VerificationWithStatus>(`/users/${user_id}/active-invitation`)
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const createInvitation = (user_id: string): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.get(`/users/${user_id}/create-invitation`)
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const degrade = (user_id: string): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.get(`/users/${user_id}/demote`)
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getDevices = (user_id: string): Promise<DeviceUserEvaluation[]> => new Promise<DeviceUserEvaluation[]>((resolve, reject) => {
  axios.get<DeviceUserEvaluation[]>(`/users/${user_id}/devices`)
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getIssues = (
  user_id: string,
  params?: {
    start_date?: string;
    end_date?: string;
  }
): Promise<Issue[]> => new Promise<Issue[]>((resolve, reject) => {
  axios.get<Issue[]>(`/users/${user_id}/issues`, { params })
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getIssueDetail = (
  user_id: string,
  issue_codename: string,
  params?: {
    start_date: string;
    end_date: string;
  }
): Promise<IssueDetail> =>
  new Promise<IssueDetail>((resolve, reject) => {
    axios.get<IssueDetail>(`/users/${user_id}/issues/${issue_codename}`, { params })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const promote = (user_id: string): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.get(`/users/${user_id}/promote`)
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const setPassword = (token: string, password: string): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.patch('/users/reset-password', { token, password })
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const resetPasswordById = (user_id: string): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.get(`/users/${user_id}/reset-password`)
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const changePassword = (user_id: string, old_password: string, password: string): Promise<null> =>
  new Promise<null>(((resolve, reject) => {
    axios.patch(`/users/${user_id}/change-password`, { old_password, password })
      .then(() => resolve(null))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  }));

// eslint-disable-next-line max-len
const changeStatus = (user_id: string, status: Status): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.patch(`/users/${user_id}/status`, { status })
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const resetPasswordByEmail = (email: string): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.get(`/users/${email}/reset-password`)
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const linkOtp = (user_id: string): Promise<string> => new Promise<string>((resolve, reject) => {
  axios.get(`/users/${user_id}/link-otp`)
    .then(res => resolve(res.data.otp_link))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const enableOtp = (user_id: string, otp_code: string): Promise<void> => new Promise<void>((resolve, reject) => {
  axios.post(`/users/${user_id}/enable-otp`, { otp_code })
    .then(() => resolve())
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const disableOtp = (user_id: string): Promise<null> => new Promise<null>((resolve, reject) => {
  axios.get(`/users/${user_id}/disable-otp`)
    .then(() => resolve(null))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getPrivacyBreaches = (user_id: string): Promise<EmailBreachScan> =>
  new Promise<EmailBreachScan>((resolve, reject) => {
    axios.get(`/users/${user_id}/privacy/emails`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getRiskScores = (user_id: string, start_date: string, end_date: string): Promise<RiskScore[]> =>
  new Promise<RiskScore[]>((resolve, reject) => {
    axios.get(`/users/${user_id}/risk-scores`, { params: { start_date, end_date } })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getNetworks = (user_id: string, start_date: string, end_date: string): Promise<NetworkScanWithoutUsers[]> =>
  new Promise<NetworkScanWithoutUsers[]>((resolve, reject) => {
    axios.get<NetworkScanWithoutUsers[]>(`/users/${user_id}/networks`, { params: { start_date, end_date } })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getNotificationLog = (user_id: string): Promise<NotificationLog[]> =>
  new Promise<NotificationLog[]>((resolve, reject) => {
    axios.get<NotificationLog[]>(`/users/${user_id}/notification-log`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const updateEmailStatus = (user_id: string, email_id: string, status: 'active' | 'resolved'): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios.patch(`/users/${user_id}/privacy/emails/${email_id}/breaches`, { status })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

// eslint-disable-next-line max-len
const updateBreachStatus = (
  user_id: string,
  email_id: string,
  breach_id: string,
  status: 'active' | 'resolved'
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios.patch(`/users/${user_id}/privacy/emails/${email_id}/breaches/${breach_id}`, { status })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getDevicesStats = (user_id: string): Promise<DevicesStats> => new Promise<DevicesStats>((resolve, reject) => {
  axios.get(`/users/${user_id}/stats/devices`)
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getNetworkDetail = (user_id: string, network_id: string, network_type: string): Promise<NetworkScan> =>
  new Promise<NetworkScan>((resolve, reject) => {
    axios.get(`/users/${user_id}/networks/${network_id}/${network_type}`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getAllQuizAssignments = (user_id: string): Promise<UserQuizAssignment[]> =>
  new Promise<UserQuizAssignment[]>((resolve, reject) => {
    axios.get<UserQuizAssignment[]>(`/users/${user_id}/education/assignments`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getQuizAssignment = (assignment_token: string): Promise<UserQuizAssignment> =>
  new Promise<UserQuizAssignment>((resolve, reject) => {
    axios.get<UserQuizAssignment>(`/users/education/assignments/${assignment_token}`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const recordUserQuizAnswer = (
  assignment_token: string,
  question_indx: number,
  choice_indx: number,
  spent_time_ms: number
): Promise<null> =>
  new Promise<null>((resolve, reject) => {
    axios.put(
      `/users/education/assignments/${assignment_token}/record-answer`,
      { question_indx, choice_indx, spent_time_ms }
    )
      .then(() => resolve(null))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

export default {
  getUser,
  getUsers,
  update,
  updateMe,
  deleteUser,
  updateWithToken,
  getActiveInvitation,
  createInvitation,
  degrade,
  getDevices,
  getIssues,
  getIssueDetail,
  promote,
  setPassword,
  changePassword,
  changeStatus,
  resetPasswordById,
  resetPasswordByEmail,
  linkOtp,
  enableOtp,
  disableOtp,
  getPrivacyBreaches,
  getRiskScores,
  getNetworks,
  getNotificationLog,
  updateBreachStatus,
  getDevicesStats,
  updateEmailStatus,
  getNetworkDetail,
  getAllQuizAssignments,
  getQuizAssignment,
  recordUserQuizAnswer,
};
