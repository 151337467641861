import { AxiosError, AxiosResponse } from 'axios';
import {
  EmailSendResponse, ErrorFields, ImportUserForExport, JWT, PartnerAdminImport, TokenSchema,
} from 'types';
import { getRefreshToken } from 'axios-jwt';

import axios, { handleError } from './_defaults';


const login = (email: string, password: string): Promise<TokenSchema> => new Promise((resolve, reject) => {
  axios.post<TokenSchema>('/auth/token', { email, password })
    .then(({ data }) => resolve(data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const refreshLogin = (): Promise<{ access: string }> => new Promise<{ access: string }>((resolve, reject) => {
  axios.post<{ access: string }>('/auth/token/refresh', { refresh: getRefreshToken() })
    .then(({ data }) => resolve(data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const registerEmails = (users_data: ImportUserForExport[], client_id?: string | null): Promise<EmailSendResponse> =>
  new Promise<EmailSendResponse>((resolve, reject) => {
    axios.post('/auth/register-emails', { client_id, users_data })
      .then((res: AxiosResponse<EmailSendResponse>) => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const verifyInvitation = (invitation_id: string, access_token?: string): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios.get(`auth/verification/${invitation_id}`, { headers: { Authorization: `Bearer ${access_token}` } })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const verifyOtp = (otp_token: string, otp_code: string): Promise<JWT> =>
  new Promise<JWT>(((resolve, reject) => {
    axios.post('/auth/verify-otp', { otp_token, otp_code })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  }));

const registerPartnerAdmins = (firm_id: string, admins: PartnerAdminImport[]): Promise<EmailSendResponse> =>
  new Promise<EmailSendResponse>((resolve, reject) => {
    axios.post(`/auth/firms/${firm_id}/users`, admins)
      .then((res) => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });


export default {
  login,
  refreshLogin,
  registerEmails,
  verifyInvitation,
  verifyOtp,
  registerPartnerAdmins,
};
