import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';
import { useQueryClient } from '@tanstack/react-query';
import { AlertOctagon, AlertTriangle, Archive, CheckCircle, HelpCircle } from 'lucide-react';

import api from 'api';
import { useAuthStore } from 'store';
import { notify } from 'utils/notifications';
import { APIError, EmailBreach } from 'types';
import BreachStatus from 'components/BreachStatus';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import { ColumnsType, HStack, Icon, Link, RTable, Tag, Text, Tooltip, Button } from '@ramp/components';


interface LeaksTableProps {
  id: string;
  emailId: string;
  usersIds: string[];
  breaches?: EmailBreach[];
  loading?: boolean;
  simple?: boolean;
  defaultPageSize?: number;
  userView?: boolean;
  hideUpdateStatus?: boolean;
}

interface EmailsBreachesRow extends EmailBreach {
  key: string;
  emailId: string;
  usersIds: string[];
}

const LeaksTable: React.FC<LeaksTableProps> = ({
  id,
  emailId,
  usersIds,
  breaches,
  loading,
  defaultPageSize = 5,
  userView = false,
  hideUpdateStatus = false,
}) => {
  if (!breaches) return null;

  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);
  const formattedEmailBreaches: EmailsBreachesRow[] = useMemo(() =>
    breaches.map(breach => ({ key: breach.name, emailId, usersIds, ...breach })),
  [breaches, emailId, usersIds]
  );

  const columns: ColumnsType<EmailsBreachesRow> = useMemo(() => {
    const cols: ColumnsType<EmailsBreachesRow> = [
      {
        title: t('components.table.emailBreaches.service'),
        key: 'title',
        sorting: true,
        sortingOptions: {
          dataType: 'string',
        },
        render: (_, { title, logo_path: logoPath }) => (
          <HStack spacing={2} pl={2}>
            <img src={logoPath} alt={title} width="18" height="18" />
            <Text>{title}</Text>
          </HStack>
        ),
      },
      {
        title: 'Status',
        key: 'status',
        sorting: true,
        align: 'center',
        sortingOptions: {
          multiSortOrder: 1,
          dataType: 'string',
          sortingFn: ({ original: a }, { original: b }) => {
            return a.status.localeCompare(b.status);
          }
        },
        render: (status) => <BreachStatus size="sm" status={status} />,
      },
      {
        title: t('components.table.emailBreaches.domain'),
        key: 'domain',
        align: 'left',
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          sortingFn: ({ original: a }, { original: b }) => {
            return a.domain.localeCompare(b.domain);
          }
        },
        render: (domain) => {
          if (domain)
            return (
              <Link href={`//${domain}`} isExternal>
                {domain} <Icon as={FiExternalLink} mx="2px" />
              </Link>
            );
          return '-';
        },
      },
      {
        title: t('components.table.emailBreaches.leakedData'),
        key: 'data_classes',
        render: (_, {
          top_secret_data_classes,
          secret_data_classes,
          classified_data_classes,
          confidential_data_classes,
          sensitive_data_classes,
          restricted_data_classes,
          protected_data_classes,
          not_categorized_data_classes,
        }) => {
          const allDataClasses: Record<string, string> = {};

          top_secret_data_classes.forEach((dataClass) => {
            allDataClasses[dataClass] = 'error.500';
          });

          secret_data_classes.forEach((dataClass) => {
            allDataClasses[dataClass] = 'error.500';
          });

          classified_data_classes.forEach((dataClass) => {
            allDataClasses[dataClass] = 'error.500';
          });

          confidential_data_classes.forEach((dataClass) => {
            allDataClasses[dataClass] = 'unknown.500';
          });

          sensitive_data_classes.forEach((dataClass) => {
            allDataClasses[dataClass] = 'unknown.500';
          });

          restricted_data_classes.forEach((dataClass) => {
            allDataClasses[dataClass] = 'unknown.500';
          });

          protected_data_classes.forEach((dataClass) => {
            allDataClasses[dataClass] = 'unknown.700';
          });

          not_categorized_data_classes.forEach((dataClass) => {
            allDataClasses[dataClass] = 'unknown.700';
          });

          return (
            <HStack spacing={1}>
              {Object.keys(allDataClasses).map((dataType, idx) => idx <= 4 && (
                <Tag
                  size="sm"
                  bg={allDataClasses[dataType]}
                  color="white"
                  style={{ opacity: '0.85', whiteSpace: 'nowrap' }}
                >
                  {allDataClasses[dataType] === 'error.500'
                    ? <AlertTriangle size={14} />
                    : allDataClasses[dataType] === 'unknown.500'
                      ? <AlertOctagon size={14} />
                      : <HelpCircle size={14} />
                  }
                  <span style={{ marginLeft: '4px' }}>{dataType}</span>
                </Tag>
              ))}
              {Object.keys(allDataClasses).length > 5 && (
                <Tooltip
                  bg={allDataClasses[Object.keys(allDataClasses)[5]]}
                  borderRadius="md"
                  color="white"
                  fontSize="12px"
                  label={Object.keys(allDataClasses).filter((d, idx) => idx >= 5).join(', ')}
                >
                  <Tag
                    size="sm"
                    color="white"
                    bg={allDataClasses[Object.keys(allDataClasses)[5]]}
                  >
                    +{Object.keys(allDataClasses).length - 5}
                  </Tag>
                </Tooltip>
              )}
            </HStack>
          );
        },
      },
      {
        title: t('components.table.emailBreaches.breachDate'),
        key: 'breach_date',
        align: 'left',
        sorting: true,
        sortingOptions: {
          multiSortOrder: 3,
          dataType: 'number',
          defaultSortOrder: 'desc',
        },
        render: (created) => dayjs(created).format('DD. MM. YYYY'),
      },
    ];

    if (!hideUpdateStatus) {
      cols.push({
        title: t('components.table.emailBreaches.changeStatus.title'),
        key: 'change_status',
        align: 'center',
        render: (_, { id: breachId, status: breachStatus, name: breachName }) => {
          const queryClient = useQueryClient();
          const { user_id: userId, entity_id: clientId } = useAuthStore((state) => state.user!);
          const [isLoading, setIsLoading] = useState(false);

          const updateBreachStatus = async (status: 'active' | 'resolved') => {
            setIsLoading(true);

            if (userView) {
              api.users.updateBreachStatus(userId, emailId, breachId, status)
                .then(async () => {
                  await queryClient.invalidateQueries(['user', userId, 'breaches']);

                  notify.success({
                    title: t('components.table.emailBreaches.breachDetail.successTitle', {
                      status: status === 'active'
                        ? t('components.table.emailBreaches.status.activatedSingle')
                        : t('components.table.emailBreaches.status.archivedSingle'),
                    }),
                    description: t('components.table.emailBreaches.breachDetail.successDescription', {
                      breach: breachName,
                      status: status === 'active'
                        ? t('components.table.emailBreaches.status.activatedSingle')
                        : t('components.table.emailBreaches.status.archivedSingle'),
                    }),
                  });

                  setIsLoading(false);
                })
                .catch((err: APIError) => {
                  notify.error({
                    title: t('components.table.emailBreaches.changeStatus.errorTitle', { code: err.code }),
                    description: err.description,
                  });

                  setIsLoading(false);
                });
            } else {
              api.clients.updateBreachStatus(clientId!, emailId, breachId, status)
                .then(async () => {
                  await queryClient.invalidateQueries(['user', userId, 'breaches']);

                  notify.success({
                    title: t('components.table.emailBreaches.breachDetail.successTitle', {
                      status: status === 'active'
                        ? t('components.table.emailBreaches.status.activatedSingle')
                        : t('components.table.emailBreaches.status.archivedSingle'),
                    }),
                    description: t('components.table.emailBreaches.breachDetail.successDescription', {
                      breach: breachName,
                      status: status === 'active'
                        ? t('components.table.emailBreaches.status.activatedSingle')
                        : t('components.table.emailBreaches.status.archivedSingle'),
                    }),
                  });

                  setIsLoading(false);
                })
                .catch((err: APIError) => {
                  notify.error({
                    title: t('components.table.emailBreaches.changeStatus.errorTitle', { code: err.code }),
                    description: err.description,
                  });

                  setIsLoading(false);
                });
            }
          };

          return (
            <Button
              size="sm"
              variant={breachStatus === 'active' ? 'brand' : 'solid'}
              isLoading={isLoading}
              onClick={() => breachStatus === 'active'
                ? updateBreachStatus('resolved')
                : updateBreachStatus('active')
              }
              leftIcon={breachStatus === 'active'
                ? <Archive size={16} style={{ position: 'relative', top: -2 }} />
                : <CheckCircle size={16} style={{ position: 'relative', top: -2 }} />
              }
            >
              {breachStatus === 'active'
                ? t('components.table.emailBreaches.button.archiveSingle')
                : t('components.table.emailBreaches.button.activeSingle')
              }
            </Button>
          );
        },
      });
    }

    return cols;
  }, []);

  return (
    <RTable
      columns={columns}
      data={formattedEmailBreaches}
      minWidth="1100px"
      loading={loading}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
    />
  );
};

export default LeaksTable;
