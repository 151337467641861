import { useTranslation } from 'react-i18next';

import api from 'api';
import { APIError, Nullable } from 'types';
import { notify } from 'utils/notifications';


export interface INotification {
  title: Nullable<string>,
  type?: string,
  message: Nullable<string>,
  is_silent?: boolean,
  additional_data?: any,
}

export const getRebootTitleAndDescription = (t: any, delay: number) => {
  switch (delay) {
    case 1:
      return {
        title: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.title'),
        message: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.description', {
          delay: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.none').toLowerCase(),
        }),
      };
    case 60:
      return {
        title: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.title'),
        message: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.description', {
          delay: t('admin.devices.detail.remoteControl.reboot.oneminute').toLowerCase(),
        }),
      };
    case 600:
      return {
        title: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.title'),
        message: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.description', {
          delay: t('admin.devices.detail.remoteControl.reboot.tenminutes').toLowerCase(),
        }),
      };
    case 1800:
      return {
        title: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.title'),
        message: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.description', {
          delay: t('admin.devices.detail.remoteControl.reboot.thirtyminutes').toLowerCase(),
        }),
      };
    case 6000:
      return {
        title: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.title'),
        message: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.description', {
          delay: t('admin.devices.detail.remoteControl.reboot.onehour').toLowerCase(),
        }),
      };
    default:
      return {
        title: t('admin.devices.detail.remoteControl.reboot.notificationSuccess.title'),
        message: '',
      };
  }
};

export const useNotificationSend = () => {
  const { t } = useTranslation();

  const DEFAULT_NOTIFICATIONS = {
    ASK_FOR_SCAN: {
      title: null,
      type: 'background-scan',
      message: null,
      is_silent: true,
    },
    UPDATE_RDS: {
      title: t('admin.devices.detail.updateRDSTitle'),
      message: t('admin.devices.detail.updateRDSMessage'),
    },
  };

  const send = (deviceId: string, notification: INotification): Promise<null> => {
    return api.devices.postNotificationToDevice(deviceId, notification);
  };

  const sendRemoteControlNotification = (
    deviceId: string,
    notification: INotification,
    otp_code?: string,
  ): Promise<null> => {
    return api.devices.postRemoteControlNotification(deviceId, notification, otp_code || null);
  };

  const massSend = (entityId: string, deviceIds: string[], notification: INotification): void => {
    api.clients.postMassNotification(entityId, deviceIds, notification)
      .then(() => {
        notify.success({
          title: t('admin.devices.detail.notificationSuccess.title'),
          description: t('admin.devices.detail.notificationSuccess.description'),
        });
      })
      .catch((err: APIError) => {
        notify.error({
          title: `[${err.code}] ${t('admin.devices.detail.notificationError.title')}`,
          description: err.description,
        });
      });
  };

  const win = {
    updateApps: (deviceId: string, appsIds: string[]) => {
      return sendRemoteControlNotification(deviceId, {
        title: t('admin.devices.detail.updateApplicationsTitle'),
        message: t('admin.devices.detail.updateApplicationsMessage'),
        type: 'win_cmd_update_applications',
        additional_data: {
          name: appsIds,
        },
      });
    },
    rebootWithDelay: (deviceId: string, delay: number, otp_code: string) => {
      const { title, message } = getRebootTitleAndDescription(t, delay);

      return sendRemoteControlNotification(deviceId, {
        title,
        message,
        type: 'win_cmd_reboot',
        additional_data: {
          delay,
        },
      }, otp_code);
    },
    networkDeviceScan: (deviceId: string, network?: string) => {
      return send(deviceId, {
        title: t('admin.devices.detail.remoteControl.networkDeviceScan.not.title'),
        message: t('admin.devices.detail.remoteControl.networkDeviceScan.not.description'),
        type: 'win_cmd_network_device_scan',
        additional_data: {
          network: network || null,
        }
      });
    }
  };

  return {
    win,
    send,
    massSend,
    DEFAULT_NOTIFICATIONS,
  };
};
