import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { getAccessToken, isLoggedIn } from 'axios-jwt';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '@ramp/components/dist/style.css';
import '@ramp/icons/dist/style.css';

import { extractUserFromToken, getEnvironment } from 'utils/utils';
import App from './App';
import { ToastContainer } from './utils/notifications';


const env = getEnvironment();

Sentry.init({
  dsn: 'https://7c72323345187232d4077ccde4650fab@sentry.redamp.io/4',
  
  environment: env,

  initialScope: {
    user: isLoggedIn() ? {
      id: extractUserFromToken(getAccessToken())?.user_id || undefined,
      email: extractUserFromToken(getAccessToken())?.email || undefined,
    } : undefined,
  },

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],

  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/sentry\.redamp\.io\/api/],
  
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in development
  // and then sample at a lower rate in production.
  replaysSessionSampleRate: env === 'local' ? 0.0 : 0.1, 

  // If you're not already sampling the entire session, change the sample rate to 100%
  // when sampling sessions where errors occur.
  replaysOnErrorSampleRate: env === 'local' ? 0.0 : 1.0,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <App />
    <ToastContainer />
  </>
);
