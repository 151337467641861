import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, HStack, RPage, RStep, RSteps,
  Text, useRSteps, VStack
} from '@ramp/components';

import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { parseRoute } from 'utils/utils';
import { PRODUCT_NAME } from 'utils/constants';
import AppDownloadBadge from 'components/AppDownloadBadge';


interface NoDataPageProps {
  heading: string;
  pageLoading: boolean;
  noUsersInCompany?: boolean;
  noScansInCompany?: boolean;
}

const NoDataPage: React.FC<NoDataPageProps> = ({ pageLoading, noUsersInCompany }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuthStore(store => store.user!);

  const { activeStep } = useRSteps((noUsersInCompany === undefined || noUsersInCompany) ? 0 : 1);

  return (
    <RPage
      px={6}
      pt={8}
      heading={t('admin.dashboard.noData.title')}
      title={`${PRODUCT_NAME} | ${t('admin.dashboard.noData.title')}`}
      loading={pageLoading}
    >
      <Text fontSize="xl" fontWeight="500" textAlign="left" mb={16}>
        {t('admin.dashboard.noData.description')}
      </Text>
      <VStack w="full" h="calc(100vh - 24rem)" align="center" justify="center">
        <RSteps activeStep={activeStep} orientation="vertical">
          <RStep
            label={t('admin.dashboard.noData.firstStep.title')}
            description={t('admin.dashboard.noData.firstStep.description')}
          >
            <VStack w="full" align="start" py={4}>
              <Button variant="brand" size="md" onClick={() => navigate(parseRoute(user, ROUTES.ADMIN.USERS.ADD))}>
                {t('admin.dashboard.noData.firstStep.button')}
              </Button>
            </VStack>
          </RStep>
          <RStep
            label={t('admin.dashboard.noData.secondStep.title')}
            description={t('admin.dashboard.noData.secondStep.description')}
          >
            <HStack spacing={4} py={4} alignItems="start">
              <AppDownloadBadge store="app-store" />
              <AppDownloadBadge store="google-play" />
              <AppDownloadBadge store="microsoft-store" architecture="64bit" />
              <AppDownloadBadge store="microsoft-store" architecture="32bit" />
            </HStack>
          </RStep>
          <RStep
            label={t('admin.dashboard.noData.thirdStep.title')}
            description={t('admin.dashboard.noData.thirdStep.description')}
          >
          </RStep>
        </RSteps>
      </VStack>
    </RPage>
  );
};

export default NoDataPage;
