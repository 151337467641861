import React from 'react';
import { Check, X } from 'lucide-react';
import { HStack, Text, useColorModeValue } from '@ramp/components';


interface PasswordCheckProps {
  name: string;
  checked: boolean;
}

const PasswordCheck: React.FC<PasswordCheckProps> = ({ name, checked }) => (
  <HStack
    py="2px"
    px="8px"
    w="fit-content"
    spacing="0.25rem"
    color={checked ? 'success.100' : 'gray.100'}
    fontWeight={400}
    borderRadius="6px"
    alignItems="center"
    justifyContent="center"
    bg={checked ? 'success.500' : useColorModeValue('white', 'gray.750')}
    transition="all 0.2s ease-in-out"
  >
    {checked
      ? <Check size={15} style={{ position: 'relative', top: '0px' }} />
      : <X size={15} style={{ position: 'relative', top: '0px' }} />
    }
    <Text fontSize="14px" whiteSpace="nowrap">{name}</Text>
  </HStack>
);

export default PasswordCheck;