import React, { useMemo } from 'react';
import { ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button, ColumnsType, HStack, Link, RTable, SeverityTag, Text, Tooltip, VStack } from '@ramp/components';

import { toCapitalize } from 'utils/utils';
import { DeviceRecommendation } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';


interface BasicIssuesTableProps {
  id: string,
  defaultPageSize?: number,
  issues?: DeviceRecommendation[],
}

interface DeviceIssueRow extends DeviceRecommendation {
  key: number,
}

const BasicIssuesTable: React.FC<BasicIssuesTableProps> = ({ id, defaultPageSize, issues = [] }) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize || 10);

  const columns: ColumnsType<DeviceIssueRow> = [
    {
      title: t('components.basicIssuesTable.severity'),
      key: 'severity',
      align: 'center',
      sorting: true,
      sortingOptions: {
        dataType: 'number',
        defaultSortOrder: 'desc',
        sortingFn: (a, b) => a.original.score - b.original.score,
      },
      render: severity => <SeverityTag size="md" severity={severity} />,
    },
    {
      title: t('components.basicIssuesTable.title'),
      key: 'title',
      width: '420px',
      render: title => <b>{title}</b>,
    },
    {
      title: t('components.basicIssuesTable.description'),
      key: 'recommendation_description',
      render: (value: string) => (
        <Tooltip title={value}>
          <Text noOfLines={1}>{value}</Text>
        </Tooltip>
      ),
    },
    {
      title: t('admin.dashboard.issues.table.actions'),
      key: 'actions',
      width: '120px',
      align: 'center',
      sorting: false,
      render: (_, issue) => (
        <HStack spacing={2}>
          <Button
            variant="outline"
            size="sm"
            rightIcon={<ChevronRight size={14} />}
            onClick={() => window.open(issue.knowledge_base_url, '_blank')}
          >
            {t('admin.dashboard.issues.table.moreInfo')}
          </Button>
          <Tooltip label={t('admin.dashboard.issues.table.disabled')} placement="top">
            <Button variant="brand" size="sm" isDisabled>
              {t('admin.dashboard.issues.table.ignore')}
            </Button>
          </Tooltip>
        </HStack>
      )
    },
  ];

  const formattedIssues: DeviceIssueRow[] = useMemo(() => {
    return issues.map((issue, key) => ({ key, ...issue }));
  }, [issues]);

  return (
    <RTable
      columns={columns}
      data={formattedIssues}
      expandable={{
        rowExpandable: () => true,
        render: recomm => (
          <HStack w="full" py={4} align="end" spacing={8}>
            <VStack w="50%" align="start">
              <HStack align="end" spacing={4}>
                <Text fontSize="1.1rem" fontWeight={600}>{t('components.basicIssuesTable.title')}</Text>
                <Text fontSize=".95rem" color="gray.200" fontWeight={300}>{recomm.title}</Text>
              </HStack>
              <HStack align="end" spacing={4}>
                <Text fontSize="1.1rem" fontWeight={600}>{t('components.basicIssuesTable.category')}</Text>
                <Text fontSize=".95rem" color="gray.200" fontWeight={300}>{toCapitalize(recomm.issue_category)}</Text>
              </HStack>
              <VStack align="start" mt="1.5rem !important" spacing={1}>
                <Text fontSize="1.1rem" fontWeight={600}>{t('components.basicIssuesTable.detailedDescription')}</Text>
                <Text fontSize=".95rem" color="gray.200" fontWeight={300}>
                  {recomm.issue_description}&nbsp;
                  <Link
                    color="brand.500"
                    textDecoration="underline"
                    href={recomm.knowledge_base_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('admin.dashboard.issues.table.moreInfo')}
                  </Link>
                </Text>
              </VStack>
            </VStack>
            <VStack w="50%" align="start" justify="end" spacing={1}>
              <Text fontSize="1.1rem" fontWeight={600}>{t('components.basicIssuesTable.suggestedSolution')}</Text>
              <Text fontSize=".95rem" color="gray.200" fontWeight={300}>
                {recomm.recommendation_description}&nbsp;
                <Link
                  color="brand.500"
                  textDecoration="underline"
                  href={recomm.knowledge_base_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('admin.dashboard.issues.table.moreInfo')}
                </Link>
              </Text>
            </VStack>
          </HStack>
        ),
      }}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
    />
  );
};

export default BasicIssuesTable;
