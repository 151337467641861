import React from 'react';
import { HStack, Text, useColorModeValue, VStack } from '@ramp/components';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoteControlCardProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  rightContent?: React.ReactNode;
}

const RemoteControlCard: React.FC<RemoteControlCardProps> = ({ title, description, rightContent }) => {
  return (
    <HStack
      w="full"
      p={4}
      borderRadius="lg"
      alignItems="center"
      justifyContent="space-between"
      bg={useColorModeValue('white', 'gray.750')}
    >
      <VStack alignItems="start" spacing={0}>
        <Text fontSize="lg" fontWeight={500}>{title}</Text>
        <Text fontSize="sm" fontWeight={400}>{description}</Text>
      </VStack>
      {rightContent}
    </HStack>
  );
};

export default RemoteControlCard;