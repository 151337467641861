import React from 'react';
import byteSize from 'byte-size';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, GridItem, HStack, PropertyCard, Text, VStack } from '@ramp/components';

import  { DeviceRecommendation, Nullable } from 'types';
import WindowsUserTypeTag from 'components/WindowsUserTypeTag';
import { getSeverityFromScore, getSeverityTextColor } from 'utils/utils';
import { WindowsOs, WindowsScan, WindowsSettings } from 'types/api/devices';
import { useDeviceDetail } from 'pages/admin/devices/detail/DeviceDetailProvider';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WindowsOSSettingsProps {}

const WindowsOSSettings: React.FC<WindowsOSSettingsProps> = () => {
  const { t } = useTranslation();
  const { platform, scan, recommendations } = useDeviceDetail();

  if (!platform || !scan || !scan.os || !recommendations) return null;

  const { device, device_settings: deviceSettings, global_score: globalScore, os } = scan;

  if (!device || !deviceSettings || !globalScore) return null;

  const osSettingsScore = globalScore.os_settings_score || 0;
  const osSettingsSeverity = getSeverityFromScore(osSettingsScore);

  const settingsRecommendations: Record<string, Nullable<DeviceRecommendation>> = {
    driver_updates: recommendations.find((rec) => rec.group_codename === 'driver_update') || null,
    disk_encryption: recommendations.find((rec) => rec.group_codename === 'disk_encryption') || null,
    uptime: recommendations.find((rec) => rec.group_codename === 'uptime') || null,
    storage: recommendations.find((rec) => rec.group_codename === 'storages') || null,
  };

  const availableDriverUpdates = (scan.os as WindowsOs).pending_updates.filter((update) => update.type === 'utDriver');
  const antiviruses = (deviceSettings as WindowsSettings).antivirus.installed_antivirus;
  const localUsers = (os as WindowsOs).local_users;

  return (
    <VStack w={{ sm: 'full', lg: '50%' }} mt={{ sm: '2rem', lg: 0 }} ml={{ lg: '1rem', xl: '2rem' }}>
      <HStack w="full" alignItems="center" justifyContent="space-between">
        <Text fontSize={{ sm: '24px', md: '28px', lg: '24px', xl: '28px' }} fontWeight={700}>
          {t('admin.devices.detail.device.windowsOsSettings').toUpperCase()}
        </Text>
        <HStack spacing={0} alignItems="end">
          <Text
            fontWeight={700}
            color={getSeverityTextColor(osSettingsSeverity)}
            fontSize={{ sm: '24px', md: '28px', lg: '24px', xl: '28px' }}
          >
            {osSettingsScore.toFixed(1)}
          </Text>
          <Text fontSize={{ sm: '18px', md: '20px', lg: '18px', xl: '20px' }} fontWeight={700}>/10</Text>
        </HStack>
      </HStack>
      <VStack w="full" spacing={4}>
        <PropertyCard
          w="full"
          status="info"
          title={settingsRecommendations.driver_updates
            ? settingsRecommendations.driver_updates.group
            : t('admin.devices.detail.device.driverUpdates')
          }
          description={settingsRecommendations.driver_updates
            ? settingsRecommendations.driver_updates.recommendation_description
            : <i>{t('admin.devices.detail.device.noRecommendation')}</i>
          }
          openable
        >
          <Grid templateColumns="6fr 1fr" gap={4}>
            <GridItem w="full">
              <Text fontSize="16px" fontWeight={500}>{t('admin.devices.detail.device.titleAndDescription')}</Text>
            </GridItem>
            <GridItem w="full">
              <Text fontSize="16px" fontWeight={500}>{t('admin.devices.detail.device.priority')}</Text>
            </GridItem>
            {availableDriverUpdates.map((update) => (
              <>
                <GridItem w="full">
                  <VStack alignItems="start" spacing={0}>
                    <Text fontWeight={500}>{update.title}</Text>
                    <Text color="gray.200">{update.description}</Text>
                  </VStack>
                </GridItem>
                <GridItem w="full">
                  <Flex w="full" h="full" justifyContent="start" alignItems="center">
                    {update.download_priority.replace('dp', '')}
                  </Flex>
                </GridItem>
              </>
            ))}
          </Grid>
        </PropertyCard>
        <PropertyCard
          w="full"
          status={settingsRecommendations.disk_encryption
            ? settingsRecommendations.disk_encryption.severity
            : t('admin.devices.detail.device.unknown')
          }
          title={settingsRecommendations.disk_encryption
            ? settingsRecommendations.disk_encryption.group
            : t('admin.devices.detail.device.diskEncryption')
          }
          description={settingsRecommendations.disk_encryption
            ? settingsRecommendations.disk_encryption.recommendation_description
            : <i>{t('admin.devices.detail.device.noRecommendation')}</i>
          }
        />
        <PropertyCard
          w="full"
          status={antiviruses.length > 0 ? 'safe' : 'risk'}
          title={t('admin.devices.detail.device.antiviruses')}
          description={`${t('admin.devices.detail.device.antivirusesDescription')} ${antiviruses.length}`}
          openable
        >
          {antiviruses.map((antivirus, idx) => (
            <span>{antivirus.display_name}{(idx + 1) !== antiviruses.length ? ', ' : ''}</span>
          ))}
        </PropertyCard>
        <PropertyCard
          w="full"
          status={settingsRecommendations.storage?.severity || 'unknown'}
          title={settingsRecommendations.storage
            ? settingsRecommendations.storage.group
            : t('admin.devices.detail.device.storages')
          }
          description={settingsRecommendations.storage
            ? settingsRecommendations.storage.recommendation_description
            : <i>{t('admin.devices.detail.device.noRecommendation')}</i>
          }
          openable
        >
          <HStack w="full" spacing={1}>
            <Text>{t('admin.devices.detail.storage')}:</Text>
            {(scan as WindowsScan).extended_device_info!.storage.map((s, i) => (
              <Text>
                {(byteSize(s.actual_usage || 0).toString())} / {(byteSize(s.total_storage || 0).toString())}
                {(i + 1) !== (scan as WindowsScan).extended_device_info!.storage.length && '; '}
              </Text>
            ))}
          </HStack>
        </PropertyCard>
        <PropertyCard
          w="full"
          status="info"
          title={t('admin.devices.detail.device.localUsers.title')}
          description={t('admin.devices.detail.device.localUsers.description')}
          openable
        >
          <Grid templateColumns="6fr 1fr" gap={4}>
            <GridItem w="full">
              <Text fontSize="16px" fontWeight={500}>
                {t('admin.devices.detail.device.localUsers.username')}
              </Text>
            </GridItem>
            <GridItem w="full" display="flex" alignItems="center" justifyContent="end">
              <Text fontSize="16px" fontWeight={500}>
                {t('admin.devices.detail.device.localUsers.permission')}
              </Text>
            </GridItem>
            {localUsers.sort((a, b) => a.type.localeCompare(b.type)).map(user => (
              <>
                <GridItem w="full">
                  <VStack alignItems="start" spacing={0}>
                    <Text fontWeight={400}>{user.username}</Text>
                  </VStack>
                </GridItem>
                <GridItem w="full">
                  <Flex w="full" h="full" justifyContent="end" alignItems="center">
                    <WindowsUserTypeTag type={user.type} />
                  </Flex>
                </GridItem>
              </>
            ))}
          </Grid>
        </PropertyCard>
      </VStack>
    </VStack>
  );
};

export default WindowsOSSettings;
