import React, { useState } from 'react';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Button, Heading, HStack, RCard, Search, useColorModeValue, VStack } from '@ramp/components';

import { RefreshCcw } from 'lucide-react';
import { APIError, Platforms } from 'types';
import { useParams } from 'react-router-dom';
import { WindowsApp } from 'types/api/devices';
import WindowsAppsTable from 'components/tables/WindowsAppsTable';
import { notify, useNotificationSend } from 'utils/notifications';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UnupdatedAppsProps {
  unupdated_apps: WindowsApp[];
}

const UnupdatedApps: React.FC<UnupdatedAppsProps> = ({ unupdated_apps }) => {
  const { t } = useTranslation();
  const notification = useNotificationSend();
  const { id: deviceId } = useParams<{ entityId: string, platform: Platforms, id: string }>();

  const [searchInApps, setSearchInApps] = useState<string>('');
  const [notificationLoading, setNotificationLoading] = useState<boolean>(false);

  const [appsForUpdate, setAppsForUpdate] = useState<string[]>([]);

  const onAppsUpdateRequest = () => {
    setNotificationLoading(true);

    notification.win.updateApps(deviceId!, unupdated_apps.map(app => app.guid!))
      .then(() => {
        setNotificationLoading(false);

        notify.success({
          title: t('admin.devices.detail.appsUpdate.notificationSuccess.title'),
          description: t('admin.devices.detail.appsUpdate.notificationSuccess.description'),
        });
      })
      .catch((err: APIError) => {
        setNotificationLoading(false);

        notify.error({
          title: t('admin.devices.detail.appsUpdate.notificationFail.title'),
          description: err.description,
        });
      });
  };

  return (
    <VStack w="full" spacing={3}>
      <HStack w="full" justify="space-between" align="end">
        <Element name="unupdated_apps">
          <Heading
            mt="2rem !important"
            mb="0.25rem !important"
            color={useColorModeValue('black', 'gray.400')}
            fontSize="1.5rem"
            fontWeight={600}
          >
            {t('admin.devices.detail.apps.unupdatedApps')}
          </Heading>
        </Element>
        <HStack spacing={4}>
          {unupdated_apps.length > 0 && (
            <Button
              variant="brand"
              onClick={onAppsUpdateRequest}
              leftIcon={<RefreshCcw size={12} />}
              isLoading={notificationLoading}
              isDisabled={appsForUpdate.length === 0}
            >
              {t('admin.devices.detail.apps.risky.updateButton')}
            </Button>
          )}
          <Search
            w="360px"
            size="sm"
            value={searchInApps}
            // eslint-disable-next-line max-len
            placeholder={`${t('admin.devices.detail.apps.risky.searchIn')} ${t('admin.devices.detail.apps.apps')}`}
            onChange={e => setSearchInApps(e.target.value)}
          />
        </HStack>
      </HStack>

      <RCard w="full">
        <VStack w="full" spacing={4}>
          <WindowsAppsTable
            id="windows_apps"
            apps={unupdated_apps || []}
            searchFor={searchInApps}
            unupdated
            selectable
            onSelectionChange={selectedRows => setAppsForUpdate(selectedRows.map(row => row.guid!))}
          />
        </VStack>
      </RCard>
    </VStack>
  );
};

export default UnupdatedApps;