import React, { useMemo } from 'react';
import { Permission } from 'types/api/devices';
import { useTranslation } from 'react-i18next';
import { ColumnsType, RTable } from '@ramp/components';

import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import PermissionStatus from 'components/PermissionStatus';
import useTablePageSize from 'utils/hooks/useTablePageSize';


interface PermissionsTableProps {
  id: string;
  loading?: boolean;
  permissions?: Permission[];
}

interface PermissionRow extends Permission {
  key: number;
}

const PermissionsTable: React.FC<PermissionsTableProps> = ({ id, loading, permissions }) => {
  if (!permissions) return null;

  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, 10);

  const columns: ColumnsType<PermissionRow> = useMemo(() => ([
    {
      title: t('components.table.permissions.name'),
      key: 'name',
      align: 'left',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
      },
    },
    {
      title: t('components.table.permissions.exploitability'),
      key: 'status',
      align: 'left',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        defaultSortOrder: 'asc',
        sortingFn: ({ original: a }, { original: b }) => {
          return a.status.localeCompare(b.status);
        }
      },
      render: status => <PermissionStatus status={status} />,
    },
    {
      title: t('components.table.permissions.description'),
      key: 'description',
      align: 'left',
    }
  ]), []);

  const formattedPermissions = useMemo(() => {
    return permissions.map((tracker, key) => ({ key, ...tracker }));
  }, [permissions]);

  return (
    <RTable
      columns={columns}
      loading={loading}
      data={formattedPermissions}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      interleaveRows
    />
  );
};

export default PermissionsTable;
