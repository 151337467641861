import React, { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { User } from 'types';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { parseRoute } from 'utils/utils';

import withAdminLayout from './withAdminLayout';
import { renderUserRoutes } from '../user/UserRoutes';


interface AdminRoutesProps {
  removeNotFoundRoute?: boolean,
}

const EventLog = lazy(() => import('pages/admin/event_log'));
const Dashboard = lazy(() => import('pages/admin/dashboard'));
const UsersList = lazy(() => import('pages/admin/users/list'));
const Compliance = lazy(() => import('pages/admin/compliance'));
const IssuesList = lazy(() => import('pages/admin/issues/list'));
const UserDetail = lazy(() => import('pages/admin/users/detail'));
const UsersImport = lazy(() => import('pages/admin/users/import'));
const DevicesList = lazy(() => import('pages/admin/devices/list'));
const NetworksList = lazy(() => import('pages/admin/networks/list'));
const DeviceDetail = lazy(() => import('pages/admin/devices/detail'));
const NetworkDetail = lazy(() => import('pages/admin/networks/detail'));
const GlobalSettings = lazy(() => import('pages/admin/global_settings'));
const AccountSettings = lazy(() => import('pages/admin/account_settings'));
const TestsList = lazy(() => import('pages/admin/education/quizzes/list'));
const CreateTest = lazy(() => import('pages/admin/education/quizzes/create_test'));
const NotificationsNew = lazy(() => import('pages/admin/notifications/new'));
const NotificationsList = lazy(() => import('pages/admin/notifications/list'));
const PrivacyProtection = lazy(() => import('pages/admin/privacy_protection/list'));
const Recommendations = lazy(() => import('pages/admin/education/recommendations'));
const LocalThreats = lazy(() => import('pages/admin/education/local_threats'));
const FirmVerification = lazy(() => import('pages/base/FirmVerification'));

export const renderAdminRoutes = (user: User, removeNotFoundRoute: boolean) => (
  <React.Fragment>
    <Route path={ROUTES.ADMIN.DASHBOARD} element={withAdminLayout(Dashboard)} />
    <Route path={ROUTES.ADMIN.DEVICES.LIST} element={withAdminLayout(DevicesList)} />
    <Route path={ROUTES.ADMIN.DEVICES.DETAIL} element={withAdminLayout(DeviceDetail)} />
    <Route path={ROUTES.ADMIN.ISSUES.LIST} element={withAdminLayout(IssuesList)} />
    <Route path={ROUTES.ADMIN.NOTIFICATIONS.LIST} element={withAdminLayout(NotificationsList)} />
    <Route path={ROUTES.ADMIN.NOTIFICATIONS.ADD} element={withAdminLayout(NotificationsNew)} />
    <Route path={ROUTES.ADMIN.NETWORKS.LIST} element={withAdminLayout(NetworksList)} />
    <Route path={ROUTES.ADMIN.NETWORKS.DETAIL} element={withAdminLayout(NetworkDetail)} />
    <Route path={ROUTES.ADMIN.USERS.LIST} element={withAdminLayout(UsersList)} />
    <Route path={ROUTES.ADMIN.USERS.DETAIL} element={withAdminLayout(UserDetail)} />
    <Route path={ROUTES.ADMIN.USERS.ADD} element={withAdminLayout(UsersImport)} />
    <Route path={ROUTES.ADMIN.PRIVACY_PROTECTION} element={withAdminLayout(PrivacyProtection)} />
    <Route path={ROUTES.ADMIN.ACCOUNT_SETTINGS} element={withAdminLayout(AccountSettings)} />
    <Route path={ROUTES.ADMIN.GLOBAL_SETTINGS} element={withAdminLayout(GlobalSettings)} />
    <Route path={ROUTES.ADMIN.EDUCATION.TESTS} element={withAdminLayout(TestsList)} />
    <Route path={ROUTES.ADMIN.EDUCATION.CREATE_TEST} element={withAdminLayout(CreateTest)} />
    <Route path={ROUTES.ADMIN.EDUCATION.RECOMMENDATIONS} element={withAdminLayout(Recommendations)} />
    <Route path={ROUTES.ADMIN.EDUCATION.LOCAL_THREATS} element={withAdminLayout(LocalThreats)} />
    <Route path={ROUTES.ADMIN.COMPLIANCE} element={withAdminLayout(Compliance)} />
    <Route path={ROUTES.ADMIN.EVENT_LOG} element={withAdminLayout(EventLog)} />
    {renderUserRoutes(user, true)}

    <Route path={ROUTES.BASE.FIRM_VERIFICATION} element={<FirmVerification />} />

    {!removeNotFoundRoute && (
      <Route
        path="*"
        element={<Navigate to={parseRoute(user!, ROUTES.ADMIN.DASHBOARD)}/>}
      />
    )}
  </React.Fragment>
);

const AdminRoutes: React.FC<AdminRoutesProps> = ({ removeNotFoundRoute = false }) => {
  const user = useAuthStore(store => store.user!);

  return (
    <React.Suspense>
      <Routes>
        {renderAdminRoutes(user, removeNotFoundRoute)}
      </Routes>
    </React.Suspense>
  );
};

export default AdminRoutes;
