import React from 'react';
import { Tag } from '@ramp/components';
import { useTranslation } from 'react-i18next';
import { ShieldCheck, User } from 'lucide-react';


interface WindowsUserTypeTagProps {
  type: 'standard' | 'administrator';
}

const WindowsUserTypeTag: React.FC<WindowsUserTypeTagProps> = ({ type }) => {
  const { t } = useTranslation();

  if (!type) return <Tag colorScheme="error">{t('components.networkType.unknown')}</Tag>;

  if (type === 'standard') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap">
        <User size="16px" />
        &nbsp;
        {t('components.issueObjectType.windowsUserTypeTag.standard')}
      </Tag>
    );
  }

  if (type === 'administrator') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap">
        <ShieldCheck size="16px" />
        &nbsp;
        {t('components.issueObjectType.windowsUserTypeTag.administrator')}
      </Tag>
    );
  }

  return <Tag colorScheme="error">{t('components.networkType.unknown')}</Tag>;
};

export default WindowsUserTypeTag;
