import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import AdminRoutes from 'router/admin/AdminRoutes';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AdminRouterProps {}

const AdminRouter: React.FC<AdminRouterProps> = () => (
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <AdminRoutes />
    </QueryParamProvider>
  </BrowserRouter>
);

export default AdminRouter;
