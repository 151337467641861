import auth from './auth';
import mars from './mars';
import firms from './firms';
import users from './users';
import clients from './clients';
import devices from './devices';
import networks from './networks';
import recommendations from './recommendations';


export default {
  auth,
  mars,
  firms,
  users,
  clients,
  devices,
  networks,
  recommendations,
};
