import React from 'react';
import { Box, StyleProps } from '@ramp/components';

import { NotificationSeverity, Nullable } from 'types';


interface NotificationSeverityStripProps extends StyleProps {
  severity?: Nullable<NotificationSeverity>;
}

const NotificationSeverityStrip: React.FC<NotificationSeverityStripProps> = ({ severity, ...props }) => {
  switch (severity) {
    case 'unknown':
      return <Box as="div" w="2px" h="24px" borderRadius="md" bg="unknown.500" {...props} />;
    case 'safe':
      return <Box as="div" w="2px" h="24px" borderRadius="md" bg="success.500" {...props} />;
    case 'warning':
      return <Box as="div" w="2px" h="24px" borderRadius="md" bg="warning.500" {...props} />;
    case 'risk':
      return <Box as="div" w="2px" h="24px" borderRadius="md" bg="error.500" {...props} />;
    default:
      return <Box as="div" w="2px" h="24px" borderRadius="md" bg="unknown.500" {...props} />;
  }
};

export default NotificationSeverityStrip;
