import React from 'react';
import { Flex } from '@ramp/components';

import OsSettings from './OsSettings';
import MobilePlatform from './MobilePlatform';
import WindowsOSSettings from './WindowsOSSettings';
import { useDeviceDetail } from '../../DeviceDetailProvider';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeviceTabProps {}

const DeviceTab: React.FC<DeviceTabProps> = () => {
  const { platform } = useDeviceDetail();

  return (
    <Flex w="full" flexDirection={{ sm: 'column', lg: 'row' }}>
      <MobilePlatform />
      {platform !== 'windows'
        ? <OsSettings />
        : <WindowsOSSettings />
      }
    </Flex>
  );
};

export default DeviceTab;
