import React, { useMemo } from 'react';
import { ChevronDown } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Description,
  DescriptionItem,
  HStack,
  VStack,
  Tag,
  Text,
  Button,
  useColorModeValue,
  Tooltip
} from '@ramp/components';

import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import UserTooltip from 'components/UserTooltip';
import SensorAppVersion from 'components/SensorAppVersion';
import { AndroidScan, WindowsScan } from 'types/api/devices';
import { serializePlatform, serializeVendor } from 'utils/serializators';
import { parseRoute, toCapitalize, usePlatformIcon, convertSecondsToTime } from 'utils/utils';

import ScanInvokedTag from 'components/ScanInvokedTag';
import WindowsExtendedInfo from './WindowsExtendedInfo';
import { useDeviceDetail } from '../../../DeviceDetailProvider';


interface WindowsDeviceDescriptionProps {
  scan: WindowsScan,
  editDeviceName: string,
  selectedDate: string,
}

const WindowsDeviceDescription: React.FC<WindowsDeviceDescriptionProps> = ({ scan }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);
  const { recommendations } = useDeviceDetail();

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const uptimeRecommendation = useMemo(() =>
    recommendations!.find((rec) => rec.group_codename === 'uptime'),
  [recommendations]);

  return (
    <VStack w="full" spacing={0} pos="relative">
      <Description
        size="md"
        columns={{ sm: 1, md: 1, lg: 4, xl: 5, '2xl': 5 }}
      >
        <DescriptionItem label={t('admin.devices.detail.platform')}>
          <HStack spacing={2} alignItems="center">
            <span>{usePlatformIcon('windows', { size: 20 })}</span>
            <span>{serializePlatform(scan.device ? scan.device.platform : '')}</span>
          </HStack>
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.edition')}>
          {scan.os
            ? scan.os.name || (
                <Text fontWeight={500} color="brand.500">
                  {t('admin.devices.detail.platformVersionUnknown')}
                </Text>
            )
            : <Text fontWeight={500} color="brand.500">{t('admin.devices.detail.platformVersionUnknown')}</Text>
          }
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.platformVersion', { platform: 'Windows' })}>
          {scan.os && scan.os.supported
            ? (
              <Tag colorScheme="success">
                {scan.os ? scan.os.version : t('admin.notifications.filter.severity.unknown')}
              </Tag>
            )
            : (
              <Tag colorScheme="error">
                {scan.os ? scan.os.version : t('admin.notifications.filter.severity.unknown')}
              </Tag>
            )
          }
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.device.installedPatch')}>
          {scan.os && scan.os.patch_number >= scan.os.latest_available_patch_number
            ? (
              <Tag colorScheme="success">
                {scan.os
                  ? `${scan.os.patch} (${scan.os.patch_number})`
                  : t('admin.notifications.filter.severity.unknown')
                }
              </Tag>
            )
            : (
              <Tooltip
                label={`${t('admin.devices.detail.device.latestPatch')}:
                ${(scan.os && scan.os.latest_available_patch_number)
                  ? `${scan.os.latest_available_patch} (${scan.os.latest_available_patch_number})`
                  : t('admin.notifications.filter.severity.unknown')
                }`}
                placement="top"
              >
                <Tag colorScheme="error">
                  {scan.os
                    ? `${scan.os.patch} (${scan.os.patch_number})`
                    : t('admin.notifications.filter.severity.unknown')
                  }
                </Tag>
              </Tooltip>
            )
          }
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.uptime')}>
          <Text
            color={['risk', 'warning'].includes(uptimeRecommendation!.severity) ? 'error.500' : 'success.500'}
          >
            {scan.extended_device_info && scan.extended_device_info.uptime
              ? convertSecondsToTime(scan.extended_device_info.uptime)
              : t('admin.devices.detail.device.unknown')
            }
          </Text>
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.vendor')}>
          {serializeVendor(scan.device ? scan.device.manufacturer : '')}
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.model')}>
          {scan.device ? (scan.device.marketing_name || scan.device.model || scan.device.device) : ''}
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.sensorVersion')}>
          <SensorAppVersion version={scan.app_version} />
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.scanInvoked')}>
          <ScanInvokedTag type={scan.invoked_by} />
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.user')}>
          {scan.user
            ? (
              <HStack
                cursor="pointer"
                onClick={() => navigate(
                  parseRoute(
                    user,
                    ROUTES.ADMIN.USERS.DETAIL,
                    { id: scan.user!.id! },
                    { 'users_devices.status': 'active' }
                  )
                )}
              >
                <UserTooltip userId={scan.user.id!} userName={scan.user.name!} />
              </HStack>
            )
            : <i>{toCapitalize(t('admin.devices.detail.device.unknown'))}</i>
          }
        </DescriptionItem>
      </Description>

      {(scan).extended_device_info && (<div style={{ height: '1rem' }} />)}

      {/* Extended Device Info */}
      {(scan as AndroidScan).extended_device_info && (
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.div
              style={{ width: '100%' }}
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: 1,
                height: 'auto',
                transition: {
                  height: {
                    duration: 0.2
                  },
                  opacity: {
                    delay: 0.15,
                    duration: 0.1
                  }
                }
              }}
              exit={{
                opacity: 0,
                height: 0,
                transition: {
                  height: {
                    duration: 0.15,
                    delay: 0.1
                  },
                  opacity: {
                    duration: 0.2
                  }
                }
              }}
            >
              <WindowsExtendedInfo info={scan.extended_device_info!} />
              <div style={{ height: '1rem' }} />
            </motion.div>
          )}
        </AnimatePresence>
      )}

      {(scan).extended_device_info && (
        <Button
          size="sm"
          variant="solid"
          pos="absolute"
          bottom="-2rem"
          leftIcon={(
            <ChevronDown
              size={16}
              style={{
                transition: 'all .25s ease-in-out',
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          )}
          bg={useColorModeValue('white', 'gray.750')}
          _hover={{ bg: useColorModeValue('gray.10', 'gray.700') }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? t('admin.devices.detail.hide') : t('admin.devices.detail.show')}&nbsp;
          {t('admin.devices.detail.extendedInfo')}
        </Button>
      )}
    </VStack>
  );
};

export default WindowsDeviceDescription;
