import dayjs from 'dayjs';
import React from 'react';
import { ArrowRight } from 'lucide-react';
import {
  Heading,
  HStack,
  Tag,
  VStack,
  Text,
  Button,
  Box,
} from '@ramp/components';
import { useTranslation } from 'react-i18next';

import { UserQuizAssignment } from 'types';


export interface StartPageProps {
  assignment: UserQuizAssignment;
  setStartQuiz: React.Dispatch<React.SetStateAction<boolean>>;
}

const StartPage: React.FC<StartPageProps> = ({ assignment, setStartQuiz }) => {
  const { t } = useTranslation();

  return (
    <VStack
      p={['16px', '32px']}
      w={['full']}
      h={['full']}
      pos="relative"
      zIndex={10}
      alignItems="start"
      borderRadius="16px"
      spacing="12px"
      // bg={useColorModeValue("white", "gray.800")}
    >
      <HStack spacing="6px">
        {assignment.quiz.keywords.map((keyword) => (
          <Tag key={keyword} size="sm">
            <Box pos="relative" top="1.5px">
              {keyword.toUpperCase()}
            </Box>
          </Tag>
        ))}
      </HStack>
      <VStack spacing="16px" alignItems="start">
        <Heading size="2xl">{assignment.quiz.title}</Heading>
        <Text fontSize="md" color="gray.500">
          {assignment.quiz.description}
        </Text>
      </VStack>
      <VStack
        w="full"
        mt="auto"
        spacing="12px"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="sm" color="gray.500">
          {t('base.test.testLengthText', { minutes: assignment.quiz.questions.length })}
        </Text>
        <Button
          size="lg"
          w="full"
          variant="brand"
          rightIcon={<ArrowRight size={20} />}
          onClick={() => {
            if (assignment.status === 'assigned') {
              setStartQuiz(true);
            }

            if (assignment.status === 'in_progress') {
              setStartQuiz(true);
            }
          }}
          isDisabled={assignment.status === 'completed'}
        >
          {assignment.status === 'completed'
            ? `${t('base.test.completedText')} ${dayjs(assignment.completion_date).format(
              'DD.MM.YYYY'
            )}`
            : assignment.status === 'assigned'
              ? t('base.test.testStartText')
              : t('base.test.testContinueText')
          }
        </Button>
      </VStack>
    </VStack>
  );
};

export default StartPage;
