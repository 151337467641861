import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import UserRoutes from 'router/user/UserRoutes';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserRouterProps {}

const UserRouter: React.FC<UserRouterProps> = () => (
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <UserRoutes />
    </QueryParamProvider>
  </BrowserRouter>
);

export default UserRouter;
