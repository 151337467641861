import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { AtSign, Building2, Globe, List, Monitor, Network } from 'lucide-react';
import { HStack, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from '@ramp/components';

import api from 'api';
import { Issue } from 'types';
import { useAuthStore } from 'store';

import DetailsTab from './DetailsTab';
import AffectedEntitiesTab from './AffectedEntitiesTab';


interface BasicIssuesTableDetailProps {
  issue: Issue;
  simple?: boolean;
  userView?: boolean;
  userId?: string;
}

const BasicIssuesTableDetail: React.FC<BasicIssuesTableDetailProps> = ({ issue, userView, userId }) => {
  const startDate = dayjs().add(-1, 'year').format('YYYY-MM-DD');
  const endDate = dayjs().format('YYYY-MM-DD');

  const { t } = useTranslation();
  const { entity_id: entityId } = useAuthStore(store => store.user!);
  const issueDetail = (userView && userId)
    ? useQuery(
      ['issue', issue.codename],
      () => api.users.getIssueDetail(userId!, issue.codename, { start_date: startDate, end_date: endDate })
    )
    : useQuery(
      ['issue', issue.codename],
      () => api.clients.getIssueDetail(entityId!, issue.codename, { start_date: startDate, end_date: endDate })
    );

  if (issueDetail.isLoading) {
    return (
      <HStack height={240} alignItems="center" justifyContent="center">
        <Spinner size="md" color="brand.500" />
      </HStack>
    );
  }

  return (
    <Tabs size="md" colorScheme="brand">
      <TabList>
        <Tab _selected={{ color: 'brand.500', borderColor: 'currentcolor' }}>
          <List size={16} />
          &nbsp;&nbsp;
          {t('admin.dashboard.issues.table.details')}
        </Tab>
        <Tab _selected={{ color: 'brand.500', borderColor: 'currentcolor' }}>
          {issue.object_type === 'network' && <Globe size={16} />}
          {issue.object_type === 'device' && <Monitor size={16} />}
          {issue.object_type === 'email' && <AtSign size={16} />}
          {issue.object_type === 'network_device' && <Network size={16} />}
          {issue.object_type === 'client' && <Building2 size={16} />}
          &nbsp;&nbsp;
          {issue.object_type === 'network' && t('admin.dashboard.issues.table.affectedNetworks')}
          {issue.object_type === 'device' && t('admin.dashboard.issues.table.affectedDevices')}
          {issue.object_type === 'email' && t('admin.dashboard.issues.table.affectedEmails')}
          {issue.object_type === 'network_device' && t('admin.dashboard.issues.table.affectedNetworkDevices')}
          {issue.object_type === 'client' && t('admin.dashboard.issues.table.affectedObjects')}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <DetailsTab issue={issue} />
        </TabPanel>
        <TabPanel>
          <AffectedEntitiesTab issue={issueDetail.data!} simple={true} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default BasicIssuesTableDetail;
