import React, { useEffect } from 'react';

import { Role } from 'types';

import BaseRouter from 'router/base';
import UserRouter from 'router/user';
import AdminRouter from 'router/admin';
import FirmAdminRouter from 'router/firmadmin';
import SuperAdminRouter from 'router/superadmin';
import { hasRole, setFavicon } from 'utils/utils';
import { useAuthStore, useLayoutStore } from 'store';
import { useColorMode, useRampTheme } from '@ramp/components';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RouterProps {}

const Router: React.FC<RouterProps> = () => {
  const { colorMode, setColorMode } = useColorMode();
  const setTheme = useLayoutStore((store) => store.setTheme);
  const theme = useLayoutStore((store) => store.theme);
  const { setBrand } = useRampTheme();
  const { user, loginAsAdmin, loginAsPartnerAdmin } = useAuthStore((store) => store);

  const [firstRender, setFirstRender] = React.useState(true);

  useEffect(() => {
    setFavicon(theme.logo_secondary_dark);
    if (setBrand) setBrand(theme.color_scheme);

    if (theme.theme === 'all') return;
    if (theme.theme === colorMode) return;

    setColorMode(theme.theme);
  }, [theme]);

  // If user is not logged in -> BaseRouter
  if (!user) return <BaseRouter />;

  // If user is logged in -> set theme
  if (firstRender) {
    setTheme({
      ...theme,
      logo_primary_light: user.logo_primary_light,
      logo_primary_dark: user.logo_primary_dark,
      logo_secondary_light: user.logo_secondary_light,
      logo_secondary_dark: user.logo_secondary_dark,
      theme: user.theme,
      color_scheme: user.color_scheme,
    });

    setFirstRender(false);
  }

  // If user is logged in & has user role -> UserRouter
  if (user && hasRole(Role.user, user.role)) return <UserRouter />;

  // If user is logged in & has admin role -> AdminRouter
  if (user && hasRole(Role.admin, user.role)) return <AdminRouter />;

  // If user is logged in & has firmadmin role -> FirmAdminRouter
  if (user && hasRole(Role.firmadmin, user.role)) {
    if (!loginAsAdmin.entityId) return <FirmAdminRouter />;
    return <AdminRouter />;
  }

  // If user is logged in & has superadmin role -> SuperAdminRouter
  if (user && hasRole(Role.superadmin, user.role)) {
    if (loginAsAdmin.entityId) return <AdminRouter />;
    if (loginAsPartnerAdmin.entityId) return <FirmAdminRouter />;
    return <SuperAdminRouter />;
  }

  return <BaseRouter />;
};

export default Router;
