import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { RCard, RPage } from '@ramp/components';
import { useQuery } from '@tanstack/react-query';

import api from 'api';
import NoDataPage from 'components/NoDataPage';
import { PRODUCT_NAME } from 'utils/constants';
import { useAuthStore, useLayoutStore } from 'store';
import BasicIssuesTable from 'components/tables/BasicIssuesTable';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IssuesProps {}

const IssuesList: React.FC<IssuesProps> = () => {
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);
  const { noUsersInCompany, noScansInCompany } = useLayoutStore(store => store);

  const startDate = dayjs().add(-1, 'year').format('YYYY-MM-DD');
  const endDate = dayjs().format('YYYY-MM-DD');

  const issues = useQuery(
    ['issues', user.user_id],
    () => api.users.getIssues(user.user_id, { start_date: startDate, end_date: endDate }),
    {
      cacheTime: 0,
      staleTime: 50000
    },
  );

  if (noScansInCompany) {
    return (
      <NoDataPage
        heading={t('admin.issues.heading')}
        pageLoading={issues.isLoading}
        noUsersInCompany={noUsersInCompany}
        noScansInCompany={noScansInCompany}
      />
    );
  }

  return (
    <RPage
      px={6}
      pt={8}
      loading={false}
      heading={t('admin.issues.heading')}
      title={t('admin.issues.title', { productName: PRODUCT_NAME })}
    >
      <RCard w="full" titleOutside={false}>
        <BasicIssuesTable
          id="issues_basic"
          issues={(issues.data || [])}
          loading={issues.isLoading}
          userView
          userId={user.user_id}
        />
      </RCard>
    </RPage>
  );
};

export default IssuesList;
