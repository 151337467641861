import React, { useMemo } from 'react';
import {
  ColumnsType, HStack, RTable, Text, useColorModeValue,
} from '@ramp/components';
import { FiUser } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import { NotificationType, SuccessfulNotificationResult } from 'types';
import { serializePlatform, serializeVendor } from 'utils/serializators';
import { formatNotificationState, parseRoute, useNotificationStateIcon, usePlatformIcon } from 'utils/utils';


interface SuccessNotificationsTableProps {
  keyId?: string,
  id: string,
  defaultPageSize?: number,
  notificationsType?: NotificationType,
  notifications?: SuccessfulNotificationResult[]
}

interface SuccessNotificationsRow extends SuccessfulNotificationResult {
  key: number,
}

const SuccessNotificationsTable: React.FC<SuccessNotificationsTableProps> = ({
  notificationsType = NotificationType.CUSTOM_NOTIFICATION, notifications = [],
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuthStore(state => state.user!);

  const columns: ColumnsType<SuccessNotificationsRow> = [
    {
      title: t('components.table.deviceEvaluation.platform'),
      key: 'platform',
      width: '120px',
      align: 'center',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
      },
      render: (_, { device }) => (
        (
          <HStack w="min-content" justify="center" spacing={2}>
            <Text fontSize="1.2rem">{usePlatformIcon(device.platform!)}</Text>
            <Text>{serializePlatform(device.platform!)}</Text>
          </HStack>
        )
      ),
    },
    {
      title: t('components.table.deviceEvaluation.vendor'),
      key: 'vendor',
      width: '140px',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
      },
      render: (_, { device }) => serializeVendor(device.manufacturer!),
    },
    {
      title: t('components.table.deviceEvaluation.model'),
      key: 'model',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
      },
      render: (_, { device }) => <Text>{device.marketing_name || device.model}</Text>,
    },
    {
      title: t('components.table.deviceEvaluation.user'),
      key: 'owner',
      sorting: true,
      width: '240px',
      sortingOptions: {
        dataType: 'string',
      },
      render: (_, { device }) => (
        <HStack
          align="center"
          bg={useColorModeValue('gray.25', 'gray.700')}
          borderRadius="lg"
          px={2}
          py={1}
          width="fit-content"
          id="user-link"
          _hover={{
            textDecor: 'underline',
          }}
        >
          <Text fontSize="md">
            <FiUser />
          </Text>
          <Text
            fontSize="sm"
            id="user-link"
          >
            {device.owner.email}
          </Text>
        </HStack>
      ),
    },
    {
      key: 'state',
      title: t('admin.notifications.table.state'),
      align: 'center',
      render: (_, notification) => (
        <HStack
          align="center"
          bg={useColorModeValue('gray.25', 'gray.700')}
          borderRadius="lg"
          px={2}
          py={1}
          width="fit-content"
          mx="auto"
        >
          <Text fontSize="sm" fontWeight={500}>
            {useNotificationStateIcon(notificationsType, notification.notification_state)}
          </Text>
          <Text fontSize="sm" fontWeight={500}>{formatNotificationState(notification.notification_state, t)}</Text>
        </HStack>
      ),
    },
  ];

  const formattedSuccessNotifications: SuccessNotificationsRow[] = useMemo(() => {
    return notifications
      ? notifications.map((notificationLog, idx) => ({ key: idx, ...notificationLog }))
      : [];
  }, [notifications]);

  return (
    <RTable
      columns={columns}
      data={formattedSuccessNotifications}
      onRowClick={({ device }: SuccessNotificationsRow, e) => {
        if ((e.target as HTMLElement).id === 'user-link') {
          navigate(parseRoute(
            user,
            ROUTES.ADMIN.USERS.DETAIL,
            { id: device.owner.id || '' },
          ));
        } else {
          navigate(parseRoute(user, ROUTES.ADMIN.DEVICES.DETAIL, { id: device.id, platform: device.platform! }));
        }
      }}
      pagination={{
        showTotal: true,
        defaultPageSize: 10,
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
    />
  );
};

export default SuccessNotificationsTable;
