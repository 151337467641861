import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BoxSelect, Eye, ListChecks } from 'lucide-react';
import {
  ColumnsType, HStack, SimpleTable, Tab, TabList,
  TabPanel, TabPanels, Tabs, Tag, Text
} from '@ramp/components';

import { AppAnalysis } from 'types/api/devices';
import AppRiskRating from 'components/AppRiskRating';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import { useDeviceDetail } from 'pages/admin/devices/detail/DeviceDetailProvider';

import TrackersTable from '../TrackersTable';
import PermissionsTable from '../PermissionsTable';


interface RequestedAppsTableProps {
  id: string;
  apps?: AppAnalysis[];
  loading?: boolean;
}

interface RequestedAppRow extends AppAnalysis {
  key: number;
}

const RequestedAppsTable: React.FC<RequestedAppsTableProps> = ({ apps, loading }) => {
  if (!apps) return null;

  const { t } = useTranslation();
  const { appsIcons } = useDeviceDetail();

  const columns: ColumnsType<RequestedAppRow> = useMemo(() => ([
    {
      title: t('components.table.requestedApps.name'),
      key: 'name',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
      },
      render: (_, app) => (
        <HStack spacing="16px">
          {app.icon_url
            ? (
              <img
                src={appsIcons ? appsIcons[app.app_identifier] || app.icon_url || '' : app.icon_url}
                alt={app.app_identifier}
                loading="lazy"
                style={{ width: '32px', height: '32px', borderRadius: '8px' }}
              />
            )
            : <BoxSelect size="32px" />
          }
          <Text>{app.app_name || app.app_identifier}</Text>
        </HStack>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        sortingFn: ({ original: a }, { original: b }) => a.status.localeCompare(b.status),
      },
      render: (_, app) => {
        switch (app.status) {
          case 'finished':
            return <Tag colorScheme="success">{t('components.table.requestedApps.finished')}</Tag>;
          case 'unfinished':
            // eslint-disable-next-line max-len
            return <Tag colorScheme="warning">{t('components.table.requestedApps.unfinished')}</Tag>;
          case 'rescan_requested':
            // eslint-disable-next-line max-len
            return <Tag colorScheme="warning">{t('components.table.requestedApps.rescanRequested')}</Tag>;
          default:
            // eslint-disable-next-line max-len
            return <Tag colorScheme="warning">{t('components.table.requestedApps.unfinished')}</Tag>;
        }
      },
    },
    {
      title: t('components.table.requestedApps.riskRating'),
      key: 'security_score',
      sorting: true,
      sortingOptions: {
        dataType: 'number',
      },
      render: (_, app) => <AppRiskRating score={app.security_score} />
    },
    {
      title: t('components.table.requestedApps.trackersDetection'),
      key: 'trackers_detection',
      sorting: true,
      sortingOptions: {
        dataType: 'number',
        sortingFn: ({ original: a }, { original: b }) =>
          (a.trackers_detection?.detected || 0) - (b.trackers_detection?.detected || 0),
      },
      render: (_, app) => {
        const trackers = app.trackers_detection?.detected || 0;
        const trackersColor = trackers > 0 ? 'red.500' : app.status !== 'unfinished' ? 'green.500' : 'gray.500';
        return <Text color={trackersColor} fontWeight="medium">{app.status !== 'unfinished' ? trackers : '-'}</Text>;
      }
    },
    {
      title: t('components.table.requestedApps.virusDetection'),
      key: 'virus_detection',
      sorting: true,
      sortingOptions: {
        dataType: 'number',
        sortingFn: ({ original: a }, { original: b }) =>
          (a.virus_detection?.total || 0) - (b.virus_detection?.total || 0),
      },
      render: (_, app) => {
        const viruses = app.virus_detection?.total || 0;
        const virusesColor = viruses > 0 ? 'red.500' : app.status !== 'unfinished' ? 'green.500' : 'gray.500';
        return <Text color={virusesColor} fontWeight="medium">{app.status !== 'unfinished' ? viruses : '-'}</Text>;
      }
    },
    {
      title: t('components.table.requestedApps.permissions'),
      key: 'permissions',
      sorting: true,
      sortingOptions: {
        dataType: 'number',
        sortingFn: ({ original: a }, { original: b }) =>
          (a.permissions?.length || 0) - (b.permissions?.length || 0),
      },
      render: (_, app) => (
        <Text fontWeight="medium">
          {app.status !== 'unfinished' ? app.permissions?.length || 0 : '-'}
        </Text>
      )
    }
  ]), []);

  const formattedApps: RequestedAppRow[] = useMemo(() => {
    return apps.map((app, key) => ({ key, ...app }));
  }, [apps]);

  return (
    <SimpleTable
      loading={loading}
      columns={columns}
      data={formattedApps}
      pagination={{
        showTotal: true,
        defaultPageSize: 10,
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      expandable={{
        rowExpandable: app => app.status === 'finished',
        render: (requestedApp) => (
          <Tabs size="md" colorScheme="brand">
            <TabList>
              <Tab _selected={{ color: 'brand.500', borderColor: 'currentcolor' }}>
                <Eye size={16} />
                &nbsp;
                {t('admin.devices.detail.apps.requested.trackers')}
                &nbsp;
                <Tag>{requestedApp.trackers_detection?.detected || 0}</Tag>
              </Tab>
              <Tab _selected={{ color: 'brand.500', borderColor: 'currentcolor' }}>
                <ListChecks size={16} />
                &nbsp;
                {t('admin.devices.detail.apps.requested.permissions')}
                &nbsp;
                <Tag>{requestedApp.permissions?.length || 0}</Tag>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <TrackersTable id="app_trackers" trackers={requestedApp.trackers_detection?.trackers} />
              </TabPanel>
              <TabPanel>
                <PermissionsTable id="app_permissions" permissions={requestedApp.permissions} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )
      }}
      interleaveRows
    />
  );
};

export default RequestedAppsTable;