import React from 'react';
import { Element } from 'react-scroll';
import { ChevronDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { Heading, HStack, RCard, Search, Text, useColorModeValue, VStack } from '@ramp/components';

import { AppInfo } from 'types';
import SystemAppsTable from 'components/tables/SystemAppsTable';


interface SystemAppsProps {
  showSystemApps: boolean;
  setShowSystemApps: React.Dispatch<React.SetStateAction<boolean>>;
  filteredApps: AppInfo[];
  searchInSystem: string;
  setSearchInSystem: React.Dispatch<React.SetStateAction<string>>;
}

const SystemApps: React.FC<SystemAppsProps> = ({
  showSystemApps,
  setShowSystemApps,
  filteredApps,
  searchInSystem,
  setSearchInSystem
}) => {
  const { t } = useTranslation();
  const systemApps = filteredApps.filter(app => app.eval_category === 'system');

  return (
    <VStack w="full" mt="2rem !important" alignItems="start" spacing={0}>
      <Element name="system_apps" style={{ width: '100%' }}>
        <HStack
          w="full"
          alignItems="space-between"
          justifyContent="space-between"
          color={useColorModeValue('black', 'gray.400')}
        >
          <HStack
            w="fit-content"
            cursor="pointer"
            alignItems="center"
            color={useColorModeValue('black', 'gray.400')}
            onClick={() => setShowSystemApps(prev => !prev)}
          >
            <Heading
              fontSize="1.5rem"
              fontWeight={600}
              color={useColorModeValue('black', 'gray.400')}
            >
              {t('admin.devices.detail.apps.system.title')}
            </Heading>
            <ChevronDown
              size={32}
              strokeWidth={3}
              style={{
                transition: 'all .2s ease-in-out',
                transform: showSystemApps ? 'rotate(-180deg)' : 'none'
              }}
            />
          </HStack>
          <Search
            w="360px"
            size="sm"
            value={searchInSystem}
            // eslint-disable-next-line max-len
            placeholder={`${t('admin.devices.detail.apps.risky.searchIn')} '${t('admin.devices.detail.apps.system.title')}'`}
            onChange={e => setSearchInSystem(e.target.value)}
            transition="all 0.2s ease-in-out"
            opacity={showSystemApps ? 1 : 0}
            _hover={{
              cursor: showSystemApps ? 'inherit' : 'none',
            }}
          />
        </HStack>
      </Element>
      <AnimatePresence>
        {showSystemApps && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: 20 }}
            style={{ width: '100%' }}
          >
            <RCard w="full">
              <SystemAppsTable
                id="device_detail_system_apps"
                apps={systemApps}
                searchFor={searchInSystem}
              />
            </RCard>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!showSystemApps && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: 20 }}
            style={{ width: '100%' }}
          >
            <VStack
              w="full"
              h="120px"
              mt="0.5rem"
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              borderRadius="lg"
              transition="all .2s ease-in-out"
              bg={useColorModeValue('white', 'gray.750')}
              _hover={{
                bg: useColorModeValue('gray.10', 'gray.700'),
              }}
              onClick={() => setShowSystemApps(true)}
            >
              <Text
                fontSize="1rem"
                fontWeight={500}
                transition="all .2s ease-in-out"
                color={useColorModeValue('gray.400', 'gray.500')}
              >
                {t('admin.devices.detail.apps.system.showBtn')}
              </Text>
            </VStack>
          </motion.div>
        )}
      </AnimatePresence>
    </VStack>
  );
};

export default SystemApps;
