import React from 'react';
import { Server } from '@ramp/icons';
import { RefreshCcw } from 'lucide-react';
import { FallbackProps } from 'react-error-boundary';
import { Button, Heading, Text, VStack } from '@ramp/components';

import ROUTES from 'router/routes';


type SomethingWentWrongProps = FallbackProps;

const SomethingWentWrong: React.FC<SomethingWentWrongProps> = ({ error }) => {
  const [spin, setSpin] = React.useState(false);

  console.error(error);

  const navigateToHome = () => {
    setSpin(true);
    setTimeout(() => {
      window.location.href = ROUTES.BASE.ROOT;
    }, 500);
  };

  return (
    <VStack w="full" h="100vh" align="center" justify="center" spacing={6}>
      <VStack spacing={4}>
        <Server size={164} />
        <VStack spacing={2}>
          <Heading>Something Went Wrong</Heading>
          <Text textAlign="center">
            Please try again later or contact our support team for assistance.<br/>
            We were informed about this error and we'll fix it as soon as possible.
          </Text>
        </VStack>
      </VStack>
      <Button
        variant="brand"
        onClick={navigateToHome}
        leftIcon={
          <RefreshCcw
            size={16}
            style={spin
              ? { transition: 'all 0.5s ease-in-out', transform: 'rotate(360deg)' }
              : undefined
            }
          />
        }
      >
        Reload Page
      </Button>
    </VStack>
  );
};

export default SomethingWentWrong;
