import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter } from 'react-router-dom';

import FirmAdminRoutes from './FirmAdminRoutes';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FirmAdminRouterProps {}

const FirmAdminRouter: React.FC<FirmAdminRouterProps> = () => (
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <FirmAdminRoutes />
    </QueryParamProvider>
  </BrowserRouter>
);

export default FirmAdminRouter;
