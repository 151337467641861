import React, { useState, useMemo } from 'react';
import { Settings } from 'lucide-react';
import { BiEnvelope } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { FiAlertOctagon, FiEdit2, FiLock, FiRotateCw, FiUserCheck, FiArchive, FiCheckSquare } from 'react-icons/fi';
import {
  Box, Text, useToast,
  Menu, MenuButton, IconButton, MenuList, MenuItem
} from '@ramp/components';

import api from 'api';
import { UserDataWithIssues } from 'types';
import UserEditModal from 'pages/admin/users/detail/UserEditModal';
import UserConfirmModal, { UserConfirmModalProps } from 'pages/admin/users/detail/UserConfirmModal';


interface UserActionsProps {
  id: string,
  user?: UserDataWithIssues,
  firmAdmin?: boolean,
}

const UserActions: React.FC<UserActionsProps> = ({ id, user, firmAdmin = false }) => {
  if (!user) return null;

  const { t } = useTranslation();
  const toast = useToast();
  const query = useQueryClient();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmModalContent, setConfirmModalContent] = useState<Omit<UserConfirmModalProps, 'isOpen' | 'onClose'>>();

  const userActions = useMemo(() => ([
    {
      id: 'edit',
      icon: <FiEdit2 />,
      name: t('admin.users.detail.editUser'),
      buttonVariant: 'borderless',
      action: () => setShowEditModal(true),
    },
    {
      id: 'send_invitation',
      icon: <BiEnvelope />,
      name: t('admin.users.detail.sendInvitation.title'),
      buttonVariant: 'borderless',
      action: () => {
        setConfirmModalContent({
          title: t('admin.users.detail.sendInvitation.question'),
          description: (
            <Text>
              {t(
                'admin.users.detail.sendInvitation.description',
                { email: user.email }
              )}
            </Text>
          ),
          okText: t('admin.users.detail.sendInvitation.okText'),
          onOk: (e) => {
            e.preventDefault();
            setShowConfirmModal(false);
            api.users.createInvitation(id)
              .then(() => {
                toast({
                  title: t('admin.users.detail.sendInvitation.successTitle'),
                  description: t('admin.users.detail.sendInvitation.successDescription', { email: user.email }),
                  status: 'success',
                  position: 'bottom-right',
                  duration: 4000,
                  containerStyle: {
                    maxWidth: '380px',
                  },
                });
              })
              .catch(() => {});
          },
        });
        setShowConfirmModal(true);
      },
    },
    {
      id: 'reset_password',
      icon: <FiRotateCw />,
      name: t('admin.users.detail.resetPassword.title'),
      buttonVariant: 'borderless',
      action: () => {
        setConfirmModalContent({
          title: t('admin.users.detail.resetPassword.question'),
          description: (
            <Text>
              {t(
                'admin.users.detail.resetPassword.description',
                { email: user.email }
              )}
            </Text>
          ),
          okText: t('admin.users.detail.resetPassword.okText'),
          onOk: (e) => {
            e.preventDefault();
            setShowConfirmModal(false);
            api.users.resetPasswordById(id)
              .then(async () => {
                toast({
                  title: t('admin.users.detail.resetPassword.successTitle'),
                  description: t('admin.users.detail.resetPassword.successDescription', { email: user.email }),
                  status: 'success',
                  position: 'bottom-right',
                  duration: 4000,
                  containerStyle: {
                    maxWidth: '380px',
                  },
                });
                await query.invalidateQueries(['users', user.id]);
              })
              .catch(() => {});
          },
        });
        setShowConfirmModal(true);
      },
    },
    {
      id: 'block_unblock_user',
      icon: user.status === 'disabled' ? <FiUserCheck /> : <FiAlertOctagon />,
      name: user.status === 'disabled'
        ? t('admin.users.detail.unblock.title')
        : t('admin.users.detail.block.title'),
      buttonVariant: 'borderless',
      action: () => {
        if (user.status === 'disabled') {
          setConfirmModalContent({
            title: t('admin.users.detail.unblock.question'),
            description: (
              <Text>
                {t(
                  'admin.users.detail.unblock.description',
                  { email: user.email }
                )}
              </Text>
            ),
            okText: t('admin.users.detail.unblock.okText'),
            onOk: (e) => {
              e.preventDefault();
              setShowConfirmModal(false);

              api.users.changeStatus(id, 'active')
                .then(async () => {
                  toast({
                    title: t('admin.users.detail.unblock.successTitle'),
                    description: t('admin.users.detail.unblock.successDescription', { name: user.name }),
                    status: 'success',
                    position: 'bottom-right',
                    duration: 4000,
                    containerStyle: {
                      maxWidth: '380px',
                    },
                  });
                  await query.invalidateQueries(['users', user.id]);
                })
                .catch(() => {});
            },
          });
        } else {
          setConfirmModalContent({
            title: t('admin.users.detail.block.question'),
            description: (
              <Text>
                {t(
                  'admin.users.detail.block.description',
                  { email: user.email }
                )}
              </Text>
            ),
            okText: t('admin.users.detail.block.okText'),
            onOk: (e) => {
              e.preventDefault();
              setShowConfirmModal(false);

              api.users.changeStatus(id, 'disabled')
                .then(async () => {
                  toast({
                    title: t('admin.users.detail.block.successTitle'),
                    description: t('admin.users.detail.block.successDescription', { name: user.name }),
                    status: 'success',
                    position: 'bottom-right',
                    duration: 4000,
                    containerStyle: {
                      maxWidth: '380px',
                    },
                  });
                  await query.invalidateQueries(['users', user.id]);
                })
                .catch(() => {});
            },
          });
        }

        setShowConfirmModal(true);
      },
    },
    {
      id: 'disable2fa',
      icon: <FiLock />,
      name: t('admin.users.detail.disable2fa.title'),
      buttonVariant: 'borderless',
      show: user.otp_enabled,
      action: () => {
        setConfirmModalContent({
          title: t('admin.users.detail.disable2fa.question'),
          description: (
            <Text>
              {t(
                'admin.users.detail.disable2fa.description',
                { email: user.email }
              )}
            </Text>
          ),
          okText: t('admin.users.detail.disable2fa.okText'),
          onOk: (e) => {
            e.preventDefault();
            setShowConfirmModal(false);
            api.users.disableOtp(id)
              .then(async () => {
                toast({
                  title: t('admin.users.detail.disable2fa.successTitle'),
                  description: t('admin.users.detail.disable2fa.successDescription', { email: user.email }),
                  status: 'success',
                  position: 'bottom-right',
                  duration: 4000,
                  containerStyle: {
                    maxWidth: '380px',
                  },
                });
                await query.invalidateQueries(['users', user.id]);
              })
              .catch(() => {});
          },
        });
        setShowConfirmModal(true);
      },
    },
    {
      id: 'archive',
      icon: user.status === 'archived' ? <FiCheckSquare /> : <FiArchive />,
      name: user.status === 'archived'
        ? t('admin.users.detail.activate.title')
        : t('admin.users.detail.archive.title'),
      buttonVariant: 'borderless',
      action: () => {
        setConfirmModalContent({
          title: t(
            'admin.users.detail.archive.question',
            {
              status: user.status === 'archived'
                ? t('admin.users.detail.activate.title').toLowerCase()
                : t('admin.users.detail.archive.title').toLowerCase()
            }
          ),
          description: (
            <Text>
              {t(
                'admin.users.detail.archive.description',
                {
                  status: user.status === 'archived'
                    ? t('admin.users.detail.activate.title').toLowerCase()
                    : t('admin.users.detail.archive.title').toLowerCase(),
                  email: user.email,
                }
              )}
            </Text>
          ),
          okText: user.status === 'archived'
            ? t('admin.users.detail.activate.okText')
            : t('admin.users.detail.archive.okText'),
          onOk: (e) => {
            e.preventDefault();
            setShowConfirmModal(false);
            if (user.status === 'archived') {
              api.users.changeStatus(id, 'active')
                .then(async () => {
                  toast({
                    title: t('admin.users.detail.activate.successTitle'),
                    description: t('admin.users.detail.activate.successDescription', { name: user.name }),
                    status: 'success',
                    position: 'bottom-right',
                    duration: 4000,
                    containerStyle: {
                      maxWidth: '380px',
                    },
                  });
                  await query.invalidateQueries(['users', user.id]);
                })
                .catch(() => {});
            } else {
              api.users.changeStatus(id, 'archived')
                .then(async () => {
                  toast({
                    title: t('admin.users.detail.archive.successTitle'),
                    description: t('admin.users.detail.archive.successDescription', { name: user.name }),
                    status: 'success',
                    position: 'bottom-right',
                    duration: 4000,
                    containerStyle: {
                      maxWidth: '380px',
                    },
                  });
                  await query.invalidateQueries(['users', user.id]);
                })
                .catch(() => {});
            }
          },
        });
        setShowConfirmModal(true);
      },
    },
  ]), []);

  return (
    <Box>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Settings'
          // @ts-ignore
          size="md"
          icon={<Settings size={18} />}
          variant='solid'
        />
        <MenuList>
          {userActions.map(({ id: actionId, name, icon, action }) => (
            <MenuItem key={actionId} icon={icon} onClick={action}>
              {name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <UserConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        {...confirmModalContent}
      />

      <UserEditModal
        user={user}
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        firmAdmin={firmAdmin}
      />
    </Box>
  );
};

export default UserActions;
