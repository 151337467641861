import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { User } from 'types';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { parseRoute } from 'utils/utils';

import Dashboard from 'pages/user/dashboard';
import IssuesList from 'pages/user/issues/list';
import DevicesList from 'pages/user/devices/list';
import NetworksList from 'pages/user/networks/list';
import DeviceDetail from 'pages/admin/devices/detail';
import NetworkDetail from 'pages/user/networks/detail';
import TestAssignment from 'pages/base/TestAssignment';
import AccountSettings from 'pages/admin/account_settings';
import FirmVerification from 'pages/base/FirmVerification';
import QuizzesList from 'pages/user/education/quizzes/list';
import NotificationsList from 'pages/user/notifications/list';
import PrivacyProtection from 'pages/user/privacy_protection';
import LocalThreats from 'pages/admin/education/local_threats';
import Recommendations from 'pages/admin/education/recommendations';
import FirmAdminVerification from 'pages/base/FirmAdminVerification';

import withUserLayout from './withUserLayout';
import ResetPassword from '../../pages/base/ResetPassword';
import ForgotPassword from '../../pages/base/ForgotPassword';


interface UserRoutesProps {
  removeNotFoundRoute?: boolean,
}


export const renderUserRoutes = (user: User, removeNotFoundRoute: boolean) => (
  <React.Fragment>
    <Route path={ROUTES.USER.DASHBOARD} element={withUserLayout(Dashboard)} />
    <Route path={ROUTES.USER.NETWORKS.LIST} element={withUserLayout(NetworksList)} />
    <Route path={ROUTES.USER.NETWORKS.DETAIL} element={withUserLayout(NetworkDetail)} />
    <Route path={ROUTES.USER.DEVICES.LIST} element={withUserLayout(DevicesList)} />
    <Route path={ROUTES.USER.DEVICES.DETAIL} element={withUserLayout(DeviceDetail)} />
    <Route path={ROUTES.USER.ISSUES.LIST} element={withUserLayout(IssuesList)} />
    <Route path={ROUTES.USER.PRIVACY_PROTECTION} element={withUserLayout(PrivacyProtection)} />
    <Route path={ROUTES.USER.NOTIFICATIONS.LIST} element={withUserLayout(NotificationsList)} />
    <Route path={ROUTES.USER.EDUCATION.TESTS} element={withUserLayout(QuizzesList)} />
    <Route path={ROUTES.USER.EDUCATION.RECOMMENDATIONS} element={withUserLayout(Recommendations)} />
    <Route path={ROUTES.USER.EDUCATION.LOCAL_THREATS} element={withUserLayout(LocalThreats)} />

    <Route path={ROUTES.USER.ACCOUNT_SETTINGS} element={withUserLayout(AccountSettings)} />

    <Route path={ROUTES.BASE.FORGOT_PASSWORD} element={<ForgotPassword />} />
    <Route path={ROUTES.BASE.RESET_PASSWORD} element={<ResetPassword />} />
    <Route path={ROUTES.BASE.FIRM_VERIFICATION} element={<FirmVerification />} />
    <Route path={ROUTES.BASE.FIRMADMIN_VERIFICATION} element={<FirmAdminVerification />} />
    <Route path={ROUTES.BASE.TEST_ASSIGNMENT} element={<TestAssignment />} />

    {!removeNotFoundRoute && <Route path="*" element={<Navigate to={parseRoute(user!, ROUTES.USER.DASHBOARD)} />} />}
  </React.Fragment>
);

const UserRoutes: React.FC<UserRoutesProps> = ({ removeNotFoundRoute = false }) => {
  const user = useAuthStore(store => store.user!);

  return (
    <Routes>
      {renderUserRoutes(user, removeNotFoundRoute)}
    </Routes>
  );
};

export default UserRoutes;
