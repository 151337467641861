import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertTriangle, LayoutGrid } from 'lucide-react';
import { Heading, HStack, RStat, useColorModeValue, VStack } from '@ramp/components';

import { WindowsApp } from 'types/api/devices';


interface WindowsAppsStatsProps {
  sortedApps: {
    not_updated?: WindowsApp[];
    installed?: WindowsApp[];
    system?: WindowsApp[];
  };
}

const WindowsAppsStats: React.FC<WindowsAppsStatsProps> = ({ sortedApps }) => {
  const { t } = useTranslation();

  const stats = useMemo(() => ({
    count: (
      (sortedApps.not_updated || []).length +
      (sortedApps.installed || []).length +
      (sortedApps.system || []).length
    ),
  }), [sortedApps]);

  return (
    <VStack align="start" w="full">
      <Heading
        mt="2rem !important"
        mb="0.25rem !important"
        color={useColorModeValue('black', 'gray.400')}
        fontSize="1.5rem"
        fontWeight={600}
      >
        {t('admin.devices.detail.apps.statistics.title')}
      </Heading>
      <HStack w="full" spacing={['16px', '16px', '16px']}>
        <RStat
          icon={<LayoutGrid size="32px" />}
          number={stats.count}
          description={t('admin.devices.detail.apps.statistics.count')}
          type="darkGradient"
          w="full"
          maxW="320px"
        />
        <RStat
          icon={<AlertTriangle size="32px" />}
          number={sortedApps.not_updated?.length || 0}
          description={t('admin.devices.detail.apps.statistics.unupdated')}
          type="yellowGradient"
          w="full"
          maxW="320px"
        />
      </HStack>
    </VStack>
  );
};

export default WindowsAppsStats;