import axios, { AxiosError } from 'axios';
import {
  IAuthTokens, TokenRefreshRequest, applyAuthTokenInterceptor, clearAuthTokens,
} from 'axios-jwt';

import { APIError, ErrorFields } from 'types';


const BASE_URL = import.meta.env.VITE_API_URL! + import.meta.env.VITE_API_VERSION;

// Editing default axios settings
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng') || 'en',
  },
  timeout: 60000, // 1min
});

// JWT token refresh func
const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<IAuthTokens | string> => {
  const accessToken = await axios.post(`${BASE_URL}/auth/token/refresh`, { refresh: refreshToken })
    .then(r => r.data.access)
    .catch(() => '');

  if (!accessToken) {
    clearAuthTokens();

    // @ts-ignore
    window.location = window.location.origin;
    return '';
  }

  return accessToken ? { accessToken, refreshToken } : '';
};

// Handling Response Errors
export const handleError = (error: AxiosError<ErrorFields>): APIError => {
  // If true, we got error message from API,
  // otherwise we got standard error message
  if (error.response !== undefined) {
    return {
      code: error.response.status,
      description: error.response.data.detail,
    };
  }

  return {
    code: error.code ? parseInt(error.code, 10) : 0,
    description: error.message,
  };
};

// Applying the JWT token interceptor into axios instance
applyAuthTokenInterceptor(axiosInstance, { requestRefresh });

export default axiosInstance;
