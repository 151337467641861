import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { getAccessToken } from 'axios-jwt';

import { INotification } from 'utils/notifications';
import {
  ErrorFields,
  DevicesStats,
  IssueStats,
  RiskScore,
  UserData,
  ClientCount,
  CVEIssue,
  NotificationLog,
  Client,
  EmailBreachScan,
  DeviceUserEvaluation,
  NetworkScan,
  NotificationsResult,
  IssueDetail,
  Issue,
  QuizAssignment,
  Quiz,
} from 'types';

import axios, { handleError } from './_defaults';

// eslint-disable-next-line max-len
const getAllClients = (): Promise<Client[]> => new Promise<Client[]>((resolve, reject) => {
  axios.get<Client[]>('/clients')
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getNetworks = (entityId: string, start_date: string, end_date: string): Promise<NetworkScan[]> => new Promise<NetworkScan[]>((resolve, reject) => {
  axios.get<NetworkScan[]>(`/clients/${entityId}/networks`, { params: { start_date, end_date } })
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getNetwork = (entityId: string, networkId: string, networkType: string): Promise<NetworkScan> =>
  new Promise<NetworkScan>((resolve, reject) => {
    axios.get<NetworkScan>(`/clients/${entityId}/networks/${networkId}/${networkType}`, { params: { networkType } })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getClient = (entityId: string, accessToken?: string): Promise<Client> =>
  new Promise<Client>((resolve, reject) => {
    axios.get<Client>(
      `/clients/${entityId}`,
      { headers: { Authorization: `Bearer ${accessToken || getAccessToken()}` } },
    )
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

type UpdateClientInfo = {
  name?: string,
  scan_frequency_in_seconds?: number,
  contact_name?: string,
  contact_email?: string,
  country?: string,
  street?: string,
  city?: string,
  zip_code?: string,
  state?: string,
  company_id?: string,
  vat_id?: string,
};
const updateClient = (client_id: string, clientInfo: UpdateClientInfo): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios.patch(`/clients/${client_id}`, { ...clientInfo })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

type ClientInfo = {
  name: string,
  admin_email: string,
  admin_password: string,
  contact_email: string,
  contact_name: string,
  country: string,
  language: string,
  street: string,
  city: string,
  zip_code: string,
  company_id: string,
  vat_id?: string,
  state?: string,
};
const addClient = (clientInfo: ClientInfo): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios.post('/clients', clientInfo)
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const archiveClient = (client_id: string): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios.patch(`/clients/${client_id}/status`, { status: 'archived' })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const activateClient = (client_id: string): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios.patch(`/clients/${client_id}/status`, { status: 'active' })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

interface GetDeviceType {
  client_id: string,
  platform?: string,
  manufacturer?: string,
  marketing_name?: string,
  device?: string,
  start_date?: dayjs.Dayjs,
  end_date?: dayjs.Dayjs,
  device_name?: string,
}

const getDevices: (o: GetDeviceType) => Promise<DeviceUserEvaluation[]> = ({
  client_id,
  platform,
  manufacturer,
  marketing_name,
  device,
  start_date,
  end_date,
  device_name,
}) => new Promise<DeviceUserEvaluation[]>((resolve, reject) => {
  const params = {
    platform,
    manufacturer,
    marketing_name,
    device,
    start_date: start_date?.format('YYYY-MM-DD'),
    end_date: end_date?.format('YYYY-MM-DD'),
    device_name,
  };

  axios.get<DeviceUserEvaluation[]>(`/clients/${client_id}/devices`, { params })
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getDevicesManufacturers = (client_id: string, platform: string): Promise<string[]> => new Promise<string[]>((resolve, reject) => {
  const params = { platform };
  axios.get<string[]>(`/clients/${client_id}/devices/manufacturers`, { params })
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getDevicesModels = (client_id: string, platform: string, manufacturer: string): Promise<string[]> =>
  new Promise<string[]>((resolve, reject) => {
    const params = { platform, manufacturer };
    axios.get<string[]>(`/clients/${client_id}/devices/marketing-names`, { params })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getIssues = (
  client_id: string,
  params?: {
    platform?: string,
    manufacturer?: string,
    marketing_name?: string,
    device?: string,
    start_date?: string,
    end_date?: string,
  },
): Promise<Issue[]> => new Promise<Issue[]>((resolve, reject) => {
  axios.get<Issue[]>(`/clients/${client_id}/issues`, { params })
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getIssueDetail = (
  client_id: string,
  issue_codename: string,
  params?: {
    start_date?: string,
    end_date?: string,
  }
): Promise<IssueDetail> => new Promise<IssueDetail>((resolve, reject) => {
  axios.get<IssueDetail>(`/clients/${client_id}/issues/${issue_codename}`, { params })
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getCveIssues = (client_id: string, platform?: string, manufacturer?: string, marketing_name?: string, device?: string): Promise<CVEIssue[]> => new Promise<CVEIssue[]>((resolve, reject) => {
  const params = {
    device, manufacturer, marketing_name, platform,
  };
  axios.get<CVEIssue[]>(`/clients/${client_id}/vuls`, { params })
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getDevicesStats = (client_id: string): Promise<DevicesStats> => new Promise<DevicesStats>((resolve, reject) => {
  axios.get<DevicesStats>(`/clients/${client_id}/stats/devices`)
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getIssuesStats = (client_id: string, start_date: string, end_date: string): Promise<IssueStats> => new Promise<IssueStats>((resolve, reject) => {
  axios.get<IssueStats>(`/clients/${client_id}/stats/issues`, { params: { start_date, end_date } })
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getRiskScoreStats = (client_id: string, start_date: string, end_date: string): Promise<RiskScore[]> => new Promise<RiskScore[]>((resolve, reject) => {
  axios.get<RiskScore[]>(`/clients/${client_id}/stats/risk-scores`, { params: { start_date, end_date } })
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

// eslint-disable-next-line max-len
const getUsers = (client_id: string): Promise<UserData[]> => new Promise<UserData[]>((resolve, reject) => {
  axios.get<UserData[]>(`/clients/${client_id}/users`)
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getNumberOfClients = (): Promise<ClientCount> => new Promise<ClientCount>((resolve, reject) => {
  axios.get<ClientCount>('/clients/count')
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getNotificationLog = (client_id: string): Promise<NotificationLog[]> => new Promise<NotificationLog[]>(
  (resolve, reject) => {
    axios.get<NotificationLog[]>(`clients/${client_id}/notification-log`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  },
);


const getPrivacyEmails = (client_id: string): Promise<EmailBreachScan> => new Promise<EmailBreachScan>(
  (resolve, reject) => {
    axios.get<EmailBreachScan>(`clients/${client_id}/privacy/emails`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  },
);

const getUserPrivacyEmails = (user_id: string): Promise<EmailBreachScan> => new Promise<EmailBreachScan>(
  (resolve, reject) => {
    axios.get<EmailBreachScan>(`users/${user_id}/privacy/emails`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  },
);

// eslint-disable-next-line max-len
const postMassNotification = (client_id: string, ids: string[], notification: INotification): Promise<NotificationsResult> =>
  new Promise<NotificationsResult>((resolve, reject) => {
    axios.post<NotificationsResult>(`clients/${client_id}/devices/send-notification`, { ids, notification })
      .then((res) => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const updateEmailStatus = (client_id: string, email_id: string, status: string): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios.patch<void>(`clients/${client_id}/privacy/emails/${email_id}/breaches`, { status })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const updateBreachStatus = (
  client_id: string,
  email_id: string,
  breach_id: string,
  status: string,
): Promise<void> => new Promise<void>((resolve, reject) => {
  axios.patch<void>(`clients/${client_id}/privacy/emails/${email_id}/breaches/${breach_id}`, { status })
    .then(() => resolve())
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getAvailableQuizzes = (client_id: string): Promise<Quiz[]> => new Promise<Quiz[]>((resolve, reject) => {
  axios.get<Quiz[]>(`clients/${client_id}/education/quizzes`)
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const getQuizAssignments = (client_id: string): Promise<QuizAssignment[]> =>
  new Promise<QuizAssignment[]>((resolve, reject) => {
    axios.get<QuizAssignment[]>(`clients/${client_id}/education/assignments`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const createQuizAssignment = (client_id: string, quiz_id: string, user_ids: string[]): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios.post<void>(`clients/${client_id}/education/assignments`, { quiz_id, user_ids })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const approveClient = (client_id: string): Promise<void> => new Promise<void>((resolve, reject) => {
  axios.patch<void>(`clients/${client_id}/approve`)
    .then(() => resolve())
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});


export default {
  getAllClients,
  getClient,
  addClient,
  archiveClient,
  activateClient,
  getNetworks,
  getNetwork,
  updateClient,
  getDevices,
  getDevicesManufacturers,
  getDevicesModels,
  getIssues,
  getIssueDetail,
  getCveIssues,
  getDevicesStats,
  getIssuesStats,
  getRiskScoreStats,
  getUsers,
  getNumberOfClients,
  getNotificationLog,
  getPrivacyEmails,
  getUserPrivacyEmails,
  postMassNotification,
  updateEmailStatus,
  updateBreachStatus,
  getAvailableQuizzes,
  getQuizAssignments,
  createQuizAssignment,
  approveClient,
};
