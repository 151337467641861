import React from 'react';
import { Tag } from '@ramp/components';
import { FiUser } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { RefreshCw, HelpCircle } from 'lucide-react';


interface ScanInvokedTagProps {
  type: 'user' | 'background' | 'unknown';
}

const ScanInvokedTag: React.FC<ScanInvokedTagProps> = ({ type }) => {
  const { t } = useTranslation();

  switch (type) {
    case 'user':
      return (
        <Tag colorScheme="unknown">
          <FiUser />
          &nbsp;{t('components.scanInvokedTag.user')}
        </Tag>
      );
    case 'background':
      return (
        <Tag colorScheme="unknown">
          <RefreshCw width="1rem" />
          &nbsp;{t('components.scanInvokedTag.background')}
        </Tag>
      );
    case 'unknown':
      return (
        <Tag colorScheme="unknown">
          <HelpCircle width="1rem" />
          &nbsp;{t('components.scanInvokedTag.unknown')}
        </Tag>
      );
  }
};

export default ScanInvokedTag;
