import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Bot as LuBot } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { FiUser, FiCheckSquare, FiXOctagon } from 'react-icons/fi';
import {
  ColumnsType, HStack, RTable, Tab, TabList,
  TabPanel, TabPanels, Tabs, Tag, Text, useColorModeValue,
} from '@ramp/components';

import { NotificationLog } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import TextTruncateTooltip from 'components/TextTruncateTooltip';
import NotificationSeverityStrip from 'components/NotificationSeverityStrip';
import { formatNotificationType, useNotificationTypeIcon } from 'utils/utils';
import FailedNotificationsTable from 'components/tables/FailedNotificationsTable';
import SuccessNotificationsTable from 'components/tables/SuccessNotificationsTable';


interface NotificationLogTableProps {
  id: string,
  defaultPageSize?: number,
  notifications?: NotificationLog[],
  loading?: boolean,
}

interface NotificationLogRow extends NotificationLog {
  key: number,
}

const PendingUpdatesTable: React.FC<NotificationLogTableProps> = ({
  id, notifications = [], defaultPageSize = 10, loading = false,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const formattedNotificationLogs: NotificationLogRow[] = useMemo(() => {
    return notifications ? notifications.map((notificationLog, key) => ({ key, ...notificationLog })) : [];
  }, [notifications]);

  const columns: ColumnsType<NotificationLogRow> = useMemo(() => ([
    {
      title: t('components.table.basicIssues.title'),
      key: 'title',
      render: (_, { title, severity }) => (
        <TextTruncateTooltip
          text={title || ''}
          maxNumOfChars={40}
          fontSize="md"
          fontWeight={600}
          noOfLines={1}
          renderChildren={truncatedTitle => (
            title
              ? (
                <HStack spacing={2}>
                  <NotificationSeverityStrip severity={severity} />
                  <Text fontWeight={600} noOfLines={1}>{truncatedTitle}</Text>
                </HStack>
              )
              : (
                <HStack spacing={2}>
                  <NotificationSeverityStrip severity={severity} />
                  <Text
                    color={useColorModeValue('gray.500', 'gray.300')}
                    fontWeight={400}
                  >
                    Background Scan Request
                  </Text>
                </HStack>
              )
          )}
        />
      ),
    },
    {
      key: 'body',
      title: t('admin.notifications.table.content'),
      render: value => (
        <TextTruncateTooltip
          text={value || ''}
          maxNumOfChars={50}
          renderChildren={truncatedBody => (
            truncatedBody || (
              <Text
                color={useColorModeValue('gray.500', 'gray.300')}
                fontWeight={400}
                fontStyle="italic"
              >
                The system has sent background scan requests to devices.
              </Text>
            )
          )}
        />
      ),
    },
    {
      key: 'notification_type',
      title: t('admin.notifications.filter.notificationType'),
      align: 'left',
      width: '240px',
      render: type => (
        <HStack
          align="center"
          bg={useColorModeValue('gray.25', 'gray.650')}
          borderRadius="lg"
          px={2}
          py={1}
          width="fit-content"
        >
          <Text fontSize="md">
            {useNotificationTypeIcon(type, { width: '1rem', height: '1rem' })}
          </Text>
          <Text
            fontSize="sm"
            id="user-link"
            fontWeight={500}
            whiteSpace="nowrap"
          >
            {formatNotificationType(type, t)}
          </Text>
        </HStack>
      ),
    },
    {
      key: 'status',
      title: t('admin.notifications.filter.sendStatus'),
      align: 'center',
      width: '200px',
      children: [
        {
          title: t('admin.notifications.table.successful'),
          key: 'successful',
          align: 'center',
          render: (_, { successful_sends }) => {
            return <Text>{successful_sends.length}</Text>;
          },
        },
        {
          title: t('admin.notifications.table.failed'),
          key: 'failed',
          align: 'center',
          render: (_, { failed_sends }) => {
            return <Text color={failed_sends.length > 0 ? 'error.400' : 'inherit'}>{failed_sends.length}</Text>;
          },
        }
      ],
    },
    {
      key: 'sender',
      title: t('admin.notifications.filter.sender'),
      render: (sender?: { id: string, email: string }) => (
        <HStack
          align="center"
          bg={useColorModeValue('gray.25', 'gray.650')}
          borderRadius="lg"
          px={2}
          py={1}
          width="fit-content"
          id="user-link"
          _hover={{
            textDecor: sender ? 'underline' : 'none',
            cursor: sender ? 'pointer' : 'default',
          }}
        >
          <Text fontSize="md">
            {sender ? <FiUser /> : <LuBot width="1rem" height="1rem" />}
          </Text>
          <TextTruncateTooltip
            text={sender ? sender.email || '' : 'System'}
            maxNumOfChars={30}
            renderChildren={truncatedBody => truncatedBody || 'System'}
          />
        </HStack>
      ),
    },
    {
      key: 'created',
      title: t('admin.notifications.filter.sentDate'),
      width: '180px',
      sorting: true,
      sortingOptions: {
        defaultSortOrder: 'desc',
      },
      render: created => dayjs(created).format('DD. MM. YYYY HH:mm'),
    },
  ]), []);

  return (
    <RTable
      columns={columns}
      data={formattedNotificationLogs}
      expandable={{
        rowExpandable: () => true,
        render: notification => (
          <Tabs size="md" colorScheme="brand">
            <TabList>
              <Tab _selected={{ color: 'brand.500', borderColor: 'currentcolor' }}>
                <FiCheckSquare />
                &nbsp;
                {t('admin.notifications.table.successfulSends')}
                &nbsp;
                <Tag>{notification.successful_sends.length}</Tag>
              </Tab>
              <Tab _selected={{ color: 'brand.500', borderColor: 'currentcolor' }}>
                <FiXOctagon />
                &nbsp;
                {t('admin.notifications.table.failedSends')}
                &nbsp;
                <Tag>{notification.failed_sends.length}</Tag>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <SuccessNotificationsTable
                  key={`${notification.key}_successful_sends`}
                  id="notifications_successful_sends"
                  notifications={notification.successful_sends}
                  notificationsType={notification.notification_type}
                />
              </TabPanel>
              <TabPanel>
                <FailedNotificationsTable
                  key={`${notification.key}_failed_sends`}
                  id="notifications_failed_sends"
                  notifications={notification.failed_sends}
                  notificationsType={notification.notification_type}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ),
      }}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      loading={loading}
      interleaveRows
    />
  );
};

export default PendingUpdatesTable;
