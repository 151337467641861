import { AxiosError } from 'axios';

import { INotification } from 'utils/notifications';
import { Scan } from 'pages/admin/devices/detail/DeviceDetailProvider';
import { AndroidScan, AppAnalysis, IosScan, LinuxScan, MacosScan, WindowsApps, WindowsScan } from 'types/api/devices';
import {
  ErrorFields, NetworkScan, RiskScore, Vulnerability,
  AndroidAppIcons, DeviceApps, DeviceRecommendation, DeviceStatus,
  Nullable,
} from 'types';

import axios, { handleError } from './_defaults';


const getRiskScores = (device_id: string, start_date: string, end_date: string): Promise<RiskScore[]> =>
  new Promise<RiskScore[]>((resolve, reject) => {
    axios.get<RiskScore[]>(`/devices/${device_id}/stats/risk-scores`, { params: { start_date, end_date } })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getAndroidScan = (device_id: string, date: string): Promise<AndroidScan> =>
  new Promise<AndroidScan>((resolve, reject) => {
    axios.get<AndroidScan>(`/devices/${device_id}/scans/${date}/android`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getIosScan = (device_id: string, date: string): Promise<IosScan> =>
  new Promise<IosScan>((resolve, reject) => {
    axios.get<IosScan>(`/devices/${device_id}/scans/${date}/ios`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getIpadOsScan = (device_id: string, date: string): Promise<IosScan> =>
  new Promise<IosScan>((resolve, reject) => {
    axios.get<IosScan>(`/devices/${device_id}/scans/${date}/ipados`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getMacosScan = (device_id: string, date: string): Promise<MacosScan> =>
  new Promise<MacosScan>((resolve, reject) => {
    axios.get<MacosScan>(`/devices/${device_id}/scans/${date}/macos`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getWindowsScan = (device_id: string, date: string): Promise<WindowsScan> =>
  new Promise<WindowsScan>((resolve, reject) => {
    axios.get<WindowsScan>(`/devices/${device_id}/scans/${date}/windows`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getLinuxScan = (device_id: string, date: string): Promise<LinuxScan> =>
  new Promise<LinuxScan>((resolve, reject) => {
    axios.get<LinuxScan>(`/devices/${device_id}/scans/${date}/linux`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getScan = (device_id: string, platform: string, date: string): Promise<Scan> =>
  new Promise<Scan>(async (resolve, reject) => {
    switch (platform) {
      case 'android':
        return getAndroidScan(device_id, date)
          .then(scan => resolve(scan))
          .catch(err => reject(err));
      case 'ios':
        return getIosScan(device_id, date)
          .then(scan => resolve(scan))
          .catch(err => reject(err));
      case 'ipados':
        return getIpadOsScan(device_id, date)
          .then(scan => resolve(scan))
          .catch(err => reject(err));
      case 'macos':
        return getMacosScan(device_id, date)
          .then(scan => resolve(scan))
          .catch(err => reject(err));
      case 'windows':
        return getWindowsScan(device_id, date)
          .then(scan => resolve(scan))
          .catch(err => reject(err));
      case 'linux':
        return getLinuxScan(device_id, date)
          .then(scan => resolve(scan))
          .catch(err => reject(err));
    }
  });

const getScanApps = (device_id: string, system: string, date: string): Promise<DeviceApps> =>
  new Promise<DeviceApps>((resolve, reject) => {
    if (['ios', 'ipados', 'macos'].includes(system)) {
      axios.get<DeviceApps>(`/devices/${device_id}/scans/${date}/apps`)
        .then(res => resolve(res.data))
        .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
    } else {
      axios.get<DeviceApps>(`/devices/${device_id}/scans/${date}/${system}/apps`)
        .then(res => resolve(res.data))
        .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
    }
  });

const getScanCveIssues = (device_id: string, date: string): Promise<Vulnerability[]> =>
  new Promise<Vulnerability[]>((resolve, reject) => {
    axios.get<Vulnerability[]>(`/devices/${device_id}/scans/${date}/vuls`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getScanDates = (device_id: string): Promise<string[]> => new Promise<string[]>((resolve, reject) => {
  axios.get<string[]>(`/devices/${device_id}/scans/dates`)
    .then(res => resolve(res.data))
    .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
});

const updateDeviceName = (device_id: string, name: string): Promise<null> =>
  new Promise<null>((resolve, reject) => {
    axios.patch<null>(`/devices/${device_id}`, { name })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const updateDeviceStatus = (device_id: string, status: DeviceStatus): Promise<null> =>
  new Promise<null>((resolve, reject) => {
    axios.patch<null>(`/devices/${device_id}`, { status })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const postNotificationToDevice = (device_id: string, notification: INotification): Promise<null> =>
  new Promise<null>((resolve, reject) => {
    axios.post<null>(`/devices/${device_id}/send-notification`, notification)
      .then(() => resolve(null))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const postRemoteControlNotification = (
  device_id: string,
  notification: INotification,
  otp_code: Nullable<string>,
): Promise<null> =>
  new Promise<null>((resolve, reject) => {
    axios.post<null>(`/devices/${device_id}/windows/send-remote-control-notification`, { notification, otp_code })
      .then(() => resolve(null))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getDeviceNetworks = (device_id: string, start_date: string, end_date: string): Promise<NetworkScan[]> =>
  new Promise<NetworkScan[]>((resolve, reject) => {
    axios.get<NetworkScan[]>(`/devices/${device_id}/scans/networks`, { params: { start_date, end_date } })
      .then((res) => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getRecommendations = (device_id: string, date: string): Promise<DeviceRecommendation[]> =>
  new Promise<DeviceRecommendation[]>((resolve, reject) => {
    axios.get<DeviceRecommendation[]>(`/devices/${device_id}/scans/${date}/recommendations`)
      .then((res) => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getAndroidAppsIcons = (device_id: string, date: string): Promise<AndroidAppIcons> =>
  new Promise<AndroidAppIcons>((resolve, reject) => {
    axios.get<AndroidAppIcons>(`/devices/${device_id}/scans/${date}/android/icons`)
      .then((res) => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getRequestedApps = (device_id: string): Promise<AppAnalysis[]> =>
  new Promise<AppAnalysis[]>((resolve, reject) => {
    axios.get<AppAnalysis[]>(`/devices/${device_id}/app-analysis`)
      .then((res) => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getWindowsApps = (device_id: string, date: string): Promise<WindowsApps> =>
  new Promise<WindowsApps>((resolve, reject) => {
    axios.get<WindowsApps>(`/devices/${device_id}/scans/${date}/windows/apps`)
      .then((res) => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

export default {
  getRiskScores,
  getScan,
  getAndroidScan,
  getIosScan,
  getMacosScan,
  getWindowsScan,
  getLinuxScan,
  getScanApps,
  getScanCveIssues,
  getScanDates,
  updateDeviceName,
  updateDeviceStatus,
  postNotificationToDevice,
  getDeviceNetworks,
  getRecommendations,
  getAndroidAppsIcons,
  getRequestedApps,
  getWindowsApps,
  postRemoteControlNotification,
};
