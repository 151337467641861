import React from 'react';
import { VStack } from '@ramp/components';

import {
  AffectedEmail,
  Client,
  DeviceUserEvaluation,
  IssueDetail,
  NetworkDevice,
  NetworkScanWithoutUsers
} from 'types';

import NetworksTable from 'components/tables/NetworksTable';
import CustomersTable from 'components/tables/CustomersTable';
import AffectedEmailsTable from 'components/tables/AffectedEmailsTable';
import DevicesEvaluationTable from 'components/tables/DevicesEvaluationTable';
import NetworkDeviceSnapshotsTable from 'components/tables/NetworkDeviceSnapshotsTable';


interface AffectedEntitiesTabProps {
  issue: IssueDetail,
  simple?: boolean,
}

const AffectedEntitiesTab: React.FC<AffectedEntitiesTabProps> = ({ issue, simple = false }) => {

  if (issue.object_type === 'device') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <DevicesEvaluationTable
          id="affected_devices"
          devices={issue.affected_objects as DeviceUserEvaluation[]}
          defaultPageSize={5}
          simple={simple}
        />
      </VStack>
    );
  }

  if (issue.object_type === 'network') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <NetworksTable
          id="affected_networks"
          networks={issue.affected_objects as NetworkScanWithoutUsers[]}
          withoutUsers
        />
      </VStack>
    );
  }

  if (issue.object_type === 'client') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <CustomersTable
          id="affected_clients"
          defaultCustomers={issue.affected_objects as Client[]}
          filteredCustomers={issue.affected_objects as Client[]}
        />
      </VStack>
    );
  }

  if (issue.object_type === 'network_device') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <NetworkDeviceSnapshotsTable
          id="affected_network_devices"
          deviceSnapshots={(issue.affected_objects as NetworkDevice[])[0].snapshots}
        />
      </VStack>
    );
  }

  if (issue.object_type === 'email') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <AffectedEmailsTable
          id="email_breach_scans_table"
          emails={issue.affected_objects as AffectedEmail[]}
        />
      </VStack>
    );
  }

  return null;
};

export default AffectedEntitiesTab;
