import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridItem, Text, VStack } from '@ramp/components';

import api from 'api';
import { Firm } from 'types/api/firms';
import { notify } from 'utils/notifications';
import { useAuthStore, useLayoutStore } from 'store';
import { APIError, UserDataWithIssues } from 'types';
import { RSelectCountry } from 'components/RSelectCountry';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CountrySettingProps {
  partner?: Firm;
  user?: UserDataWithIssues;
}

const CountrySetting: React.FC<CountrySettingProps> = ({ partner, user }) => {
  const { t } = useTranslation();
  const { entity_id: entityId } = useAuthStore(store => store.user!);
  const theme = useLayoutStore(store => store.theme);

  const [innerCountry, setInnerCountry] = React.useState<string | undefined>(partner?.country || user?.country || '');

  const onSelect = (country?: string) => {
    if (!country) return;

    setInnerCountry(country);

    if (partner && country !== innerCountry) {
      api.firms.updatePartner(entityId!, {
        firm_data: {
          firm_alias: partner.firm_alias,
          contact_email: partner.contact_email,
          contact_name: partner.contact_name,
          country: innerCountry!,
          street: partner.street,
          city: partner.city,
          zip_code: partner.zip_code,
          company_id: partner.company_id,
          vat_id: partner.vat_id,
          state: partner.state,
          theme: theme.theme,
          color_scheme: theme.color_scheme,
        }
      })
        .then(() => {
          notify.success({
            title: 'Country updated',
            description: 'Country was successfully updated.',
          });
        })
        .catch((err: APIError) => {
          setInnerCountry(country);
          notify.error({
            title: 'Country update failed',
            description: err.description,
          });
        });
    }

    if (user && country !== innerCountry) {
      api.users.updateMe({
        country: country || user.country,
        language: user.language,
      })
        .then(() => {
          notify.success({
            title: 'Country updated',
            description: 'Country was successfully updated.',
          });
        })
        .catch((err: APIError) => {
          setInnerCountry(country);
          notify.error({
            title: 'Country update failed',
            description: err.description,
          });
        });
    }
  };

  return (
    <Grid w="full" templateColumns="1fr 1.4fr" gap={14}>
      <GridItem w="100%">
        <VStack w="full" align="start">
          <Text fontWeight={600} fontSize="1.25rem">{t('admin.settings.countryLanguage.country.title')}</Text>
          <Text fontWeight={300} fontSize="1rem">
            {partner && t('admin.settings.countryLanguage.country.description')}
            {user && t('admin.settings.countryLanguage.country.descriptionUser')}
          </Text>
        </VStack>
      </GridItem>
      <GridItem w="100%">
        <VStack w="full" h="62px" align="start" justify="center" spacing={6}>
          <RSelectCountry
            label={t('admin.settings.countryLanguage.country.title')}
            value={innerCountry}
            searchable={true}
            onCountrySelect={country => onSelect(country?.code.iso3)}
            w="320px"
          />
        </VStack>
      </GridItem>
    </Grid>
  );
};

export default CountrySetting;
