import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { MessageCircleQuestion, ChevronDown, CircleHelp } from 'lucide-react';
import {
  HStack,
  Text,
  VStack,
  useColorModeValue,
  Grid,
  Tooltip,
  Tag,
} from '@ramp/components';

import { QuizAnswer, QuizQuestion } from 'types';


interface QuestionDetailProps {
  index: number;
  question: QuizQuestion;
  onCard?: boolean;
  simple?: boolean;
  answers?: QuizAnswer[];
}

const QuestionDetail: React.FC<QuestionDetailProps> = ({
  index,
  question: q,
  onCard = false,
  simple = false,
  answers = [],
}) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const userAnswer: QuizAnswer | undefined = answers.find(
    (a) => a.question_indx === index
  );

  return (
    <VStack w="full" cursor="pointer" spacing={0}>
      <HStack
        w="full"
        py={2}
        px={4}
        borderRadius="md"
        alignItems="center"
        justifyContent="space-between"
        transition="background 0.2s ease-in-out"
        bg={
          isExpanded && !onCard
            ? useColorModeValue('gray.10', 'gray.700')
            : !simple
              ? useColorModeValue('white', 'gray.750')
              : useColorModeValue('white', 'gray.700')
        }
        _hover={
          !onCard
            ? {
              bg: useColorModeValue('gray.10', 'gray.700'),
            }
            : {}
        }
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <HStack spacing={4} alignItems="center">
          {!simple && <MessageCircleQuestion size={16} />}
          <HStack spacing={2}>
            <Text fontWeight={400} color="gray.400" whiteSpace="nowrap">
              {!simple && `${t('admin.quizzes.table.question')} `}
              {index + 1}:
            </Text>
            <Text fontWeight={500}>{q.text}</Text>
          </HStack>
        </HStack>
        <HStack spacing={4} alignItems="center">
          {userAnswer?.spent_time_ms && (
            <Text fontSize="sm" fontWeight={400} color="gray.400">
              {(userAnswer.spent_time_ms / 1000).toFixed(2)}s
            </Text>
          )}
          {answers.length > 0 && (
            <Tag
              colorScheme={userAnswer?.is_answer_correct ? 'success' : 'error'}
            >
              {userAnswer?.is_answer_correct
                ? t('admin.quizzes.table.correctlyAnswered')
                : t('admin.quizzes.table.incorrectlyAnswered')
              }
            </Tag>
          )}
          <ChevronDown
            size={16}
            style={{
              transition: 'transform 0.2s ease-in-out',
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </HStack>
      </HStack>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: 'auto',
              transition: {
                height: {
                  duration: 0.2,
                },
                opacity: {
                  delay: 0.15,
                  duration: 0.1,
                },
              },
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                height: {
                  duration: 0.15,
                  delay: 0.1,
                },
                opacity: {
                  duration: 0.2,
                },
              },
            }}
            style={{ width: '100%' }}
          >
            <VStack
              w="full"
              spacing={4}
              p={4}
              borderBottomRadius="md"
              bg={
                !simple
                  ? useColorModeValue('white', 'gray.750')
                  : useColorModeValue('white', 'gray.700')
              }
            >
              <VStack
                w="full"
                spacing={0}
                alignItems="start"
                justifyContent="start"
              >
                <Text fontSize="sm" fontWeight={400} color="gray.400">
                  {t('admin.quizzes.table.question')}
                </Text>
                <Text fontSize="md" fontWeight={400}>
                  {q.text}
                </Text>
              </VStack>
              <VStack
                w="full"
                spacing={0}
                alignItems="start"
                justifyContent="start"
              >
                <Tooltip
                  label={t('admin.quizzes.table.takeawayDescription')}
                  placement="top"
                >
                  <Text
                    fontSize="sm"
                    fontWeight={400}
                    color="gray.400"
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    {t('admin.quizzes.table.takeaway')}
                    <CircleHelp size={14} />
                  </Text>
                </Tooltip>
                <Text fontSize="md" fontWeight={400}>
                  {q.takeaway}
                </Text>
              </VStack>
              <VStack
                w="full"
                spacing={0}
                alignItems="start"
                justifyContent="start"
              >
                <Text
                  fontSize="sm"
                  fontWeight={400}
                  color="gray.400"
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  {t('admin.quizzes.table.userAnswer')}
                </Text>
                <Text fontSize="md" fontWeight={400}>
                  {q.choices[userAnswer?.choice_indx || 0].text}
                </Text>
              </VStack>
              <VStack
                w="full"
                spacing={1}
                alignItems="start"
                justifyContent="start"
              >
                <Text fontSize="sm" fontWeight={400} color="gray.400">
                  {t('admin.quizzes.table.answers')}
                </Text>
                <Grid
                  templateColumns={simple ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'}
                  gap={1}
                  w="full"
                >
                  {q.choices
                    .map((value) => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value: choice }, idx) => (
                      <HStack
                        key={idx}
                        py={2}
                        px={2}
                        borderRadius="md"
                        bg={
                          !simple
                            ? useColorModeValue('gray.50', 'gray.800')
                            : useColorModeValue('gray.10', 'gray.750')
                        }
                      >
                        <HStack
                          bg={useColorModeValue('gray.10', 'gray.700')}
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="md"
                          w={6}
                          h={6}
                        >
                          <Text
                            fontSize="sm"
                            fontWeight={500}
                            color={
                              choice.is_correct ? 'green.500' : 'error.500'
                            }
                            position="relative"
                            top="1px"
                            left="0.5px"
                          >
                            {String.fromCharCode(65 + idx)}
                          </Text>
                        </HStack>
                        <Text fontSize="sm" fontWeight={400}>
                          {choice.text}
                        </Text>
                      </HStack>
                    ))}
                </Grid>
              </VStack>
            </VStack>
          </motion.div>
        )}
      </AnimatePresence>
    </VStack>
  );
};

export default QuestionDetail;
