import React from 'react';
import { Tag } from '@ramp/components';
import { useTranslation } from 'react-i18next';
import { Building, HelpCircle, Home, Warehouse } from 'lucide-react';

import { ClientNetworkType } from 'types';


interface ClientNetworkTagProps {
  type: ClientNetworkType;
}

const ClientNetworkTag: React.FC<ClientNetworkTagProps> = ({ type }) => {
  const { t } = useTranslation();

  if (!type) return <Tag colorScheme="error">{t('components.networkType.unknown')}</Tag>;

  if (type === 'office_network') {
    return (
      <Tag colorScheme="success" whiteSpace="nowrap">
        <Building size="16px" />
        &nbsp;
        {t('components.networkType.office')}
      </Tag>
    );
  }

  if (type === 'remote_office_network') {
    return (
      <Tag colorScheme="blue" whiteSpace="nowrap">
        <Warehouse size="16px" />
        &nbsp;
        {t('components.networkType.remoteOffice')}
      </Tag>
    );
  }

  if (type === 'home_office_network') {
    return (
      <Tag colorScheme="warning" whiteSpace="nowrap">
        <Home size="16px" />
        &nbsp;
        {t('components.networkType.homeOffice')}
      </Tag>
    );
  }

  if (type === 'third_party_network') {
    return (
      <Tag colorScheme="error" whiteSpace="nowrap">
        <HelpCircle size="16px" />
        &nbsp;
        {t('components.networkType.thirdParty')}
      </Tag>
    );
  }

  return <Tag colorScheme="unknown">{t('components.networkType.unknown')}</Tag>;
};

export default ClientNetworkTag;
