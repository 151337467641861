import React from 'react';
import { colorHex } from '@ramp/theme';
import { useTranslation } from 'react-i18next';
import { Box, HStack, StyleProps, Text, useColorModeValue, VStack } from '@ramp/components';

import Logo from '../Logo';


type BasePageLeftPanelProps = StyleProps;

const BasePageLeftPanel: React.FC<BasePageLeftPanelProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <VStack
      h="calc(100vh - 64px)"
      minH="580px"
      w={props.w || props.width}
      display={['none', 'none', 'flex', 'flex', 'flex']}
      p="26px"
      alignItems="start"
      borderRadius="16px"
      position="relative"
      bg={`linear-gradient(0deg, ${colorHex('brand.500')} -17.58%, rgba(214, 9, 40, 0) 96.18%)`}
      {...props}
    >
      <Logo type="partner" clickable />
      <VStack position="absolute" top="50%" transform="translate(0, -50%)" spacing="16px">
        <Text
          as="h1"
          w={['218px', '218px', '192px', '218px', '260px']}
          fontSize={['360px', '360px', '42px', '47px', '56px']}
          lineHeight="100%"
          fontWeight={600}
        >
          {t('base.signIn.leftPanel.title')}
        </Text>
        <Text
          w={['218px', '218px', '192px', '218px', '260px']}
          fontSize={['16px', '16px', '16px', '18px', '20px']}
          fontWeight={300}
          lineHeight="100%"
        >
          {t('base.signIn.leftPanel.description')}
        </Text>
      </VStack>
      <HStack
        w="fit-content"
        h={['54px', '54px', '54px', '54px', '54px']}
        px="26px"
        py="14px"
        spacing="16px"
        borderRadius="12px"
        position="absolute"
        bottom="0"
        left="50%"
        transform="translate(-50%, -32px)"
        background={useColorModeValue('rgba(231, 231, 231, 0.65)', 'rgba(30, 30, 30, 0.65)')}
      >
        {/*// @ts-ignore */}
        <Text style={{ textWrap: 'nowrap', inlineSize: 'max-content' }}>{t('base.signIn.leftPanel.builtBy')}</Text>
        <Box w={['90px', '90px', '90px', '90px', '90px']}>
          <Logo type="redamp" style={{ width: 'inherit' }} />
        </Box>
      </HStack>
    </VStack>
  );
};

export default BasePageLeftPanel;
