import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { object, SchemaOf, string } from 'yup';
import { FormikErrors, useFormik } from 'formik';
import { useLocation, Link, Navigate } from 'react-router-dom';
import { Button, Divider, RInput, Text, ThemeToggle, useColorModeValue, VStack } from '@ramp/components';

import api from 'api';
import { APIError } from 'types';
import Logo from 'components/Logo';
import ROUTES from 'router/routes';
import { Key } from 'lucide-react';
import { REGEX } from 'utils/utils';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResetPasswordFormProps {}

interface ISetPassword {
  password: string,
  passwordAgain: string,
}

const SetupPassword: React.FC<ResetPasswordFormProps> = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  if (!token) return <Navigate to={ROUTES.BASE.SIGN_IN} />;

  const [apiError, setApiError] = useState<string>();
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [disableUpdate, setDisableUpdate] = useState<boolean>(true);

  const PasswordValidationSchema: SchemaOf<ISetPassword> = object().shape({
    password: string()
      .min(10, t('base.resetPassword.validation.minChar'))
      .matches(REGEX.LOWERCASE_LETTER, t('base.resetPassword.validation.oneLower'))
      .matches(REGEX.UPPERCASE_LETTER, t('base.resetPassword.validation.oneUpper'))
      .matches(REGEX.NUMBER, t('base.resetPassword.validation.oneNumber'))
      .matches(REGEX.SPECIAL_LETTER, t('base.resetPassword.validation.oneSpecial'))
      .required(t('base.resetPassword.validation.passwordRequired')),
    passwordAgain: string().required(t('base.resetPassword.validation.passwordAgainRequired')),
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      password: '',
      passwordAgain: '',
    },
    validationSchema: PasswordValidationSchema,
    validate: ({ password, passwordAgain }) => {
      const validationErrors: FormikErrors<ISetPassword> = {};

      if (password.length > 0 && passwordAgain.length > 0) {
        if (password !== passwordAgain) {
          validationErrors.passwordAgain = t('base.resetPassword.validation.passwordNotSame');
        }
      }

      if (apiError) {
        validationErrors.passwordAgain = apiError;
      }

      return validationErrors;
    },
    onSubmit: ({ password }) => {
      setApiError(undefined);
      setLoadingUpdate(true);

      api.users.setPassword(token, password)
        .then(() => {
          setLoadingUpdate(false);
          setDisableUpdate(true);
          setApiSuccess(true);
        })
        .catch((err: APIError) => {
          setLoadingUpdate(false);
          setDisableUpdate(true);
          setApiError(err.description);
        });
    },
  });

  useEffect(() => {
    const isPasswordEmpty = values.password.length === 0 || values.passwordAgain.length === 0;
    const isPasswordError = errors.password || errors.passwordAgain;

    if (isPasswordEmpty || isPasswordError) {
      setDisableUpdate(true);
    } else {
      setDisableUpdate(false);
    }
  }, [
    values.password, values.passwordAgain, errors.password, errors.passwordAgain,
  ]);

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      style={{ position: 'relative', zIndex: 10 }}
    >
      <VStack
        p={['16px', '32px']}
        w={['320px', '440px', '380px', '440px', '440px']}
        pos="relative"
        zIndex={10}
        alignItems="center"
        borderRadius="16px"
        spacing="16px"
        bg={useColorModeValue('white', 'gray.800')}
      >
        <Logo type="partner" style={{ marginBottom: '1rem', marginTop: '0.5rem' }} clickable  />
        <Text fontSize="1.5rem" fontWeight={500}>{t('base.firmAdminVerification.title')}</Text>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <VStack w="full" spacing="16px">
            <RInput
              h="56px"
              w="full"
              id="password"
              name="password"
              type="password"
              label={t('base.signIn.loginForm.password')}
              size="lg"
              data-test="signin-password"
              icon={<Key width="24px" height="24px"/>}
              value={values.password}
              onChange={handleChange}
              isError={!!(errors.password)}
              error={errors.password}
              bg={useColorModeValue('gray.10', 'gray.700')}
              onCard
            />
            <RInput
              h="56px"
              w="full"
              size="lg"
              id="passwordAgain"
              name="passwordAgain"
              type="password"
              label={t('base.resetPassword.passwordConfirmation')}
              icon={<Key width="24px" height="24px" />}
              value={values.passwordAgain}
              onChange={handleChange}
              isError={!!(errors.passwordAgain)}
              error={errors.passwordAgain}
              bg={useColorModeValue('gray.10', 'gray.700')}
              onCard
            />
            {apiError && (
              <VStack
                w="full"
                align="center"
                p={['8px', '12px']}
                bg={useColorModeValue('gray.10', 'gray.750')}
                borderRadius="8px"
              >
                <Text fontWeight={600} fontSize="1rem" textAlign="center" color="brand.500" mt="0 !important">
                  {apiError}
                </Text>
              </VStack>
            )}
            {apiSuccess && (
              <VStack
                w="full"
                align="center"
                p={['8px', '12px']}
                bg={useColorModeValue('gray.10', 'gray.750')}
                borderRadius="8px"
              >
                <Text fontWeight={600} fontSize="1rem" color="green.500" mt="0 !important">
                  {t('base.firmAdminVerification.successMessage')}
                </Text>
              </VStack>
            )}
            {!apiSuccess
              ? (
                <Button
                  h="48px"
                  w="full"
                  variant="brand"
                  fontSize="18px"
                  type="submit"
                  mt="2rem !important"
                  disabled={disableUpdate}
                  isLoading={loadingUpdate}
                >
                  {t('base.firmAdminVerification.setupButton')}
                </Button>
              )
              : (
                <Link to={ROUTES.BASE.SIGN_IN} style={{ width: '100%' }}>
                  <Button
                    h="48px"
                    w="full"
                    variant="solid"
                    fontSize="18px"
                  >
                    {t('base.resetPassword.backToSignIn')}
                  </Button>
                </Link>
              )
            }
          </VStack>
        </form>
        <Divider borderColor="gray.600" />
        <ThemeToggle />
      </VStack>
    </motion.div>
  );
};

export default SetupPassword;
