import React, { useMemo, useState } from 'react';
import { Key } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { object, SchemaOf, string } from 'yup';
import { FormikErrors, useFormik } from 'formik';
import { useLocation, Link, Navigate } from 'react-router-dom';
import { Button, Divider, HStack, RInput, Text, ThemeToggle, useColorModeValue, VStack } from '@ramp/components';

import api from 'api';
import { APIError } from 'types';
import Logo from 'components/Logo';
import ROUTES from 'router/routes';
import PasswordCheck from 'components/PasswordCheck';
import { REGEX, translateFormError } from 'utils/utils';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResetPasswordFormProps {}

interface ISetPassword {
  password: string,
  passwordAgain: string,
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  if (!token) return <Navigate to={ROUTES.BASE.SIGN_IN} />;

  const [apiError, setApiError] = useState<string>();
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  const PasswordValidationSchema: SchemaOf<ISetPassword> = object().shape({
    password: string()
      .min(10, translateFormError(t, 'min', { field: t('base.signUp.fourthSlide.password'), num: 10 }))
      .matches(
        REGEX.LOWERCASE_LETTER,
        translateFormError(t, 'lowercase', { field: t('base.signUp.fourthSlide.password'), num: 1 })
      )
      .matches(
        REGEX.UPPERCASE_LETTER,
        translateFormError(t, 'uppercase', { field: t('base.signUp.fourthSlide.password'), num: 1 })
      )
      .matches(REGEX.NUMBER, translateFormError(t, 'number', { field: t('base.signUp.fourthSlide.password'), num: 1 }))
      .matches(
        REGEX.SPECIAL_LETTER,
        translateFormError(t, 'special', { field: t('base.signUp.fourthSlide.password'), num: 1 })
      )
      .required(translateFormError(t, 'required', { field: t('base.signUp.fourthSlide.password') })),
    passwordAgain: string()
      .required(translateFormError(t, 'required', { field: t('base.signUp.fourthSlide.confirmPassword') })),
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      password: '',
      passwordAgain: '',
    },
    validationSchema: PasswordValidationSchema,
    validate: ({ password, passwordAgain }) => {
      const validationErrors: FormikErrors<ISetPassword> = {};

      if (password.length > 0 && passwordAgain.length > 0) {
        if (password !== passwordAgain) {
          validationErrors.passwordAgain = t('base.resetPassword.validation.passwordNotSame');
        }
      }

      if (apiError) {
        validationErrors.passwordAgain = apiError;
      }

      return validationErrors;
    },
    onSubmit: ({ password }) => {
      setApiError(undefined);
      setLoadingUpdate(true);

      api.users.setPassword(token, password)
        .then(() => {
          setLoadingUpdate(false);
          setApiSuccess(true);
        })
        .catch((err: APIError) => {
          setLoadingUpdate(false);
          setApiError(err.description);
        });
    },
  });

  const passwordChecks = useMemo(() => {
    const { password, passwordAgain } = values;

    return {
      length: password.length >= 10,
      lowercase: REGEX.LOWERCASE_LETTER.test(password),
      uppercase: REGEX.UPPERCASE_LETTER.test(password),
      number: REGEX.NUMBER.test(password),
      special: REGEX.SPECIAL_LETTER.test(password),
      passwordsMatch: (password.length > 0 && passwordAgain.length > 0) ? password === passwordAgain : false,
    };
  }, [values.password, values.passwordAgain]);

  const isResetDisabled = useMemo(() => {
    return Object.values(passwordChecks).some((check) => !check);
  }, [passwordChecks]);

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      style={{ position: 'relative', zIndex: 10 }}
    >
      <VStack
        p={['16px', '32px']}
        w={['320px', '440px', '380px', '440px', '440px']}
        pos="relative"
        zIndex={10}
        alignItems="center"
        borderRadius="16px"
        spacing="16px"
        bg={useColorModeValue('white', 'gray.800')}
      >
        <Logo type="partner" style={{ marginBottom: '1rem', marginTop: '0.5rem' }} clickable />
        <Text fontSize="1.5rem" fontWeight={500}>{t('base.resetPassword.title')}</Text>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <VStack w="full" spacing="16px">
            <RInput
              w="full"
              size="lg"
              id="password"
              name="password"
              type="password"
              label={t('base.resetPassword.newPassword')}
              icon={<Key width="24px" height="24px" />}
              value={values.password}
              onChange={handleChange}
              isError={!!(errors.password)}
              error={errors.password}
              bg={useColorModeValue('gray.10', 'gray.750')}
            />
            <RInput
              w="full"
              size="lg"
              id="passwordAgain"
              name="passwordAgain"
              type="password"
              label={t('base.resetPassword.passwordConfirmation')}
              icon={<Key width="24px" height="24px" />}
              value={values.passwordAgain}
              onChange={handleChange}
              isError={!!(errors.passwordAgain)}
              error={errors.passwordAgain}
              bg={useColorModeValue('gray.10', 'gray.750')}
            />
            {apiError && (
              <VStack
                w="full"
                align="center"
                p={['8px', '12px']}
                bg={useColorModeValue('gray.10', 'gray.750')}
                borderRadius="8px"
              >
                <Text fontWeight={600} fontSize="1rem" textAlign="center" color="brand.500" mt="0 !important">
                  {apiError}
                </Text>
              </VStack>
            )}

            <HStack w="full" flexWrap="wrap" mt="0rem" spacing="0.5rem" alignItems="center" justifyContent="center">
              <PasswordCheck
                name={t('components.passwordCheck.min', { num: 10 })}
                checked={passwordChecks.length}
              />
              <PasswordCheck
                name={t('components.passwordCheck.lowercase', { num: 1 })}
                checked={passwordChecks.lowercase}
              />
              <PasswordCheck
                name={t('components.passwordCheck.uppercase', { num: 1 })}
                checked={passwordChecks.uppercase}
              />
              <PasswordCheck
                name={t('components.passwordCheck.number', { num: 1 })}
                checked={passwordChecks.number}
              />
              <PasswordCheck
                name={t('components.passwordCheck.special', { num: 1 })}
                checked={passwordChecks.special}
              />
              <PasswordCheck
                name={t('components.passwordCheck.match')}
                checked={passwordChecks.passwordsMatch}
              />
            </HStack>

            {apiSuccess && (
              <VStack
                w="full"
                align="center"
                p={['8px', '12px']}
                bg={useColorModeValue('gray.10', 'gray.750')}
                borderRadius="8px"
              >
                <Text fontWeight={600} fontSize="1rem" color="green.500" mt="0 !important">
                  {t('base.resetPassword.successMessage')}
                </Text>
              </VStack>
            )}

            {!apiSuccess
              ? (
                <Button
                  h="48px"
                  w="full"
                  variant="brand"
                  fontSize="18px"
                  type="submit"
                  mt="1rem !important"
                  isDisabled={isResetDisabled}
                  isLoading={loadingUpdate}
                >
                  {t('base.resetPassword.resetButton')}
                </Button>
              )
              : (
                <Link to={ROUTES.BASE.SIGN_IN} style={{ width: '100%' }}>
                  <Button
                    h="48px"
                    w="full"
                    variant="solid"
                    fontSize="18px"
                  >
                    {t('base.resetPassword.backToSignIn')}
                  </Button>
                </Link>
              )
            }
          </VStack>
        </form>
        <Divider borderColor="gray.600" />
        <ThemeToggle />
      </VStack>
    </motion.div>
  );
};

export default ResetPasswordForm;
