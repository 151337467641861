import React, { useMemo } from 'react';
import { GeneralRecommendation } from 'types';
import { useTranslation } from 'react-i18next';
import {
  ColumnsType,
  HStack,
  RTable,
  SeverityNumTag,
  Tag,
  Text,
  VStack,
  useColorModeValue,
} from '@ramp/components';

import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import TextTruncateTooltip from 'components/TextTruncateTooltip';
import { usePlatformIcon } from 'utils/utils';
import { serializePlatform } from 'utils/serializators';


interface RecommendationsTableProps {
  id: string;
  loading?: boolean;
  defaultPageSize?: number;
  recommendations: GeneralRecommendation[];
}

interface RecommendationRow extends GeneralRecommendation {
  key: number | string;
}

const RecommendationsTable: React.FC<RecommendationsTableProps> = ({
  id,
  defaultPageSize = 50,
  recommendations,
  loading,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const formattedRecommendations: RecommendationRow[] = useMemo(() => {
    return recommendations.map((rec, key) => ({ key, ...rec }));
  }, [recommendations]);

  const columns: ColumnsType<RecommendationRow> = useMemo(
    () => [
      {
        key: 'type',
        title: t('admin.devices.detail.network.type'),
        sorting: true,
        sortingOptions: {
          dataType: 'string',
        },
        render: (_, { type }) => {
          switch (type) {
            case 'do':
              return (
                <Tag colorScheme="success">
                  {t('admin.recommendations.filter.type.do').toUpperCase()}
                </Tag>
              );
            case 'consider_doing':
              return (
                <Tag colorScheme="warning">
                  {t(
                    'admin.recommendations.filter.type.considerDoing'
                  ).toUpperCase()}
                </Tag>
              );
            case 'avoid':
              return (
                <Tag colorScheme="error">
                  {t('admin.recommendations.filter.type.avoid').toUpperCase()}
                </Tag>
              );
            default:
              return <Tag colorScheme="unknonwn">{type}</Tag>;
          }
        },
      },
      {
        key: 'importance',
        title: t('admin.recommendations.filter.importance'),
        sorting: true,
        sortingOptions: {
          dataType: 'number',
          sortingFn: ({ original: a }, { original: b }) =>
            (a.importness || 0) - (b.importness || 0),
          defaultSortOrder: 'desc',
        },
        render: (_, { importness }) => (
          <HStack justify="center" spacing={0}>
            <SeverityNumTag severityScore={importness} />
            {importness !== undefined && (
              <Text pos="relative" top="0.5rem" left="0.2rem">
                /10
              </Text>
            )}
          </HStack>
        ),
      },
      {
        key: 'title',
        title: t('components.table.basicIssues.title'),
        render: (_, { title }) => <Text fontWeight={500}>{title}</Text>,
      },
      {
        key: 'description',
        title: t('components.table.basicIssues.description'),
        width: '50%',
        render: (_, { description }) => (
          <TextTruncateTooltip
            text={description || ''}
            maxNumOfChars={240}
            noOfLines={2}
          />
        ),
      },
    ],
    []
  );

  return (
    <RTable<RecommendationRow>
      data={formattedRecommendations}
      columns={columns}
      loading={loading}
      expandable={{
        rowExpandable: () => true,
        render: (recommendation) => (
          <HStack w="full" py={4} pr={8} align="start" spacing={4}>
            <VStack
              w="33%"
              align="start"
              spacing={4}
              p={4}
              bg={useColorModeValue('gray.25', 'gray.750')}
              borderRadius="md"
            >
              <VStack w="full" spacing={0} align="start">
                <Text fontSize="sm" color="gray.400">
                  {t('components.table.basicIssues.title')}
                </Text>
                <Text fontSize="md" fontWeight={500}>
                  {recommendation.title}
                </Text>
              </VStack>
              <VStack w="full" spacing={0} align="start">
                <Text fontSize="sm" color="gray.400">
                  {t('components.table.basicIssues.detailedDescription')}
                </Text>
                <Text fontSize="md" fontWeight={500}>
                  {recommendation.description}
                </Text>
              </VStack>
            </VStack>
            <VStack
              w="66%"
              align="start"
              spacing={4}
              p={4}
              bg={useColorModeValue('gray.25', 'gray.750')}
              borderRadius="md"
            >
              <VStack w="full" spacing={0} align="start">
                <Text fontSize="sm" color="gray.400">
                  {t('admin.recommendations.filter.importance')}
                </Text>
                <HStack justify="center" spacing={0}>
                  <SeverityNumTag severityScore={recommendation.importness} />
                  {recommendation.importness !== undefined && (
                    <Text pos="relative" top="8px" left="4px" color="gray.400">
                      /10
                    </Text>
                  )}
                </HStack>
              </VStack>
              <VStack w="full" spacing={0} align="start">
                <Text fontSize="sm" color="gray.400">
                  {t('components.table.advancedIssues.affectedPlatforms')}
                </Text>
                <HStack>
                  {recommendation.platform.map((platform) => (
                    <Tag key={platform}>
                      <span
                        style={{
                          position: 'relative',
                          top: '-1px',
                          marginRight: '4px',
                        }}
                      >
                        {usePlatformIcon(platform)}
                      </span>
                      <span style={{ position: 'relative', top: '2px' }}>
                        {serializePlatform(platform)}
                      </span>
                    </Tag>
                  ))}
                </HStack>
              </VStack>
            </VStack>
          </HStack>
        ),
      }}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      interleaveRows
    />
  );
};

export default RecommendationsTable;
