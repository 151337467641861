import React, { useMemo } from 'react';
import { FiGlobe } from 'react-icons/fi';
import { RiAndroidLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { VscTerminalLinux } from 'react-icons/vsc';
import { AiOutlineApple, AiOutlineWindows } from 'react-icons/ai';
import { DeviceUserEvaluation, GeneralRecommendation, Platforms } from 'types';
import { Box, FilterBar, IFilter, RRangeSlider, RSelect, RSelectOption, StyleProps, VStack } from '@ramp/components';
import { Tag } from 'lucide-react';


interface RecommendationsFilterProps extends StyleProps {
  recommendations?: GeneralRecommendation[];
  setRecommendations: React.Dispatch<React.SetStateAction<GeneralRecommendation[]>>;
}

const RecommendationsFilter: React.FC<RecommendationsFilterProps> = ({
  recommendations = [],
  setRecommendations,
  ...props
}) => {
  const { t } = useTranslation();

  const filters: IFilter[] = useMemo(() => [
    {
      id: 'platform',
      type: 'select',
      label: t('components.table.deviceEvaluation.platform'),
      icon: FiGlobe,
      disabled: () => false,
      active: () => true,
      items: [
        { icon: RiAndroidLine, value: 'android', content: 'Android' },
        { icon: AiOutlineApple, value: 'ios', content: 'iOS' },
        { icon: AiOutlineApple, value: 'ipados', content: 'iPadOS' },
        { icon: AiOutlineWindows, value: 'windows', content: 'Windows' },
        { icon: AiOutlineApple, value: 'macos', content: 'macOS' },
        { icon: VscTerminalLinux, value: 'linux', content: 'Linux' }
      ],
      onFilter: (data: GeneralRecommendation[], value: Platforms) => data.filter((it) => it.platform.includes(value)),
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          value={filter.selected ? filter.selected.value : undefined}
          onChange={newValue => onValueChange(filter.id, newValue)}
          placeholder={t('admin.dashboard.issues.filter.platformSelect')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items && filter.items.map(item => (
            <RSelectOption
              key={item.value}
              value={item.value}
              icon={<item.icon/>}
            >
              {item.content}
            </RSelectOption>
          ))}
        </RSelect>
      )
    },
    {
      id: 'type',
      type: 'select',
      label: t('admin.devices.detail.network.type'),
      icon: () => <Tag size={16} />,
      disabled: () => false,
      active: () => true,
      items: [
        {
          icon: () => <Tag size={14} />,
          value: 'do',
          content: t('admin.recommendations.filter.type.do'),
        },
        {
          icon: () => <Tag size={14} />,
          value: 'consider_doing',
          content: t('admin.recommendations.filter.type.considerDoing'),
        },
        {
          icon: () => <Tag size={14} />,
          value: 'avoid',
          content: t('admin.recommendations.filter.type.avoid'),
        },
      ],
      onFilter: (data: GeneralRecommendation[], value: string) => data.filter((it) => it.type === value),
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          value={filter.selected ? filter.selected.value : undefined}
          onChange={newValue => onValueChange(filter.id, newValue)}
          placeholder={t('admin.dashboard.issues.filter.objectTypeSelect')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items && filter.items.map(item => (
            <RSelectOption
              key={item.value}
              value={item.value}
              icon={<item.icon/>}
            >
              {item.content}
            </RSelectOption>
          ))}
        </RSelect>
      ),
    },
    {
      id: 'importance',
      type: 'slider',
      label: t('admin.recommendations.filter.importance'),
      icon: FiGlobe,
      disabled: () => false,
      active: () => false,
      onFilter: (data: DeviceUserEvaluation[], value) => {
        return data.filter(device => device.device_score || 0 >= value.min && device.device_score || 0 <= value.max);
      },
      render: (filter, _, onValueChange) => (
        <VStack w="full" h="full" alignItems="center" justifyContent="flex-start">
          <RRangeSlider
            minValue={1}
            maxValue={10}
            width="200px"
            marks={[
              { value: 1, label: '1' },
              { value: 3, label: '3' },
              { value: 8, label: '8' },
              { value: 10, label: '10' },
            ]}
            onCommit={newValue => onValueChange(filter.id, newValue)}
            values={filter.selected ? { min: filter.selected.min, max: filter.selected.max } : { min: 0, max: 10 }}
          />
        </VStack>
      ),
    }
  ], []);

  return (
    <Box w="full" {...props}>
      <FilterBar<GeneralRecommendation>
        tableId="recommendations_table"
        filters={filters}
        data={recommendations}
        setData={setRecommendations}
      />
    </Box>
  );
};

export default RecommendationsFilter;
