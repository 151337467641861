import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { RCard, RPage, VStack } from '@ramp/components';

import api from 'api';
import { PRODUCT_NAME } from 'utils/constants';
import NoDataPage from 'components/NoDataPage';
import UserInfoCard from 'components/UserInfoCard';
import { useAuthStore, useLayoutStore } from 'store';
import UserRiskScoreCard from 'components/UserRiskScoreCard';
import BasicIssuesTable from 'components/tables/BasicIssuesTable';

import DevicesCard from './DevicesCard';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const { t } = useTranslation();
  const { user_id: id } = useAuthStore(store => store.user!);
  const { noUsersInCompany, noScansInCompany, setNoScansInCompany } = useLayoutStore(store => store);

  const user = useQuery(
    ['users', id],
    () => api.users.getUser(id),
    {
      cacheTime: 0,
      onSuccess: data => setNoScansInCompany(data.devices.length === 0)
    },
  );

  if (noScansInCompany) {
    return (
      <NoDataPage
        heading={t('admin.dashboard.heading')}
        pageLoading={user.isLoading}
        noUsersInCompany={noUsersInCompany}
        noScansInCompany={noScansInCompany}
      />
    );
  }

  return (
    <RPage
      px={6}
      pt={8}
      loading={user.isLoading}
      heading={t('admin.dashboard.heading')}
      title={t('admin.dashboard.title', { productName: PRODUCT_NAME })}
    >
      <VStack w="full" spacing={2}>
        <UserInfoCard user={user.data} />

        <UserRiskScoreCard title={t('admin.dashboard.riskScore.title')} userId={id} />

        <RCard w="full" title={t('admin.dashboard.issues.title')} titleOutside={false}>
          <BasicIssuesTable
            id="user_basic_issues"
            issues={user.data?.issues}
            defaultPageSize={10}
            userView
            userId={id}
          />
        </RCard>

        <DevicesCard devices={user.data?.devices} />
      </VStack>
    </RPage>
  );
};

export default Dashboard;
