import React from 'react';
import { Flag } from 'lucide-react';
import countries from 'country-list-js';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { RSelectOptionV2, RSelectV2, RSelectV2Props } from '@ramp/components';

import CountryFlag from './CountryFlag';


export interface Country {
  capital: string;
  code: {
    iso2: string;
    iso3: string;
  };
  continent: string;
  currency: {
    code: string;
    symbol: string;
    decimal: string;
  };
  dialing_code: string;
  name: string;
  provinces: string[];
  region: string;
}

export type RSelectCountryProps = Omit<RSelectV2Props, 'children'> & { onCountrySelect?: (country?: Country) => any };

export const getCountryFlagIcon = (countryCodeIso2: string, style?: React.CSSProperties) => (
  <CountryFlag countryCode={countryCodeIso2} style={style} />
);

export const RSelectCountry: React.FC<RSelectCountryProps> = ({ onCountrySelect, ...props }) => {
  const { t } = useTranslation();

  const COUNTRIES = countries.names()
    .map(countryName => countries.findByName(countryName))
    .sort((a, b) => a.name.localeCompare(b.name));

  const CZ = COUNTRIES.find(c => c.code.iso3 === 'CZE');
  const DE = COUNTRIES.find(c => c.code.iso3 === 'DEU');
  const PL = COUNTRIES.find(c => c.code.iso3 === 'POL');
  const SK = COUNTRIES.find(c => c.code.iso3 === 'SVK');
  const AT = COUNTRIES.find(c => c.code.iso3 === 'AUT');

  const FINAL_COUNTRIES = [...COUNTRIES.filter(c => ![CZ, DE, PL, SK, AT].includes(c))];

  const onSelect = (country?: string | number) => {
    const selectedCountry = COUNTRIES.find(c => c.code.iso3 === country as string);

    if (onCountrySelect && selectedCountry) {
      onCountrySelect(selectedCountry);
    }
  };

  const COUNTRY_ICON_STYLE = {
    width: { lg: '22px', md: '18px', sm: '14px' }[props.size || 'md'],
    height: { lg: '22px', md: '18px', sm: '14px' }[props.size || 'md'],
    lineHeight: { lg: '22px', md: '18px', sm: '14px' }[props.size || 'md'],
    fontSize: { lg: '22px', md: '18px', sm: '14px' }[props.size || 'md'],
  };

  return (
    <RSelectV2
      w="full"
      id="country"
      name="country"
      label={t('admin.settings.countryLanguage.country.title')}
      value={props.value}
      isError={props.isError}
      error={props.error}
      size="lg"
      icon={<Flag />}
      searchable={false}
      onChange={onSelect}
      required={props.required}
      mobileFriendly={isMobile}
      {...props}
    >
      <RSelectOptionV2
        key={AT.code.iso3}
        value={AT.code.iso3}
        icon={getCountryFlagIcon(AT.code.iso2, COUNTRY_ICON_STYLE)}
      >
        {AT.name}
      </RSelectOptionV2>
      <RSelectOptionV2
        key={CZ.code.iso3}
        value={CZ.code.iso3}
        icon={getCountryFlagIcon(CZ.code.iso2, COUNTRY_ICON_STYLE)}
      >
        {CZ.name}
      </RSelectOptionV2>
      <RSelectOptionV2
        key={SK.code.iso3}
        value={SK.code.iso3}
        icon={getCountryFlagIcon(SK.code.iso2, COUNTRY_ICON_STYLE)}
      >
        {SK.name}
      </RSelectOptionV2>
      <RSelectOptionV2
        key={DE.code.iso3}
        value={DE.code.iso3}
        icon={getCountryFlagIcon(DE.code.iso2, COUNTRY_ICON_STYLE)}
      >
        {DE.name}
      </RSelectOptionV2>
      <RSelectOptionV2
        key={PL.code.iso3}
        value={PL.code.iso3}
        icon={getCountryFlagIcon(PL.code.iso2, COUNTRY_ICON_STYLE)}
      >
        {PL.name}
      </RSelectOptionV2>

      {FINAL_COUNTRIES.map(country => (
        <RSelectOptionV2
          key={country.code.iso3}
          icon={getCountryFlagIcon(country.code.iso2, COUNTRY_ICON_STYLE)}
          value={country.code.iso3}
        >
          {country.name}
        </RSelectOptionV2>
      ))}
    </RSelectV2>
  );
};
