import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Heading, HStack, RCard, Search, useColorModeValue, VStack } from '@ramp/components';

import { AppInfo } from 'types';
import UnknownSourceAppsTable from 'components/tables/UnknownSourceAppsTable';


interface InVerificationAppsProps {
  filteredApps: AppInfo[];
}

const InVerificationApps: React.FC<InVerificationAppsProps> = ({ filteredApps }) => {
  const { t } = useTranslation();
  const inVerificationApps = filteredApps.filter(app => app.eval_category === 'in_verification');

  const [searchInVerification, setSearchInVerification] = React.useState('');
  const [filteredVerification, setFilteredVerification] = React.useState(inVerificationApps);

  useEffect(() => {
    const apps = filteredApps.filter(app => app.eval_category === 'in_verification');
    const filteredRiskyApps = apps.filter(app => {
      const appName = (app.application_name || app.package_name).toLowerCase();
      return appName.includes(searchInVerification.toLowerCase());
    });

    setFilteredVerification(filteredRiskyApps);
  }, [filteredApps, searchInVerification]);

  return (
    <VStack w="full" spacing={3}>
      <HStack w="full" justify="space-between" align="end">
        <Element name="in_verification_apps">
          <Heading
            mt="2rem !important"
            mb="0.25rem !important"
            color={useColorModeValue('black', 'gray.400')}
            fontSize="1.5rem"
            fontWeight={600}
          >
            {t('admin.devices.detail.apps.inVerification.title')}
          </Heading>
        </Element>
        <Search
          w="360px"
          size="sm"
          value={searchInVerification}
          // eslint-disable-next-line max-len
          placeholder={`${t('admin.devices.detail.apps.risky.searchIn')} '${t('admin.devices.detail.apps.inVerification.title')}'`}
          onChange={e => setSearchInVerification(e.target.value)}
        />
      </HStack>

      <RCard w="full">
        <VStack w="full" spacing={4}>
          <UnknownSourceAppsTable id="device_detail_verification_apps" apps={filteredVerification} />
        </VStack>
      </RCard>
    </VStack>
  );
};

export default InVerificationApps;
