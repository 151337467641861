import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, RTab, RTabList, RTabPanel, RTabPanels, RTabs } from '@ramp/components';

import { NetworkScan } from 'types';
import NetworkDetail from 'pages/admin/devices/detail/Evaluation/NetworksTab/NetworkDetail';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NetworkEvaluationProps {
  network?: NetworkScan;
}

const NetworkEvaluation: React.FC<NetworkEvaluationProps> = ({ network }) => {
  if (!network) return null;

  const { t } = useTranslation();

  return (
    <Box w="full" mt="2rem !important">
      <RTabs isLazy>
        <RTabList mx="auto">
          <RTab key="overview">{t('admin.networks.detail.overview')}</RTab>
          {/*<RTab key="devices" whiteSpace="nowrap">{t('admin.networks.detail.devices')}</RTab>*/}
        </RTabList>
        <RTabPanels mt={4}>
          <RTabPanel key="overview">
            <NetworkDetail network={network} />
          </RTabPanel>
          {/*<RTabPanel key="devices">*/}
          {/*  <DevicesTab />*/}
          {/*</RTabPanel>*/}
        </RTabPanels>
      </RTabs>
    </Box>
  );
};

export default NetworkEvaluation;
