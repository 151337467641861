import React, { lazy, useEffect } from 'react';
import { getAccessToken } from 'axios-jwt';
import { useQuery } from '@tanstack/react-query';
import { Route, Routes, Navigate } from 'react-router-dom';

import api from 'api';
import { User } from 'types';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { extractUserFromToken, parseRoute } from 'utils/utils';

import withFirmAdminLayout from './withFirmAdminLayout';
import { renderAdminRoutes } from '../admin/AdminRoutes';


interface FirmAdminRoutesProps {
  removeNotFoundRoute?: boolean,
}

const Settings = lazy(() => import('pages/firmadmin/settings'));

const CustomerAdd = lazy(() => import('pages/firmadmin/customers/add'));
const CustomersList = lazy(() => import('pages/firmadmin/customers/list'));
const FirmAdminDashboard = lazy(() => import('pages/firmadmin/dashboard'));
const CustomerDetail = lazy(() => import('pages/firmadmin/customers/detail'));
const PartnerAdminsList = lazy(() => import('pages/firmadmin/admins/list'));
const PartnerAdminsImport = lazy(() => import('pages/firmadmin/admins/import'));
const PartnerAdminDetail = lazy(() => import('pages/firmadmin/admins/detail'));

export const renderFirmAdminRoutes = (user: User, removeNotFoundRoute: boolean) => (
  <React.Fragment>
    <Route path={ROUTES.FIRMADMIN.DASHBOARD} element={withFirmAdminLayout(FirmAdminDashboard)} />
    <Route path={ROUTES.FIRMADMIN.CUSTOMERS.ADD} element={withFirmAdminLayout(CustomerAdd)} />
    <Route path={ROUTES.FIRMADMIN.CUSTOMERS.LIST} element={withFirmAdminLayout(CustomersList)} />
    <Route path={ROUTES.FIRMADMIN.CUSTOMERS.DETAIL} element={withFirmAdminLayout(CustomerDetail)} />
    <Route path={ROUTES.FIRMADMIN.ADMINS.LIST} element={withFirmAdminLayout(PartnerAdminsList)} />
    <Route path={ROUTES.FIRMADMIN.ADMINS.ADD} element={withFirmAdminLayout(PartnerAdminsImport)} />
    <Route path={ROUTES.FIRMADMIN.ADMINS.DETAIL} element={withFirmAdminLayout(PartnerAdminDetail)} />
    <Route path={ROUTES.FIRMADMIN.SETTINGS} element={withFirmAdminLayout(Settings)} />

    {renderAdminRoutes(user!, true)}

    {!removeNotFoundRoute && (
      <Route path="*" element={<Navigate to={parseRoute(user, ROUTES.FIRMADMIN.DASHBOARD)} />} />
    )}
  </React.Fragment>
);

const FirmAdminRoutes: React.FC<FirmAdminRoutesProps> = ({ removeNotFoundRoute = false }) => {
  const { user, loginUserAsAdmin } = useAuthStore(store => store);
  const inClientNamespace = window.location.pathname.split('/')[2] === 'client';
  const entityId = inClientNamespace ? user?.entity_id : null;
  const firmAdminEntityId = inClientNamespace ? extractUserFromToken(getAccessToken()!)!.entity_id : null;

  const client = entityId
    ? useQuery(['client', entityId], () => api.clients.getClient(entityId))
    : undefined;

  const customers = firmAdminEntityId
    ? useQuery(['customers'], () => api.firms.getClients(firmAdminEntityId))
    : undefined;

  useEffect(() => {
    if (entityId && client && client.data && customers && customers.data) {
      loginUserAsAdmin({ entityId, clientName: client.data.name, previousUser: user, customers: customers.data });
    }
  }, [client]);

  return (
    <React.Suspense>
      <Routes>
        {renderFirmAdminRoutes(user!, removeNotFoundRoute)}
      </Routes>
    </React.Suspense>
  );
};

export default FirmAdminRoutes;
