import React, { useState } from 'react';
import { History } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  HStack,
  RSelectOptionV2,
  RSelectV2,
  Tooltip,
  useDisclosure,
} from '@ramp/components';

import { useAuthStore } from 'store';
import { APIError, Platforms } from 'types';
import { notify, useNotificationSend } from 'utils/notifications';
import { getRebootTitleAndDescription } from 'utils/notifications/useNotificationSend';

import RemoteControlCard from '../RemoteControlCard';
import OTPNotificationModal from '../OTPNotificationModal';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RebootCardProps {}

const RebootCard: React.FC<RebootCardProps> = () => {
  const { t } = useTranslation();
  const notification = useNotificationSend();
  const { id: deviceId } = useParams<{
    entityId: string;
    platform: Platforms;
    id: string;
  }>();
  const user = useAuthStore((store) => store.user!);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [rebootDelay, setRebootDelay] = useState<number>(1);
  const [notificationLoading, setNotificationLoading] =
    useState<boolean>(false);

  const onRebootRequest = (otpCode: string) => {
    setNotificationLoading(true);

    notification.win
      .rebootWithDelay(deviceId!, rebootDelay, otpCode)
      .then(() => {
        setNotificationLoading(false);
        onClose();

        const { title, message } = getRebootTitleAndDescription(t, rebootDelay);

        notify.success({
          title,
          description: message,
        });
      })
      .catch((err: APIError) => {
        setNotificationLoading(false);

        notify.error({
          title: t('admin.devices.detail.remoteControl.reboot.notificationError.title'),
          description: err.description,
        });
      });
  };

  return (
    <>
      <RemoteControlCard
        title={t('admin.devices.detail.remoteControl.reboot.title')}
        description={t('admin.devices.detail.remoteControl.reboot.description')}
        rightContent={
          <HStack spacing={4}>
            <RSelectV2
              width="180px"
              icon={<History size={16} />}
              label={t('admin.devices.detail.remoteControl.reboot.label')}
              value={rebootDelay}
              onChange={(val) => setRebootDelay(val as number)}
              onCard
            >
              <RSelectOptionV2 value={1} icon={<History size={16} />}>
                {t('admin.devices.detail.remoteControl.reboot.none')}
              </RSelectOptionV2>
              <RSelectOptionV2 value={60} icon={<History size={16} />}>
                {t('admin.devices.detail.remoteControl.reboot.oneminute')}
              </RSelectOptionV2>
              <RSelectOptionV2 value={600} icon={<History size={16} />}>
                {t('admin.devices.detail.remoteControl.reboot.tenminutes')}
              </RSelectOptionV2>
              <RSelectOptionV2 value={1800} icon={<History size={16} />}>
                {t('admin.devices.detail.remoteControl.reboot.thirtyminutes')}
              </RSelectOptionV2>
              <RSelectOptionV2 value={6000} icon={<History size={16} />}>
                {t('admin.devices.detail.remoteControl.reboot.onehour')}
              </RSelectOptionV2>
            </RSelectV2>
            <Tooltip
              placement="top"
              isDisabled={user.otp_enabled}
              label={t('components.otpNotificationModal.otpDisabled.description')}
            >
              <Button
                variant="brand"
                onClick={onOpen}
                isLoading={notificationLoading}
                isDisabled={!user.otp_enabled}
              >
                {t('admin.devices.detail.remoteControl.reboot.button')}
              </Button>
            </Tooltip>
          </HStack>
        }
      />

      {user.otp_enabled && (
        <OTPNotificationModal
          isOpen={isOpen}
          isLoading={notificationLoading}
          onClose={onClose}
          onComplete={onRebootRequest}
        />
      )}
    </>
  );
};

export default RebootCard;
