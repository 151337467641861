import React from 'react';
import { HStack, Text } from '@ramp/components';


interface AppRiskRatingProps {
  score?: number;
}

const AppRiskRating: React.FC<AppRiskRatingProps> = ({ score }) => {
  const color = () => {
    if (score === undefined || score === null) return 'gray.500';

    if (score <= 4) {
      return 'success.500';
    }
    if (score <= 6) {
      return 'warning.500';
    }
    if (score <= 7) {
      return 'orange.500';
    }

    return 'error.500';
  };

  const rating = () => {
    if (score === undefined || score === null) return '-';

    if (score <= 4) {
      return 'A';
    }
    if (score <= 6) {
      return 'B';
    }
    if (score <= 7) {
      return 'C';
    }

    return 'F';
  };

  return (
    <HStack
      w="32px"
      h="32px"
      align="center"
      justify="center"
      border="1px solid"
      borderColor={color()}
      borderRadius="full"
      padding="1rem"
    >
      <Text color={color()} fontWeight={500} fontSize="1rem" lineHeight="1rem">
        {rating()}
      </Text>
    </HStack>
  );
};

export default AppRiskRating;