import React, { useMemo } from 'react';
import { ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import {
  Button,
  ColumnsType, HStack, RTable, SeverityTag, Text, Tooltip, useColorModeValue,
} from '@ramp/components';

import { Issue } from 'types';
import { getSeverityFromScore } from 'utils/utils';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import IssueObjectTypeTag from 'components/IssueObjectTypeTag';

import BasicIssuesTableDetail from './BasicIssuesTableDetail';


interface BasicIssuesTableProps {
  id: string,
  defaultPageSize?: number,
  issues?: Issue[],
  loading?: boolean,
  simple?: boolean,
  userView?: boolean,
  userId?: string;
}

interface IssueRow extends Issue {
  key: number,
}

const BasicIssuesTable: React.FC<BasicIssuesTableProps> = ({
  id,
  defaultPageSize,
  issues = [],
  loading,
  simple = false,
  userView = false,
  userId,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize || 10);

  const columns: ColumnsType<IssueRow> = useMemo(() => [
    {
      title: t('admin.dashboard.issues.table.severity'),
      key: 'score',
      width: '120px',
      sorting: true,
      align: 'center',
      sortingOptions: {
        dataType: 'number',
        defaultSortOrder: 'desc',
        sortingFn: (a, b) => (a.original.score || 0) - (b.original.score || 0),
      },
      render: score => <SeverityTag size="md" severity={getSeverityFromScore(score)} />,
    },
    {
      title: t('admin.dashboard.issues.table.type'),
      key: 'object_type',
      width: '120px',
      sorting: true,
      align: 'center',
      sortingOptions: {
        dataType: 'number',
        sortingFn: (a, b) => a.original.object_type.localeCompare(b.original.object_type),
      },
      render: objectType => <IssueObjectTypeTag type={objectType} />,
    },
    {
      title: t('admin.dashboard.issues.table.count'),
      key: 'n_affected_objects',
      width: '120px',
      sorting: true,
      align: 'center',
      sortingOptions: {
        dataType: 'number',
        sortingFn: (a, b) => a.original.object_type.localeCompare(b.original.object_type),
      },
      render: (numOfAffectedObjects, { object_type }) => {
        if (object_type === 'device') {
          return numOfAffectedObjects > 1
            ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.devices')}`
            : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.device')}`;
        }

        if (object_type === 'network') {
          return numOfAffectedObjects > 1
            ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.networks')}`
            : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.network')}`;
        }

        if (object_type === 'client') {
          return numOfAffectedObjects > 1
            ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.clients')}`
            : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.client')}`;
        }

        if (object_type === 'network_device') {
          return numOfAffectedObjects > 1
            ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.devices')}`
            : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.device')}`;
        }

        if (object_type === 'email') {
          return numOfAffectedObjects > 1
            ? numOfAffectedObjects < 5
              ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.emails')}`
              : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.emails2')}`
            : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.email')}`;
        }

        return numOfAffectedObjects;
      },
    },
    {
      title: t('admin.dashboard.issues.table.title'),
      key: 'title',
      width: '360px',
      render: title => (
        <Tooltip label={title}>
          <Text noOfLines={1} fontWeight={600}>{title}</Text>
        </Tooltip>
      ),
    },
    {
      title: t('admin.dashboard.issues.table.description'),
      key: 'description',
      width: '40%',
      render: (value: string) => (
        <Tooltip label={value}>
          <Text
            noOfLines={2}
            color={useColorModeValue('black', 'white')}
          >
            {value}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: t('admin.dashboard.issues.table.actions'),
      key: 'actions',
      width: '120px',
      align: 'center',
      sorting: false,
      render: (_, issue) => (
        <HStack spacing={2}>
          <Button
            variant="outline"
            size="sm"
            rightIcon={<ChevronRight size={14} />}
            onClick={() => window.open(issue.knowledge_base_url, '_blank')}
          >
            {t('admin.dashboard.issues.table.moreInfo')}
          </Button>
          <Tooltip label={t('admin.dashboard.issues.table.disabled')} placement="top">
            <Button variant="brand" size="sm" isDisabled>
              {t('admin.dashboard.issues.table.ignore')}
            </Button>
          </Tooltip>
        </HStack>
      )
    },
  ], []);

  const formattedIssues: IssueRow[] = useMemo(() => {
    return issues.map((issue, key) => ({ key, ...issue }));
  }, [issues]);

  return (
    <RTable
      columns={columns}
      data={formattedIssues}
      loading={loading}
      expandable={{
        rowExpandable: () => true,
        render: issue => (
          <BasicIssuesTableDetail
            issue={issue}
            simple={simple}
            userView={userView}
            userId={userId}
          />
        ),
      }}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      interleaveRows
    />
  );
};

export default BasicIssuesTable;
