import React, { useState } from 'react';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@ramp/components';

import QRCodeScan from './QRCodeScan';
import Enter2FACode from './Enter2FACode';


interface Enable2FAModalProps {
  isOpen: boolean,
  onClose: () => void,
}

const Enable2FAModal: React.FC<Enable2FAModalProps> = ({ isOpen, onClose }) => {
  const [qrCodeScanned, setQrCodeScanned] = useState<boolean>(false);

  const onModalClose = () => {
    setQrCodeScanned(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {!qrCodeScanned
          ? <QRCodeScan setQrCodeScanned={setQrCodeScanned} />
          : <Enter2FACode onClose={onModalClose} />
        }
      </ModalContent>
    </Modal>
  );
};

export default Enable2FAModal;
