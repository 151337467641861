import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Archive, CheckCircle } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button, ColumnsType, HStack, RTable,
  SeverityNumTag, Text, Tooltip, useColorModeValue
} from '@ramp/components';

import api from 'api';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { parseRoute } from 'utils/utils';
import { APIError, EmailScan } from 'types';
import { notify } from 'utils/notifications';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import LeaksTable from 'components/tables/DataBreachedEmailsTable/LeaksTable';

import { ScanStatusIndicator } from './ScanStatusIndicator';


interface DataBreachedEmailsTableProps {
  id: string;
  emailBreachScans?: EmailScan[];
  loading?: boolean;
  simple?: boolean;
  defaultPageSize?: number;
  showPaginationControls?: boolean;
  userView?: boolean;
}

interface EmailsBreachesRow extends EmailScan {
  key: string;
  breaches_count: number | null;
}

const DataBreachedEmailsTable: React.FC<DataBreachedEmailsTableProps> = ({
  id,
  emailBreachScans,
  loading,
  defaultPageSize = 50,
  showPaginationControls = true,
  userView = false,
}) => {
  if (!emailBreachScans) return null;

  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const columns: ColumnsType<EmailsBreachesRow> = [
    {
      title: t('components.table.emailBreaches.email'),
      key: 'email',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        multiSortOrder: 2,
      },
    },
    {
      title: t('components.table.emailBreaches.requestedBy'),
      key: 'requested_by',
      width: '180px',
      render: (_, { users }) => {
        const navigate = useNavigate();
        const loggedUser = useAuthStore(store => store.user!);

        return (
          <HStack align="center" spacing="4px">
            {users.map((user, idx) => idx < 4 && (
              <Tooltip key={idx} label={user.name} placement="top">
                <HStack
                  w="26px"
                  h="24px"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="md"
                  zIndex={idx}
                  cursor="pointer"
                  onClick={() => navigate(parseRoute(
                    loggedUser,
                    ROUTES.ADMIN.USERS.DETAIL,
                    { id: user.id || '' },
                  ))}
                  transition="all .2s ease-in-out"
                  bg={useColorModeValue('gray.25', 'gray.650')}
                  _hover={{
                    bg: useColorModeValue('gray.50', 'gray.600'),
                  }}
                >
                  <Text
                    fontSize="14px"
                    lineHeight="14px"
                    textTransform="uppercase"
                    pos="relative"
                    top="2px"
                  >
                    {user.name.substring(0, 2)}
                  </Text>
                </HStack>
              </Tooltip>
            ))}
            {users.length >= 5 && (
              <Tooltip
                placement="top"
                label={users.filter((d, idx) => idx >= 5).map((user) => user.name).join(', ')}
              >
                <HStack
                  w="26px"
                  h="24px"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="md"
                  zIndex={100}
                  bg={useColorModeValue('gray.10', 'gray.700')}
                >
                  <Text
                    fontSize="14px"
                    lineHeight="14px"
                    textTransform="uppercase"
                    pos="relative"
                    top="2px"
                  >
                    +{users.length - 5}
                  </Text>
                </HStack>
              </Tooltip>
            )}
          </HStack>
        );
      },
    },
    {
      title: t('components.table.emailBreaches.activeBreaches'),
      key: 'active_breaches_count',
      sorting: true,
      align: 'right',
      sortingOptions: {
        dataType: 'number',
        defaultSortOrder: 'desc',
        sortingFn: ({ original: a }, { original: b }) => {
          const aActiveBreaches = a.breaches.filter((breach) => breach.status === 'active');
          const bActiveBreaches = b.breaches.filter((breach) => breach.status === 'active');

          return aActiveBreaches.length - bActiveBreaches.length;
        }
      },
      render: (breachCount, { breaches }) => {
        const activeBreaches = breaches.filter((breach) => breach.status === 'active');

        if (breachCount === -1) return 0;
        if (breachCount === -2) return '-';
        return (
          <Text
            fontWeight={activeBreaches.length > 0 ? '500' : 'normal'}
            color={activeBreaches.length > 0 ? 'brand.500' : 'initial'}
          >
            {activeBreaches.length}
          </Text>
        );
      },
    },
    {
      title: t('components.table.emailBreaches.resolvedBreaches'),
      key: 'resolved_breaches_count',
      sorting: true,
      align: 'right',
      sortingOptions: {
        dataType: 'number',
      },
      render: (breachCount, { breaches }) => {
        const resolvedBreaches = breaches.filter((breach) => breach.status === 'resolved');

        if (breachCount === -1) return 0;
        if (breachCount === -2) return '-';
        return resolvedBreaches.length;
      },
    },
    {
      title: t('components.table.emailBreaches.score'),
      key: 'score',
      width: '160px',
      align: 'center',
      sorting: true,
      sortingOptions: {
        dataType: 'number',
        sortingFn: ({ original: a }, { original: b }) => (a.score || 0) - (b.score || 0),
      },
      render: (_, { score }) => (
        <HStack justify="center" spacing={0}>
          <SeverityNumTag severityScore={score} />
          {(score || score === 0) && <Text pos="relative" top="0.5rem" left="0.2rem">/10</Text>}
        </HStack>
      ),
    },
    {
      title: t('components.table.emailBreaches.scanRequested'),
      key: 'scan_requested',
      align: 'left',
      sorting: true,
      sortingOptions: {
        multiSortOrder: 1,
      },
      render: (created) => dayjs(created).format('DD. MM. YYYY'),
    },
    {
      title: t('components.table.emailBreaches.lastScanDone'),
      key: 'scan_done',
      align: 'left',
      sorting: true,
      sortingOptions: {
        sortingFn: (a, b) => {
          if (!a.original.scan_done) return 1;
          if (!b.original.scan_done) return -1;
          return a.original.scan_done < b.original.scan_done ? -1 : 1;
        },
        multiSortOrder: 3,
      },
      render: (doneAt) => {
        return doneAt ? (
          dayjs(doneAt).format('DD. MM. YYYY')
        ) : (
          <ScanStatusIndicator status="inProgress" />
        );
      },
    },
    {
      title: t('components.table.emailBreaches.changeStatus.title'),
      key: 'change_status',
      align: 'center',
      width: '100px',
      render: (_, { id: emailId, email, breaches, breaches_count }) => {
        const queryClient = useQueryClient();
        const [isLoading, setIsLoading] = useState(false);
        const { entity_id: entityId } = useAuthStore((state) => state.user!);
        const emailStatus = useMemo(() => {
          const numOfActiveBreaches = breaches.filter((breach) => breach.status === 'active').length;
          return numOfActiveBreaches > 0 ? 'active' : 'resolved';
        }, [breaches]);

        const updateEmailStatus = async (status: 'active' | 'resolved') => {
          setIsLoading(true);

          api.clients.updateEmailStatus(entityId!, emailId, status)
            .then(async () => {
              await queryClient.invalidateQueries(['client', entityId, 'breaches']);

              notify.success({
                title: t('components.table.emailBreaches.changeStatus.successTitle', {
                  status: status === 'active'
                    ? t('components.table.emailBreaches.status.activated')
                    : t('components.table.emailBreaches.status.archived'),
                }),
                description: t('components.table.emailBreaches.changeStatus.successDescription', {
                  email: email,
                  status: status === 'active'
                    ? t('components.table.emailBreaches.status.activated')
                    : t('components.table.emailBreaches.status.archived'),
                }),
              });

              setIsLoading(false);
            })
            .catch((err: APIError) => {
              notify.error({
                title: t('components.table.emailBreaches.changeStatus.errorTitle', { code: err.code }),
                description: err.description,
              });

              setIsLoading(false);
            });
        };

        if (breaches_count === -1) return <></>;

        return (
          <Tooltip
            placement="top"
            label={emailStatus === 'active'
              ? t('components.table.emailBreaches.tooltip.archive')
              : t('components.table.emailBreaches.tooltip.active')
            }
          >
            <Button
              size="sm"
              variant={emailStatus === 'active' ? 'brand' : 'solid'}
              isLoading={isLoading}
              onClick={() => emailStatus === 'active'
                ? updateEmailStatus('resolved')
                : updateEmailStatus('active')
              }
              leftIcon={emailStatus === 'active'
                ? <Archive size={16} style={{ position: 'relative', top: -2 }} />
                : <CheckCircle size={16} style={{ position: 'relative', top: -2 }} />
              }
            >
              {emailStatus === 'active'
                ? t('components.table.emailBreaches.button.archive')
                : t('components.table.emailBreaches.button.active')
              }
            </Button>
          </Tooltip>
        );
      }
    }
  ];

  const userColumns: ColumnsType<EmailsBreachesRow> = [
    {
      title: t('components.table.emailBreaches.email'),
      key: 'email',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        multiSortOrder: 2,
      },
    },
    {
      title: t('components.table.emailBreaches.activeBreaches'),
      key: 'active_breaches_count',
      sorting: true,
      align: 'right',
      sortingOptions: {
        dataType: 'number',
        defaultSortOrder: 'desc',
        sortingFn: ({ original: a }, { original: b }) => {
          const aActiveBreaches = a.breaches.filter((breach) => breach.status === 'active');
          const bActiveBreaches = b.breaches.filter((breach) => breach.status === 'active');

          return aActiveBreaches.length - bActiveBreaches.length;
        }
      },
      render: (breachCount, { breaches }) => {
        const activeBreaches = breaches.filter((breach) => breach.status === 'active');

        if (breachCount === -1) return 0;
        if (breachCount === -2) return '-';

        return (
          <Text
            fontWeight={activeBreaches.length > 0 ? '500' : 'normal'}
            color={activeBreaches.length > 0 ? 'brand.500' : 'initial'}
          >
            {activeBreaches.length}
          </Text>
        );
      },
    },
    {
      title: t('components.table.emailBreaches.resolvedBreaches'),
      key: 'resolved_breaches_count',
      sorting: true,
      align: 'right',
      sortingOptions: {
        dataType: 'number',
        sortingFn: ({ original: a }, { original: b }) => {
          const aActiveBreaches = a.breaches.filter((breach) => breach.status === 'resolved');
          const bActiveBreaches = b.breaches.filter((breach) => breach.status === 'resolved');

          return aActiveBreaches.length - bActiveBreaches.length;
        }
      },
      render: (breachCount, { breaches }) => {
        const activeBreaches = breaches.filter((breach) => breach.status === 'resolved');

        if (breachCount === -1) return 0;
        if (breachCount === -2) return '-';
        return activeBreaches.length;
      },
    },
    {
      title: t('components.table.emailBreaches.score'),
      key: 'score',
      width: '160px',
      align: 'center',
      sorting: true,
      sortingOptions: {
        dataType: 'number',
        sortingFn: ({ original: a }, { original: b }) => (a.score || 0) - (b.score || 0),
      },
      render: (_, { score }) => (
        <HStack justify="center" spacing={0}>
          <SeverityNumTag severityScore={score} />
          {(score || score === 0) && <Text pos="relative" top="0.5rem" left="0.2rem">/10</Text>}
        </HStack>
      ),
    },
    {
      title: t('components.table.emailBreaches.scanRequested'),
      key: 'scan_requested',
      align: 'left',
      sorting: true,
      sortingOptions: {
        multiSortOrder: 1,
      },
      render: (created) => dayjs(created).format('DD. MM. YYYY'),
    },
    {
      title: t('components.table.emailBreaches.lastScanDone'),
      key: 'scan_done',
      align: 'left',
      sorting: true,
      sortingOptions: {
        sortingFn: (a, b) => {
          if (!a.original.scan_done) return 1;
          if (!b.original.scan_done) return -1;
          return a.original.scan_done < b.original.scan_done ? -1 : 1;
        },
        multiSortOrder: 3,
      },
      render: (doneAt) => {
        return doneAt ? (
          dayjs(doneAt).format('DD. MM. YYYY')
        ) : (
          <ScanStatusIndicator status="inProgress" />
        );
      },
    },
    {
      title: t('components.table.emailBreaches.changeStatus.title'),
      key: 'change_status',
      align: 'center',
      width: '100px',
      render: (_, { id: emailId, email, breaches, breaches_count }) => {
        const queryClient = useQueryClient();
        const [isLoading, setIsLoading] = useState(false);
        const { user_id: userId } = useAuthStore((state) => state.user!);
        const emailStatus = useMemo(() => {
          const numOfActiveBreaches = breaches.filter((breach) => breach.status === 'active').length;
          return numOfActiveBreaches > 0 ? 'active' : 'resolved';
        }, [breaches]);

        const updateEmailStatus = async (status: 'active' | 'resolved') => {
          setIsLoading(true);

          api.users.updateEmailStatus(userId, emailId, status)
            .then(async () => {
              await queryClient.invalidateQueries(['user', userId, 'breaches']);

              notify.success({
                title: t('components.table.emailBreaches.changeStatus.successTitle', {
                  status: status === 'active'
                    ? t('components.table.emailBreaches.status.activated')
                    : t('components.table.emailBreaches.status.archived'),
                }),
                description: t('components.table.emailBreaches.changeStatus.successDescription', {
                  email: email,
                  status: status === 'active'
                    ? t('components.table.emailBreaches.status.activated')
                    : t('components.table.emailBreaches.status.archived'),
                }),
              });

              setIsLoading(false);
            })
            .catch((err: APIError) => {
              notify.error({
                title: t('components.table.emailBreaches.changeStatus.errorTitle', { code: err.code }),
                description: err.description,
              });

              setIsLoading(false);
            });
        };

        if (breaches_count === -1) return <></>;

        return (
          <Tooltip
            placement="top"
            label={emailStatus === 'active'
              ? t('components.table.emailBreaches.tooltip.archive')
              : t('components.table.emailBreaches.tooltip.active')
            }
          >
            <Button
              size="sm"
              variant={emailStatus === 'active' ? 'brand' : 'solid'}
              isLoading={isLoading}
              onClick={() => emailStatus === 'active'
                ? updateEmailStatus('resolved')
                : updateEmailStatus('active')
              }
              leftIcon={emailStatus === 'active'
                ? <Archive size={16} style={{ position: 'relative', top: -2 }} />
                : <CheckCircle size={16} style={{ position: 'relative', top: -2 }} />
              }
            >
              {emailStatus === 'active'
                ? t('components.table.emailBreaches.button.archive')
                : t('components.table.emailBreaches.button.active')
              }
            </Button>
          </Tooltip>
        );
      }
    }
  ];

  const formattedEmailsBreachScans: EmailsBreachesRow[] = useMemo(() => {
    return emailBreachScans.map(
      (emailBreachScan) => {
        const breachCount = emailBreachScan.scan_done
          ? emailBreachScan.breaches.length
            ? emailBreachScan.breaches.length
            : -1
          : -2;

        return {
          key: emailBreachScan.email,
          breaches_count: breachCount,
          ...emailBreachScan,
        };
      }
    );
  }, [emailBreachScans]);

  return (
    <RTable
      w="full"
      h="full"
      // @ts-ignore
      columns={userView ? userColumns : columns}
      data={formattedEmailsBreachScans}
      expandable={{
        rowExpandable: (record) => record.breaches.length > 0,
        render: (record) => (
          <LeaksTable
            emailId={record.id}
            usersIds={!userView ? record.users.map(us => us.id) : []}
            id="email_breaches_table"
            breaches={record.breaches}
            userView={userView}
          />
        ),
      }}
      loading={loading}
      pagination={
        showPaginationControls
          ? {
            showTotal: true,
            defaultPageSize: pageSize,
            onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
            showGoToPage: true,
            showPageSizeSelector: true,
            pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
          }
          : {
            showTotal: false,
            showGoToPage: false,
            showPageSizeSelector: false,
            pageSize: 5,
          }
      }
      interleaveRows
    />
  );
};

export default DataBreachedEmailsTable;
