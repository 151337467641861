import React from 'react';
import { UserCog } from 'lucide-react';
import { RMenuGroup } from '@ramp/components';
import { useTranslation } from 'react-i18next';
import { Settings, Home, Users } from 'lucide-react';

import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import Layout from 'components/Layout';
import { parseRoute } from 'utils/utils';


const withFirmAdminLayout = (Page: React.ComponentType) => {
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);

  const menuItems: RMenuGroup[] = [
    {
      title: t('firmadmin.menu.main.title'),
      items: [
        {
          key: 'dashboard',
          to: parseRoute(user, ROUTES.FIRMADMIN.DASHBOARD),
          content: t('firmadmin.menu.main.dashboard'),
          icon: <Home size={16} />,
        },
        {
          key: 'customers',
          to: parseRoute(user, ROUTES.FIRMADMIN.CUSTOMERS.LIST),
          content: t('firmadmin.menu.main.customers'),
          icon: <Users size={16} />,
        },
      ],
    },
    {
      title: t('firmadmin.menu.management.title'),
      items: [
        {
          key: 'admins',
          to: parseRoute(user, ROUTES.FIRMADMIN.ADMINS.LIST),
          content: t('admin.menu.companyManagement.admins'),
          icon: <UserCog size={16} />,
        },
        {
          key: 'settings',
          to: parseRoute(user, ROUTES.FIRMADMIN.SETTINGS),
          content: t('firmadmin.menu.management.settings'),
          icon: <Settings size={16} />,
        },
      ],
    },
  ];

  return (
    <Layout
      type="firmadmin"
      menuItems={[
        { title: t('components.menu.admin'), items: menuItems }
      ]}
      page={Page}
    />
  );
};

export default withFirmAdminLayout;
