import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { themeColor } from '@ramp/theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertTriangle, Info, Network, Signal, Wifi } from 'lucide-react';
import {
  Box,
  ColumnsType,
  Flex,
  HStack,
  SeverityNumTag,
  SimpleTable,
  Tag,
  Text,
  Tooltip,
} from '@ramp/components';

import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { parseRoute } from 'utils/utils';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import ClientNetworkTag from 'components/ClientNetworkTag';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import { NetworkScan, NetworkScanWithoutUsers } from 'types';


interface NetworksTableProps {
  id: string;
  networks?: NetworkScan[] | NetworkScanWithoutUsers[];
  loading?: boolean;
  withoutUsers?: boolean;
  defaultPageSize?: number;
}

interface NetworkScanRow extends NetworkScan {
  key: string;
}

interface NetworkScanWithoutUsersRow extends NetworkScanWithoutUsers {
  key: string;
}

const NetworksTable: React.FC<NetworksTableProps> = ({
  id,
  loading,
  networks = [],
  withoutUsers = false,
  defaultPageSize = 10,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuthStore((store) => store.user!);

  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const columns: ColumnsType<NetworkScanRow> = useMemo(
    () => [
      {
        key: 'name',
        title: t('components.table.networks.name'),
        sorting: true,
        sortingOptions: {
          dataType: 'string',
        },
        render: (_, { name, network_type }) => (
          <HStack spacing={4}>
            <Flex
              w="32px"
              h="32px"
              p="0.5rem"
              alignItems="center"
              justifyContent="center"
              borderRadius="md"
              bg={themeColor('gray.10', 'gray.700')}
            >
              {network_type === 'wifi' && <Wifi width="1rem" />}
              {network_type === 'cellular' && <Signal width="1rem" />}
              {network_type === 'ethernet' && <Network width="1rem" />}
              {network_type === 'unknown' && <AlertTriangle width="1rem" />}
            </Flex>
            <Text fontSize="md" fontWeight={500}>
              {name}
            </Text>
          </HStack>
        ),
      },
      {
        key: 'client_type',
        title: t('components.table.networks.clientNetworkType'),
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          defaultSortOrder: 'asc',
        },
        render: (_, { client_type: clientNetworkType }) => (
          <ClientNetworkTag type={clientNetworkType} />
        ),
      },
      {
        key: 'public_ip',
        title: `${t('admin.networks.detail.publicIp')} (IPv4)`,
        render: (_, { public_ip }) => {
          if (!public_ip || !public_ip.ipv4) {
            return (
              <Text fontStyle="italic">
                {t('admin.notifications.filter.severity.unknown')}
              </Text>
            );
          }

          return <Text>{public_ip.ipv4}</Text>;
        },
      },
      {
        key: 'default_gateway',
        title: t('components.table.networks.defaultGateway'),
        render: (_, { default_gateway_ip }) => {
          if (!default_gateway_ip || default_gateway_ip.length === 0) {
            return (
              <Text fontStyle="italic">
                {t('admin.notifications.filter.severity.unknown')}
              </Text>
            );
          }

          return <Text>{default_gateway_ip.join(', ')}</Text>;
        },
      },
      {
        key: 'dns',
        title: 'DNS',
        render: (_, { dns }) => {
          if (!dns || dns.length === 0)
            return <Text fontStyle="italic">Unknown</Text>;

          return <Text>{dns.join(', ')}</Text>;
        },
      },
      {
        key: 'users',
        title: t('components.table.networks.users'),
        sorting: true,
        sortingOptions: {
          dataType: 'number',
        },
        render: (_, { users }) => <Text>{users.length}</Text>,
      },
      {
        key: 'riskScore',
        title: t('components.table.networks.riskScore'),
        align: 'center',
        sorting: true,
        sortingOptions: {
          dataType: 'number',
          sortingFn: (a, b) =>
            (a.original.score || 0) - (b.original.score || 0),
        },
        render: (_, { score }) => (
          <HStack spacing={1} justifyContent="center" alignItems="end">
            <SeverityNumTag severityScore={score} />
            <Text fontSize="md" lineHeight="8px">
              /10
            </Text>
          </HStack>
        ),
      },
      {
        key: 'is_safe',
        title: t('components.table.networks.autoVpnActive'),
        sorting: true,
        align: 'center',
        sortingOptions: {
          dataType: 'string',
          sortingFn: (a, b) =>
            (a.original.is_safe ? 1 : 0) - (b.original.is_safe ? 1 : 0),
        },
        render: (_, { is_safe }) => {
          return (
            <HStack w="full" justifyContent="center">
              <Tooltip
                placement="top"
                label={t('components.table.networks.autoVpnActiveTooltip')}
              >
                {is_safe ? (
                  <Tag
                    colorScheme="error"
                    w="fit-content"
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <Info size={12} />
                    <Box pos="relative" top="1px">
                      {t('admin.networks.detail.externalScanTab.table.no')}
                    </Box>
                  </Tag>
                ) : (
                  <Tag
                    colorScheme="success"
                    w="fit-content"
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <Info size={12} />
                    <Box pos="relative" top="1px">
                      {t('admin.networks.detail.externalScanTab.table.yes')}
                    </Box>
                  </Tag>
                )}
              </Tooltip>
            </HStack>
          );
        },
      },
      {
        key: 'last_scan_done',
        title: t('components.table.networks.lastScanDone'),
        align: 'center',
        sorting: true,
        sortingOptions: {
          sortingFn: (a, b) =>
            dayjs(b.original.last_scan_done).diff(
              dayjs(a.original.last_scan_done)
            ),
        },
        render: (_, { last_scan_done }) =>
          dayjs(last_scan_done).format('DD. MM. YYYY'),
      },
    ],
    []
  );

  const columnsWithoutUsers: ColumnsType<NetworkScanWithoutUsersRow> = useMemo(
    () => [
      {
        key: 'name',
        title: t('components.table.networks.name'),
        sorting: true,
        sortingOptions: {
          dataType: 'string',
        },
        render: (_, { name, network_type }) => (
          <HStack spacing={4}>
            <Flex
              w="32px"
              h="32px"
              p="0.5rem"
              alignItems="center"
              justifyContent="center"
              borderRadius="md"
              bg={themeColor('gray.10', 'gray.700')}
            >
              {network_type === 'wifi' && <Wifi width="1rem" />}
              {network_type === 'cellular' && <Signal width="1rem" />}
              {network_type === 'ethernet' && <Network width="1rem" />}
              {network_type === 'unknown' && <AlertTriangle width="1rem" />}
            </Flex>
            <Text fontSize="md" fontWeight={500}>
              {name}
            </Text>
          </HStack>
        ),
      },
      {
        key: 'client_type',
        title: t('components.table.networks.clientNetworkType'),
        sorting: true,
        sortingOptions: {
          dataType: 'string',
        },
        render: (_, { client_type: clientNetworkType }) => (
          <ClientNetworkTag type={clientNetworkType} />
        ),
      },
      {
        key: 'default_gateway',
        title: t('components.table.networks.defaultGateway'),
        render: (_, { default_gateway_ip }) => {
          if (!default_gateway_ip || default_gateway_ip.length === 0)
            return <Text fontStyle="italic">Unknown</Text>;

          return <Text>{default_gateway_ip.join(', ')}</Text>;
        },
      },
      {
        key: 'dns',
        title: 'DNS',
        render: (_, { dns }) => {
          if (!dns || dns.length === 0)
            return <Text fontStyle="italic">Unknown</Text>;

          return <Text>{dns.join(', ')}</Text>;
        },
      },
      {
        title: t('components.table.networks.riskScore'),
        key: 'score',
        align: 'center',
        sorting: true,
        sortingOptions: {
          dataType: 'number',
          sortingFn: ({ original: a }, { original: b }) =>
            (a.score || 0) - (b.score || 0),
        },
        render: (_, { score }) => (
          <HStack spacing={1} justifyContent="center" alignItems="end">
            <SeverityNumTag severityScore={score} />
            <Text fontSize="md" lineHeight="8px">
              /10
            </Text>
          </HStack>
        ),
      },
      {
        key: 'last_scan_done',
        title: t('components.table.networks.lastScanDone'),
        align: 'center',
        sorting: true,
        sortingOptions: {
          defaultSortOrder: 'desc',
        },
        render: (_, { last_scan_done }) =>
          dayjs(last_scan_done).format('DD. MM. YYYY'),
      },
    ],
    []
  );

  // @ts-ignore
  const formattedNetworks: NetworkScanRow[] | NetworkScanWithoutUsersRow[] =
    useMemo(() => {
      return networks.map((n, idx) => ({ ...n, key: n.id || idx.toString() }));
    }, [networks]);

  return (
    <SimpleTable<NetworkScanRow | NetworkScanWithoutUsersRow>
      data={formattedNetworks}
      // @ts-ignore
      columns={withoutUsers ? columnsWithoutUsers : columns}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
      }}
      onRowClick={({ id: networkId, network_type: type }) => {
        // If withoutUsers is set to true, we are on the user page -> navigate to the user network detail page
        if (withoutUsers) {
          navigate(
            parseRoute(user, ROUTES.USER.NETWORKS.DETAIL, {
              type,
              id: networkId!,
            })
          );
        } else {
          navigate(
            parseRoute(user, ROUTES.ADMIN.NETWORKS.DETAIL, {
              type,
              id: networkId!,
            })
          );
        }
      }}
      loading={loading}
      interleaveRows
    />
  );
};

export default NetworksTable;
