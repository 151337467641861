import React from 'react';
import byteSize from 'byte-size';
import { useTranslation } from 'react-i18next';
import { Description, DescriptionItem, HStack, Text } from '@ramp/components';

import { toCapitalize } from 'utils/utils';
import { WindowsExtendedDeviceInfo } from 'types/api/devices';


interface WindowsExtendedInfoProps {
  info: WindowsExtendedDeviceInfo;
}

const WindowsExtendedInfo: React.FC<WindowsExtendedInfoProps> = ({ info }) => {
  const { t } = useTranslation();

  return (
    <Description
      size="md"
      columns={{ sm: 1, md: 1, lg: 2, xl: 4, '2xl': 4 }}
    >
      <DescriptionItem label={t('admin.devices.detail.ram')}>
        <HStack justify="start" spacing={2}>
          {/* eslint-disable-next-line max-len */}
          <Text>{((info.ram.actual_usage || 0) / 10e8).toFixed(2)}GB / {((info.ram.total_memory || 0) / 10e8).toFixed(2)}GB</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.storage')}>
        <HStack justify="start" spacing={2}>
          {info.storage.map((s, i) => (
            // eslint-disable-next-line max-len
            <Text>{(byteSize(s.actual_usage || 0).toString())} / {(byteSize(s.total_storage || 0).toString())}{(i + 1) !== info.storage.length && '; '}</Text>
          ))}
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.cpu.name')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.cpu.name}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.cpu.architecture')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.cpu.architecture}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.cpu.numberOfCores')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.cpu.number_of_cores}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.cpu.frequency')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.cpu.cores_frequency}MHz</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.gpu.name')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.gpu.model}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.gpu.vendor')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.gpu.vendor}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.gpu.memory')}>
        <HStack justify="start" spacing={2}>
          <Text>{byteSize(info.gpu.memory || 0).toString()}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.display.resolution')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.display.resolution}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.battery.status')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.battery_status.status || toCapitalize(t('admin.devices.detail.device.unknown'))}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.battery.capacity')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.battery_status.capacity || toCapitalize(t('admin.notifications.filter.severity.unknown'))}</Text>
        </HStack>
      </DescriptionItem>
    </Description>
  );
};

export default WindowsExtendedInfo;
