import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@ramp/components';

import { APIError, Platforms } from 'types';
import { notify, useNotificationSend } from 'utils/notifications';

import RemoteControlCard from '../RemoteControlCard';
import { useDeviceDetail } from '../../../DeviceDetailProvider';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateAppsCardProps {}

const UpdateAppsCard: React.FC<UpdateAppsCardProps> = () => {
  const { t } = useTranslation();
  const notification = useNotificationSend();
  const { windowsApps } = useDeviceDetail();
  const { id: deviceId } = useParams<{ entityId: string, platform: Platforms, id: string }>();

  const [notificationLoading, setNotificationLoading] = useState<boolean>(false);

  const unupdatedApps = useMemo(() => {
    return windowsApps!.evaluated_applications!.filter(app => app.available_version && !app.system_component);
  }, [windowsApps]);

  const onAppUpdateClick = () => {
    setNotificationLoading(true);

    notification.win.updateApps(deviceId!, unupdatedApps.map(app => app.guid!))
      .then(() => {
        setNotificationLoading(false);

        notify.success({
          title: t('admin.devices.detail.appsUpdate.notificationSuccess.title'),
          description: t('admin.devices.detail.appsUpdate.notificationSuccess.description'),
        });
      })
      .catch((err: APIError) => {
        setNotificationLoading(false);

        notify.error({
          title: t('admin.devices.detail.appsUpdate.notificationFail.title'),
          description: err.description,
        });
      });
  };

  return (
    <RemoteControlCard
      title={t('admin.devices.detail.remoteControl.updateApps.title')}
      description={unupdatedApps.length > 0
        ? (
          <Text color="brand.500">
            {t('admin.devices.detail.remoteControl.updateApps.description', { num: unupdatedApps.length })}
          </Text>
        )
        : t('admin.devices.detail.remoteControl.updateApps.descriptionSuccess')
      }
      rightContent={(unupdatedApps.length > 0) && (
        <Button
          variant="brand"
          onClick={onAppUpdateClick}
          isLoading={notificationLoading}
        >
          {t('admin.devices.detail.remoteControl.updateApps.button')}
        </Button>
      )}
    />
  );
};

export default UpdateAppsCard;