import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter } from 'react-router-dom';

import SuperAdminRoutes from './SuperAdminRoutes';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SuperAdminRouterProps {}

const SuperAdminRouter: React.FC<SuperAdminRouterProps> = () => (
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <SuperAdminRoutes />
    </QueryParamProvider>
  </BrowserRouter>
);

export default SuperAdminRouter;
