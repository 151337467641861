import create from 'zustand';
import * as Sentry from '@sentry/react';
import { devtools } from 'zustand/middleware';

import api from 'api';
import { extractUserFromToken } from 'utils/utils';
import { clearAuthTokens, getAccessToken, getRefreshToken, setAuthTokens } from 'axios-jwt';
import { User, LoginAsAdminProps, APIError, UserCredentials, LoginAsPartnerAdminProps } from 'types';


export interface AuthStore {
  user: User | null,
  otpToken: string | null,
  loading: boolean,
  error: APIError | null,
  loginAsAdmin: LoginAsAdminProps,
  loginAsPartnerAdmin: LoginAsPartnerAdminProps,
  loginUser: (credentials: UserCredentials) => void,
  loginUserWithAccessToken: (entityId?: string) => void,
  loginUserWithRefreshToken: (entityId?: string, callback?: () => void) => void,
  verifyOtp: (otpCode: string, otpToken: string) => void,
  loginUserAsAdmin: (props: LoginAsAdminProps) => void,
  loginUserAsPartnerAdmin: (props: LoginAsPartnerAdminProps) => void,
  logoutUserAsAdmin: () => void,
  logoutUserAsPartnerAdmin: () => void,
  logoutUser: () => void,
  setOtpEnabled: (enabled: boolean) => void,
}

export const useAuthStore = create<AuthStore>()(
  devtools((set) => ({
    user: null,
    otpToken: null,
    loading: false,
    error: null,
    loginAsAdmin: {
      entityId: null,
      clientName: '',
      previousUser: null,
      customers: [],
    },
    loginAsPartnerAdmin: {
      entityId: null,
      partnerName: '',
      previousUser: null,
    },
    loginUser: ({ email, password }) => {
      set({ loading: true, error: null });
      api.auth.login(email, password)
        .then(({ access, refresh, otp_token: otpToken }) => {
          if (otpToken) return set({ otpToken, loading: false });

          if (access && refresh) {
            const extractedUser = extractUserFromToken(access);

            setAuthTokens({ accessToken: access, refreshToken: refresh });
            Sentry.setUser({
              id: extractedUser.user_id || 'unknown',
              email: extractedUser.email || 'unknown',
            });

            return set({ user: extractedUser, loading: false });
          }

          return set({ error: { description: 'Unknown login error' }, loading: false });
        })
        .catch((error: APIError) => set({ error, loading: false }));
    },
    loginUserWithAccessToken: entityId => {
      const user = extractUserFromToken(getAccessToken());
      set({ user: { ...user, entity_id: (entityId || user.entity_id || null) } });
    },
    loginUserWithRefreshToken: entityId => {
      api.auth.refreshLogin()
        .then(({ access }) => {
          const refreshToken = getRefreshToken();

          if (access) {
            clearAuthTokens();
            setAuthTokens({ accessToken: access, refreshToken: refreshToken || '' });

            const loggedUser = extractUserFromToken(access);

            return set({
              user: {
                ...loggedUser,
                entity_id: (entityId || loggedUser.entity_id || null),
              },
              loading: false,
            });
          }

          return set({ error: { description: 'Unknown login error' }, loading: false });
        })
        .catch((error: APIError) => set({ error, loading: false }));
    },
    verifyOtp: (otpCode, otpToken) => {
      set({ loading: true, error: null });
      api.auth.verifyOtp(otpToken, otpCode)
        .then(({ access, refresh }) => {
          setAuthTokens({ accessToken: access, refreshToken: refresh });
          return setTimeout(() => set({
            user: extractUserFromToken(access),
            otpToken: null,
            loading: false,
          }), 500);
        })
        .catch((error: APIError) => set({ error, loading: false }));
    },
    loginUserAsAdmin: ({ entityId, clientName, previousUser, customers }) => {
      set({
        user: {
          ...previousUser!,
          entity_id: entityId,
        },
        loginAsAdmin: {
          entityId: entityId,
          clientName: clientName,
          previousUser: previousUser,
          customers: customers,
        },
      });
    },
    loginUserAsPartnerAdmin: ({ entityId, partnerName, previousUser }) => {
      set({
        user: {
          ...previousUser!,
          entity_id: entityId,
        },
        loginAsPartnerAdmin: {
          entityId: entityId,
          partnerName: partnerName,
          previousUser: previousUser,
        },
      });
    },
    logoutUserAsAdmin: () => {
      set(state => ({
        ...state,
        user: {
          ...state.loginAsAdmin.previousUser!,
          client_id: null,
        },
        loginAsAdmin: {
          entityId: null,
          clientName: '',
          previousUser: null,
          customers: [],
        },
      }));
    },
    logoutUserAsPartnerAdmin: () => {
      set(state => ({
        ...state,
        user: {
          ...state.loginAsPartnerAdmin.previousUser!,
          client_id: null,
        },
        loginAsPartnerAdmin: {
          entityId: null,
          partnerName: '',
          previousUser: null,
        }
      }));
    },
    logoutUser: () => {
      clearAuthTokens();
      Sentry.setUser(null);
      set({
        user: null,
        otpToken: null,
        loginAsAdmin: {
          entityId: null,
          clientName: '',
          previousUser: null,
          customers: [],
        }
      });
    },
    setOtpEnabled: (enabled: boolean) => {
      set(state => ({
        ...state,
        user: {
          ...state.user!,
          otp_enabled: enabled,
        },
      }));
    },
  })),
);
