import React, { useState } from 'react';
import { DeviceUserEvaluation } from 'types';
import { useTranslation } from 'react-i18next';
import { RCard, VStack } from '@ramp/components';

import DevicesFilter from 'components/DevicesFilter';
import DevicesEvaluationTable from 'components/tables/DevicesEvaluationTable';


interface DevicesCardProps {
  devices?: DeviceUserEvaluation[];
}

const DevicesCard: React.FC<DevicesCardProps> = ({ devices }) => {
  const { t } = useTranslation();
  const [filteredDevices, setFilteredDevices] = useState<DeviceUserEvaluation[]>(devices || []);

  return (
    <RCard w="full" title={t('admin.dashboard.platformStats.devices')} titleOutside={false}>
      <VStack w="full" align="start" spacing={4}>
        <DevicesFilter devices={devices} setDevices={setFilteredDevices} showSearch={false} />
        <DevicesEvaluationTable id="devices_table" devices={filteredDevices} />
      </VStack>
    </RCard>
  );
};

export default DevicesCard;
