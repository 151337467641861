import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRightIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useColorModeValue,
  VStack,
  Text,
  HStack,
  PinInput,
  PinInputField,
  Box,
  ModalBody,
  Button,
  PinInputFieldProps,
  Heading,
  Stack,
  Spinner,
} from '@ramp/components';

import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { parseRoute } from 'utils/utils';


interface OTPNotificationModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onComplete: (otpCode: string) => void;
}

// TODO: Move this to `ramp` library
const pinInputFieldStyle: PinInputFieldProps = {
  height: '56px',
  fontWeight: 500,
  fontSize: '20px',
  _focusVisible: {
    borderColor: 'rgba(214, 9, 40, 0.5)',
    boxShadow: 'rgb(214 9 40 / 50%) 0px 0px 0px 1px',
  },
};

const OTPNotificationModal: React.FC<OTPNotificationModalProps> = ({
  isOpen,
  isLoading,
  onClose,
  onComplete,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useAuthStore((store) => store.user!);

  if (!user.otp_enabled) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay onClick={onClose} />
        <ModalContent
          bg={useColorModeValue('white', 'gray.750')}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ModalBody
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py="1.5rem"
            gap="1rem"
          >
            <VStack gap="0.5rem">
              <Heading size="md" textAlign="center">
                {t('components.otpNotificationModal.otpDisabled.title')}
              </Heading>
              <Text textAlign="center" color="gray.400">
                {t('components.otpNotificationModal.otpDisabled.description')}
              </Text>
            </VStack>
            <Button
              size="sm"
              variant="brand"
              onClick={() =>
                navigate(parseRoute(user, ROUTES.ADMIN.ACCOUNT_SETTINGS))
              }
              rightIcon={<ArrowRightIcon size={14} />}
            >
              {t('components.otpNotificationModal.otpDisabled.setupButton')}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue('white', 'gray.750')}>
        <ModalBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py="1.5rem"
          gap="1.5rem"
        >
          <VStack gap="0.5rem">
            <Heading size="md">
              {t('components.otpNotificationModal.otpEnabled.title')}
            </Heading>
            <Text textAlign="center" color="gray.400">
              {t('components.otpNotificationModal.otpEnabled.description')}
            </Text>
          </VStack>
          <AnimatePresence initial={false}>
            {!isLoading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
              >
                <HStack justify="center">
                  <PinInput onComplete={onComplete} otp>
                    <PinInputField {...pinInputFieldStyle} autoFocus />
                    <PinInputField {...pinInputFieldStyle} />
                    <PinInputField {...pinInputFieldStyle} />
                    <Box mx={1} border="none" w={1} />
                    <PinInputField {...pinInputFieldStyle} />
                    <PinInputField {...pinInputFieldStyle} />
                    <PinInputField {...pinInputFieldStyle} />
                  </PinInput>
                </HStack>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence initial={false}>
            {isLoading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
              >
                <Stack alignItems="center" justifyContent="center">
                  <Spinner size="md" color="brand.500" />
                </Stack>
              </motion.div>
            )}
          </AnimatePresence>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OTPNotificationModal;
