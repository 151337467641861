import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Box, Heading, RCard, Spinner, useColorModeValue, VStack } from '@ramp/components';

import api from 'api';
import RequestedAppsTable from 'components/tables/RequestedAppsTable';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RequestedAppsProps {}

const RequestedApps: React.FC<RequestedAppsProps> = () => {
  const { t } = useTranslation();
  const { id: deviceId } = useParams<{ id: string }>();

  const requestedApps = useQuery(
    ['deviceDetail', deviceId, 'requestedApps'],
    () => api.devices.getRequestedApps(deviceId!),
  );

  if (requestedApps.isLoading) {
    return (
      <Box w="full" display="flex" h="50vh" alignItems="center" justifyContent="center">
        <Spinner size="md" color="brand.500" />
      </Box>
    );
  }

  return (
    <VStack w="full" align="start">
      <Heading color={useColorModeValue('black', 'gray.400')} fontSize="1.5rem" fontWeight={600}>
        {t('admin.devices.detail.apps.requestedTitle')}
      </Heading>
      <RCard w="full">
        <VStack w="full" spacing={4}>
          <RequestedAppsTable id="device" apps={requestedApps.data} loading={requestedApps.isLoading} />
        </VStack>
      </RCard>
    </VStack>
  );
};

export default RequestedApps;
