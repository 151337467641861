import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ColumnsType, RTable, Text } from '@ramp/components';

import { AffectedEmail } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import { ScanStatusIndicator } from '../DataBreachedEmailsTable/ScanStatusIndicator';


interface AffectedEmailsTableProps {
  id: string;
  emails?: AffectedEmail[];
  loading?: boolean;
  defaultPageSize?: number;
  showPaginationControls?: boolean;
}

interface AffectedEmailRow extends AffectedEmail {
  key: string;
}

const AffectedEmailsTable: React.FC<AffectedEmailsTableProps> = ({
  id,
  emails,
  loading,
  defaultPageSize = 50,
  showPaginationControls = true,
}) => {
  if (!emails) return null;

  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const columns: ColumnsType<AffectedEmailRow> = [
    {
      title: t('components.table.emailBreaches.email'),
      key: 'email',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        multiSortOrder: 2,
      },
    },
    {
      title: t('components.table.emailBreaches.activeBreaches'),
      key: 'n_breaches',
      sorting: true,
      align: 'right',
      sortingOptions: {
        dataType: 'number',
        defaultSortOrder: 'desc',
        sortingFn: ({ original: a }, { original: b }) => a.n_breaches - b.n_breaches,
      },
      render: (breachCount) => {
        if (breachCount === -1) return 0;
        if (breachCount === -2) return '-';

        return (
          <Text
            fontWeight={breachCount > 0 ? '500' : 'normal'}
            color={breachCount > 0 ? 'brand.500' : 'initial'}
          >
            {breachCount}
          </Text>
        );
      },
    },
    {
      title: t('components.table.emailBreaches.scanRequested'),
      key: 'scan_requested',
      align: 'left',
      sorting: true,
      sortingOptions: {
        multiSortOrder: 1,
      },
      render: (created) => dayjs(created).format('DD. MM. YYYY'),
    },
    {
      title: t('components.table.emailBreaches.lastScanDone'),
      key: 'scan_done',
      align: 'left',
      sorting: true,
      sortingOptions: {
        sortingFn: (a, b) => {
          if (!a.original.scan_done) return 1;
          if (!b.original.scan_done) return -1;
          return a.original.scan_done < b.original.scan_done ? -1 : 1;
        },
        multiSortOrder: 3,
      },
      render: (doneAt) => {
        return doneAt ? (
          dayjs(doneAt).format('DD. MM. YYYY')
        ) : (
          <ScanStatusIndicator status="inProgress" />
        );
      },
    },
  ];

  const formattedEmails: AffectedEmailRow[] = useMemo(() => emails.map(email => ({
    key: email.id,
    ...email,
  })), [emails]);

  return (
    <RTable
      w="full"
      h="full"
      columns={columns}
      data={formattedEmails}
      loading={loading}
      pagination={
        showPaginationControls
          ? {
            showTotal: true,
            defaultPageSize: pageSize,
            onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
            showGoToPage: true,
            showPageSizeSelector: true,
            pageSizeSelectorOptions: DEFAULT_PAGE_SIZES,
          }
          : {
            showTotal: false,
            showGoToPage: false,
            showPageSizeSelector: false,
            pageSize: 5,
          }
      }
      interleaveRows
    />
  );
};

export default AffectedEmailsTable;
