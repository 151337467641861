import React from 'react';
import { useTranslation } from 'react-i18next';
import { RCard, Grid, GridItem, useColorModeValue } from '@ramp/components';

import { DevicesStats } from 'types';
import PlatformStats from 'components/PlatformStats';
import { useWindowWidth } from 'utils/hooks/useWindowWidth';
import NoDataCardContent from 'components/NoDataCardContent';


interface PlatformStatsCardProps {
  devicesStats?: DevicesStats,
}

const PlatformStatsCard: React.FC<PlatformStatsCardProps> = ({ devicesStats }) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  if (!devicesStats) return null;

  // @ts-ignore
  if (Object.keys(devicesStats).filter(platform => devicesStats[platform].devices_count > 0).length === 0) {
    return (
      <RCard
        w="full"
        h="360px"
        mt="0 !important"
        title={t('admin.users.detail.platformStats')}
        titleOutside={false}
      >
        <NoDataCardContent />
      </RCard>
    );
  }

  return (
    <RCard
      title={t('admin.dashboard.platformStats.title')}
      w="full"
      h="360px"
      mt="0 !important"
      titleOutside={false}
      titleStyle={{ color: useColorModeValue('black', 'gray.400') }}
    >
      <Grid
        w="100%"
        templateColumns={(windowWidth > 1700) ? '2.25fr 1.25fr 1.25fr 2.25fr' : '1.25fr 1.25fr 1.25fr 1.25fr'}
        alignItems="center"
        padding="0.5rem"
      >
        <GridItem textAlign="left">
          <b>{t('admin.dashboard.platformStats.platform')}</b>
        </GridItem>
        <GridItem textAlign="center">
          <b>{t('admin.dashboard.platformStats.devices')}</b>
        </GridItem>
        <GridItem textAlign="center">
          <b>{t('admin.dashboard.platformStats.issues')}</b>
        </GridItem>
        <GridItem textAlign={(windowWidth > 1700) ? 'center' : 'right'}>
          <b>{t('admin.dashboard.platformStats.score')}</b>
        </GridItem>
      </Grid>
      <PlatformStats name="windows" platform={devicesStats.windows} />
      <PlatformStats name="macos" platform={devicesStats.macos} />
      <PlatformStats name="android" platform={devicesStats.android} />
      <PlatformStats name="ios" platform={devicesStats.ios} />
      <PlatformStats name="ipados" platform={devicesStats.ipados} />
    </RCard>
  );
};

export default PlatformStatsCard;
