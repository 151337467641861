import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { RampProvider } from '@ramp/components';
import { init as initApm } from '@elastic/apm-rum';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import 'utils/fonts';
import 'utils/locales';
import Router from 'router';
import { APIError } from 'types';
import AppLoader from 'components/AppLoader';
import { useLayoutStore } from 'store/layout';
import { notify } from 'utils/notifications';
import SomethingWentWrong from 'components/SomethingWentWrong';
import { getEnvironment, getLoggingService } from 'utils/utils';
import MobileUnsupportedModal from 'components/MobileUnsupportedModal';

import './main.css';

// Importing locales for dayjs
import 'dayjs/locale/de';
import 'dayjs/locale/cs';

import pkg from '../package.json';


getEnvironment();

initApm({
  serviceName: 'securitycheck-frontend',
  environment: getEnvironment(),
  serverUrl: getLoggingService(),
  serviceVersion: pkg.version,
});

const App: React.FC = () => {
  const { t } = useTranslation();
  const loading = useLayoutStore(store => store.loading);
  const { color_scheme: brandColorScheme } = useLayoutStore(store => store.theme);

  const queryClient = useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        // @ts-ignore
        onError: (err: APIError) => {
          notify.error({
            title: t('components.notification.error', { error: err.code }),
            description: err.description,
          });

          // If the error is 4xx, redirect to the home page
          if (400 <= (err.code || 0) && (err.code || 0) <= 499) {
            window.location.replace('/');
          }
        },
        cacheTime: 0,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
      },
    },
  }), []);

  const setDayJsLocale = () => {
    const locale = localStorage.getItem('i18nextLng') || 'en';
    dayjs.locale(locale);
  };

  useEffect(() => {
    setDayJsLocale();
  }, []);

  return (
    <RampProvider brand={brandColorScheme} initialColorMode="dark" appVersion={`v${pkg.version}`}>
      <ErrorBoundary FallbackComponent={SomethingWentWrong}>
        <QueryClientProvider client={queryClient}>
          <MobileUnsupportedModal />
          {loading ? <AppLoader /> : <Router />}
        </QueryClientProvider>
      </ErrorBoundary>
    </RampProvider>
  );
};

export default App;
