import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ROUTES from 'router/routes';


interface BaseRoutesProps {
  removeNotFoundRoute?: boolean,
}

const SignIn = lazy(() => import('pages/base/SignIn'));
const SignUp = lazy(() => import('pages/base/SignUp'));
const ResetPassword = lazy(() => import('pages/base/ResetPassword'));
const SignUpCompany = lazy(() => import('pages/base/SignUp/company'));
const ForgotPassword = lazy(() => import('pages/base/ForgotPassword'));
const TestAssignment = lazy(() => import('pages/base/TestAssignment'));
const FirmVerification = lazy(() => import('pages/base/FirmVerification'));
const FirmAdminVerification = lazy(() => import('pages/base/FirmAdminVerification'));

const BaseRoutes: React.FC<BaseRoutesProps> = ({ removeNotFoundRoute = false }) => (
  <React.Suspense>
    <Routes>
      <Route path={ROUTES.BASE.SIGN_IN} element={<SignIn />} />
      <Route path={ROUTES.BASE.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTES.BASE.FIRM_VERIFICATION} element={<FirmVerification />} />
      <Route path={ROUTES.BASE.FIRMADMIN_VERIFICATION} element={<FirmAdminVerification />} />
      <Route path={ROUTES.BASE.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ROUTES.BASE.SIGN_UP} element={<SignUp />} />
      <Route path={ROUTES.BASE.SIGN_UP_COMPANY} element={<SignUpCompany />} />
      <Route path={ROUTES.BASE.TEST_ASSIGNMENT} element={<TestAssignment />} />

      {!removeNotFoundRoute ? <Route path="*" element={<Navigate to={ROUTES.BASE.SIGN_IN} />} /> : undefined}
    </Routes>
  </React.Suspense>
);

export default BaseRoutes;
