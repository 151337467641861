import React from 'react';
import dayjs from 'dayjs';
import { linear } from '@ramp/theme';
import { RiUser3Line } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { HStack, RCard, Tag, Text, useColorModeValue, VStack } from '@ramp/components';

import { UserDataWithIssues } from 'types';
import { getRoleByNumber } from 'utils/utils';
import UserActions from 'components/UserActions';


interface UserInfoCardProps {
  user?: UserDataWithIssues;
  withUserActions?: boolean;
  firmAdmin?: boolean;
}

const UserInfoCard: React.FC<UserInfoCardProps> = ({ user, withUserActions = false, firmAdmin = false }) => {
  if (!user) return null;

  const { t } = useTranslation();

  return (
    <RCard w="full" mt="0 !important">
      <HStack w="full" justify="space-between">
        <HStack spacing={8}>
          <HStack spacing={4}>
            <HStack
              align="center"
              justify="center"
              w="60px"
              h="60px"
              borderRadius={8}
              bgGradient={useColorModeValue(
                linear('to-br', ['gray.25', 'transparent']),
                linear('to-br', ['black', 'gray.700']),
              )}
            >
              <RiUser3Line fontSize="2rem" color={useColorModeValue('black', 'white')} />
            </HStack>
            <VStack align="start" spacing={0}>
              <Text fontSize="1.2rem" fontWeight={500}>{user.name}</Text>
              <Tag size="sm">
                {getRoleByNumber(user.role || 0, t).toUpperCase()}
              </Tag>
            </VStack>
          </HStack>
          <HStack spacing={6}>
            <VStack align="start" spacing={1}>
              <HStack spacing={2}>
                <Text fontWeight={500}>{t('admin.users.filters.status')}</Text>
                {user.status === 'active' && (
                  <Tag size="sm" colorScheme="green">
                    {t('admin.users.filters.active')}
                  </Tag>
                )}
                {user.status === 'pending' && (
                  <Tag size="sm" colorScheme="yellow">
                    {t('admin.users.filters.pending')}
                  </Tag>
                )}
                {/* eslint-disable-next-line max-len */}
                {user.status === 'email_verification' && (
                  <Tag size="sm" colorScheme="yellow">
                    {t('admin.users.filters.emailVerification')}
                  </Tag>
                )}
                {user.status === 'password_reset' && (
                  <Tag size="sm" colorScheme="yellow">
                    {t('admin.users.filters.passwordReset')}
                  </Tag>
                )}
                {/* eslint-disable-next-line max-len */}
                {user.status === 'password_reset_activation' && (
                  <Tag size="sm" colorScheme="yellow">
                    {t('admin.users.filters.passwordReset')}
                  </Tag>
                )}
                {/* eslint-disable-next-line max-len */}
                {user.status === 'password_set_verification' && (
                  <Tag size="sm" colorScheme="yellow">
                    {t('admin.users.filters.passwordSetVerification')}
                  </Tag>
                )}
                {user.status === 'disabled' && (
                  <Tag size="sm" colorScheme="brand">
                    {t('admin.users.filters.blocked')}
                  </Tag>
                )}
                {user.status === 'archived' && (
                  <Tag size="sm" colorScheme="gray">
                    {t('admin.users.filters.archived')}
                  </Tag>
                )}
              </HStack>
              <HStack spacing={2}>
                <Text fontWeight={500}>{t('admin.users.filters.name')}</Text>
                <Text fontWeight={300}>{user.name}</Text>
              </HStack>
            </VStack>
            <VStack align="start" spacing={1}>
              <HStack spacing={2}>
                <Text fontWeight={500}>{t('admin.users.filters.role')}</Text>
                <Tag size="sm">
                  {getRoleByNumber(user.role || 0, t).toUpperCase()}
                </Tag>
              </HStack>
              <HStack spacing={2}>
                <Text fontWeight={500}>{t('admin.users.detail.position')}</Text>
                <Text fontWeight={300}>{(user.position || t('admin.users.detail.none'))}</Text>
              </HStack>
            </VStack>
            <VStack align="start" spacing={1}>
              <HStack spacing={2}>
                <Text fontWeight={500}>2FA</Text>
                <Tag size="sm" colorScheme={user.otp_enabled ? 'green' : 'brand'}>
                  {user.otp_enabled
                    ? t('admin.users.filters.enabled')
                    : t('admin.users.filters.disabled')
                  }
                </Tag>
              </HStack>
              <HStack spacing={2}>
                <Text fontWeight={500}>Email</Text>
                <Text fontWeight={300}>{(user.email)}</Text>
              </HStack>
            </VStack>
            <VStack align="start" spacing={1}>
              <HStack spacing={2}>
                <Text fontWeight={500}>{t('admin.users.filters.lastLogin')}</Text>
                <Text fontWeight={300}>
                  {user.last_login
                    ? dayjs(user.last_login).format('DD. MM. YYYY')
                    : t('components.permissionRow.never')
                  }
                </Text>
              </HStack>
              <HStack spacing={2}>
                <Text fontWeight={500}>{t('admin.users.filters.accountCreated')}</Text>
                <Text fontWeight={300}>{dayjs(user.registered).format('DD. MM. YYYY')}</Text>
              </HStack>
            </VStack>
          </HStack>
        </HStack>

        {withUserActions && <UserActions id={user.id} user={user} firmAdmin={firmAdmin} />}
      </HStack>
    </RCard>
  );
};

export default UserInfoCard;